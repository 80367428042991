.jeeta-app .timer-btn,
.jeeta-app * .timer-btn {
  padding: .2rem 5rem;
  font-size: .9rem;
  font-weight: 600;
  color: var(--PrimaryColor);
  background-color: var(--glass);
  border: none;
  border-radius: .3rem;
  cursor: pointer;
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}

.jeeta-app .timer-btn:disabled,
.jeeta-app * .timer-btn:disabled {
  background-color: var(--glass);
  color: var(--tiger-lilly);
  cursor: not-allowed;
}
