.jeeta-app .alertModal,
.jeeta-app * .alertModal {
  max-width: 80vw;
  max-height: 80vh;
  overflow-y: auto;
  background-color: var(--whiteColorDeam);
  padding: 2rem 3rem;
  position: fixed;
  top: 55%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 1000;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
