.jeeta-app .forward-timer,
.jeeta-app * .forward-timer {
  position: relative;
  padding: .2rem .4rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: var(--SecondaryBkg);
  color: var(--whiteColor);
  border-radius: .5rem;
  font-size: .94rem;
  font-weight: 600;
  gap: .2rem;
}

.jeeta-app .forward-timer:hover,
.jeeta-app * .forward-timer:hover {
  cursor: pointer;
  background-color: var(--SecondaryColor);
}

.jeeta-app .forward-timer .icon,
.jeeta-app * .forward-timer .icon {
  font-size: 1.1rem;
}

.jeeta-app .forward-timer.visible,
.jeeta-app * .forward-timer.visible {
  background-color: var(--SecondaryColor);
}

.jeeta-app .forward-timer.visible:hover,
.jeeta-app * .forward-timer.visible:hover {
  background-color: var(--SecondaryBkg);
}

.jeeta-app .timer-container,
.jeeta-app * .timer-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.jeeta-app .timer-container.on-top,
.jeeta-app * .timer-container.on-top {
  background: var(--whiteColor);
  border: none;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  position: fixed;
  top: 4rem;
  right: .5rem;
  z-index: 10;
}

.jeeta-app .timer,
.jeeta-app * .timer {
  position: relative;
  width: 8rem;
  height: 8rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.jeeta-app .progress-ring,
.jeeta-app * .progress-ring {
  width: 100%;
  height: 100%;
  /* Sets the SVG size to 10rem by 10rem */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* Ensure it's flex to align child svg */
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  /* Center content vertically */
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  /* Center content horizontally */
}

.jeeta-app .progress-ring__circle,
.jeeta-app * .progress-ring__circle {
  stroke: var(--easy);
  stroke-width: 1rem;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 0.35s;
  transition: stroke-dashoffset 0.35s;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}

.jeeta-app .progress-ring__circle.half,
.jeeta-app * .progress-ring__circle.half {
  stroke: var(--medium);
}

.jeeta-app .progress-ring__circle.little,
.jeeta-app * .progress-ring__circle.little {
  stroke: var(--hard);
}

@media all and (max-width: 550px) {
  .jeeta-app .timer-container,
  .jeeta-app * .timer-container {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
}
