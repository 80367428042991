:root {
  --fc-button-bg-color: rgba(65, 137, 230, 0.5);
  --fc-button-border-color: rgba(65, 137, 230, 0.5);
  --fc-button-active-bg-color: hsl(214, 77%, 58%);
  --fc-button-active-border-color: rgba(65, 137, 230, 0.5);
  --fc-button-hover-bg-color: hsl(169, 79%, 44%);
  --fc-button-hover-border-color: rgba(24, 201, 168, 0.5);
  --fc-bg-event-color: rgba(24, 201, 168, .3);
  --fc-bg-event-opacity: 0.9;
  --fc-event-bg-color: hsl(169, 79%, 44%);
  --fc-event-border-color: #ddd;
  --fc-today-bg-color: rgba(65, 137, 230, 0.1);
}

.fc-button :hover,
.fc-bg-event :hover,
.fc-event-main :hover {
  cursor: pointer;
}

.fc-button::first-letter,
.fc-bg-event::first-letter,
.fc-event-main::first-letter {
  text-transform: capitalize;
}

.fc-toolbar-title {
  text-align: center;
  color: var(--PrimaryColor);
  font-size: 1.5rem;
}

.fc-col-header-cell,
.fc-daygrid-day-top,
.fc-timegrid-slot-label {
  font-size: 1rem;
}

.fc .fc-view-harness {
  height: 100% !important;
  /* Forces the calendar's view to take up the full height of its container */
}

.fc .fc-view-harness-active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.fc .fc-scrollgrid {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  /* Allows the main calendar grid to flexibly fill the available space */
}

.fc .fc-scroller::-webkit-scrollbar {
  width: .5rem;
  /* Adjust width as needed */
}

.fc .fc-scroller::-webkit-scrollbar-track {
  background: transparent;
  /* Adjust track background color as needed */
}

.fc .fc-scroller::-webkit-scrollbar-thumb {
  background-color: var(--PrimaryBkg);
  border-radius: .5rem;
  /* Adjust border-radius as needed */
  border: none;
  /* Adjust border and color as needed */
}

.fc .fc-timegrid-lot-label {
  width: auto;
  white-space: nowrap;
}

.fc .fc-timegrid-slot-lane {
  padding-left: auto !important;
  /* Adjust padding to fit new label width */
}

.fc-col-header-cell {
  color: var(--textColor);
  background-color: rgba(24, 201, 168, 0.2);
  /* Example background color */
  border: 1px solid #ddd !important;
  /* Example to make the border bolder */
}

.fc-col-header-cell.fc-day.fc-day-sat,
.fc-col-header-cell.fc-day.fc-day-sun {
  background-color: rgba(255, 224, 224, 0.65);
}

@media screen and (max-width: 768px) {
  .fc-button {
    padding: 0rem .2rem !important;
    font-size: .7rem !important;
  }
  .fc-toolbar {
    font-size: .8rem !important;
  }
  .fc-header-toolbar {
    margin-bottom: .5em !important;
  }
  .fc-toolbar-title {
    font-size: 1rem !important;
  }
  .fc-col-header-cell,
  .fc-daygrid-day-top,
  .fc-timegrid-slot-label {
    font-size: .8rem !important;
  }
}
