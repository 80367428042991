.jeeta-app,
.jeeta-app *,
.customModal,
.customModal * {
    .tests {
        width: 100%;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        padding: 1rem;
        color: var(--textColor);
        animation: fadeUp 1.2s ease-out forwards;
        /* Example duration and timing */
    }

    .singleTestPage {
        width: 100vw;
        height: 100vh;
        box-sizing: border-box;
        overflow: hidden;

        .tests-glass {
            height: 100%;
            border-radius: 0 !important;
            overflow-y: auto;
        }
    }

    .tests-header {
        position: relative;
        width: 100%;
        padding: .5rem 1rem;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        background: var(--gradientBkg);
        border-radius: 1rem;
        box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);

        .title {
            color: var(--whiteColor);
            text-shadow: 0 2px 4px rgba(140, 140, 141, 0.3);
            text-align: center;
        }
    }

    .tests-filter {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        z-index: 5;
        $table-border-color: var(--cardBG);
        $table-header-bg: #f7f7f7;

        .filterTitle {
            width: 100%;
            // padding: 1rem;
            border-radius: 1rem;
            // background: var(--gradientBkg);
            // box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
            flex-direction: column;
            gap: .5rem;

            .title {
                text-align: center;
                color: var(--SecondaryColor);
            }

            span {
                font-size: 1.5rem;
                text-align: center;
                font-weight: 600;
                color: var(--blackColor);
                opacity: .8;
            }
        }

        // background: var(--cardBG);
        // border-radius: 1rem;
        // box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);

        .filters {
            width: 100%;
            margin: .5rem 0;
            padding: 1rem 3rem;
            display: flex;
            align-items: center;
            gap: 2rem;

            // display: grid;
            // grid-template-columns: repeat(3, 1fr);
            // place-items: center;
            // gap: .5rem;

            .filterSelect,
            .searchTextInput,
            .testSelectionDropdown {
                flex: 1;
                min-width: 0;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: .5rem;
                // padding: 1rem .5rem;

                .title {
                    font-weight: 600;
                    font-size: .85rem;
                    color: var(--textColor);
                }

                .selectionDropdown,
                .selectionDropdownMultiple {
                    flex-grow: 1;
                    font-size: .87rem;

                    .css-wsp0cs-MultiValueGeneric {
                        max-width: 180px;
                        overflow: hidden;
                    }
                }

                input {
                    width: 100%;
                    border: hsl(0, 0%, 80%) solid 1px;
                    border-radius: 4px;
                    padding: .3rem .5rem;
                    font-size: .83rem;
                }
            }
        }
    }

    .tests-glass {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        min-height: 40vh;
        padding: 1.5rem 1rem .5rem 1rem;
        background: var(--whiteColor);
        border-radius: 2rem;
        gap: .5rem;
        box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);

        .title {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: .5rem;
            padding: .5rem;
            text-align: center;
            color: var(--SecondaryColor);
        }

        .bkgImg {
            width: 25rem;
        }
    }

    // #NTA-tests-glass{
    //     height: 100vh !important;
    //     box-sizing: border-box;
    //     justify-content: center;
    //     border-radius: 0 !important;
    //     padding: 0;
    //     gap: 0;
    //     overflow: hidden;
    // }

    .btn {
        color: var(--whiteColor);
        font-weight: 600;
        justify-content: space-between;
        gap: .5rem;

        .icon {
            align-self: center;
            font-size: 1rem;
        }
    }

    #startTest {
        background-color: var(--tiger-lilly);
    }

    .testReport-btn {
        position: absolute;
        top: 0;
        right: 0;
        justify-content: center;

        .report-btn {
            background-color: var(--finished);
            color: var(--whiteColor);
            border: none;
            border-radius: 1rem;
            font-size: .87rem;
            padding: .5rem 1rem;
            font-weight: 600;
            justify-content: center;
            align-items: center;
            gap: .5rem;

            .icon {
                align-self: center;
                font-size: 1.11rem;
            }

            &:hover {
                cursor: pointer;
                background-color: var(--SecondaryColor);
            }
        }
    }

    .test-refresh {
        position: absolute;
        top: 0;
        right: 0;
        justify-content: center;
        align-items: center;

        .msg {
            background-color: var(--started);
            color: var(--whiteColor);
            border: none;
            border-radius: 1rem;
            font-size: .87rem;
            padding: .5rem 1rem;
            font-weight: 600;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: .5rem;

            .icon {
                font-size: 1rem;
                font-weight: 600;
            }
        }

        &:hover {
            cursor: pointer;
        }
    }

    .test-status {
        position: absolute;
        top: 0;
        left: 0;
        justify-content: center;
        align-items: center;

        .msg {
            color: var(--whiteColor);
            border: none;
            border-radius: 1rem;
            font-size: .87rem;
            padding: .5rem 1rem;
            font-weight: 600;
            justify-content: center;
            align-items: center;
            gap: .5rem;
        }

        #started {
            background-color: var(--started);
        }

        #finished {
            background-color: var(--finished);
        }

        #not-started {
            background-color: var(--not-started);
        }
    }

    .testInfo-edit-btn {
        position: absolute;
        top: 0;
        right: 0;
        justify-content: center;
        align-items: center;

        .msg {
            background-color: var(--PrimaryColor);
            color: var(--whiteColor);
            border: none;
            justify-content: center;
            align-items: center;
            gap: .2rem;
            border-radius: 1rem;
            padding: .5rem 1rem;
            font-weight: 600;
            font-size: .87rem;

            .icon {
                font-size: 1.05rem;
                font-weight: 600;
            }
        }

        &:hover {
            cursor: pointer;

            .msg {
                background-color: var(--SecondaryColor);
            }
        }
    }

    .testEdit {
        position: relative;
        width: 100%;
        flex-direction: column;
        gap: .5rem;
        padding: .5rem;

        .testSubjectTabsContainer,
        .testSubjectCreateTabsContainer {
            transform: translateY(.5rem);
            flex-direction: row;
            padding: 0 1rem;
            justify-content: flex-start;
            align-items: center;

            .testSubjectTabs {
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: .01rem;
                overflow-x: auto;
                /* Allows horizontal scrolling */
                scroll-behavior: smooth;
                -ms-overflow-style: none;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                    display: none;
                    /* for Chrome, Safari, and Opera */
                }
            }

            .tabItem,
            .left-btn,
            .right-btn {
                white-space: nowrap;
                padding: .3rem 1rem 1.5rem 1rem;
                text-decoration: none;
                font-weight: 500;
                color: var(--whiteColor);
                background-color: var(--SecondaryBkg);
                font-size: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
                gap: .5rem;

                &:hover {
                    cursor: pointer;
                    background-color: var(--SecondaryColor);
                    font-weight: 600;
                }

                .icon {
                    font-size: 1.5rem;
                }

                .icon.delete-subject,
                .icon.add-subject {
                    &:hover {
                        color: var(--tiger-lilly);
                    }
                }
            }

            .tabItem.selected {
                color: var(--whiteColor);
                background-color: var(--SecondaryColor);
                font-weight: 600;
            }

            .left-btn,
            .right-btn {
                padding: .3rem .5rem 1.5rem .5rem;
            }
        }

        .testSubjectCreateTabsContainer {
            transform: translateY(0);
        }

        .testSectionTabsContainer,
        .testSectionCreateTabsContainer {
            transform: translateY(.5rem);
            flex-direction: row;
            padding: 0 1rem;
            justify-content: flex-start;
            align-items: center;

            .testSectionTabs {
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: .01rem;
                overflow-x: auto;
                /* Allows horizontal scrolling */
                scroll-behavior: smooth;
                -ms-overflow-style: none;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                    display: none;
                    /* for Chrome, Safari, and Opera */
                }
            }

            .tabItem,
            .left-btn,
            .right-btn {
                white-space: nowrap;
                padding: .3rem 1rem 1.5rem 1rem;
                text-decoration: none;
                font-weight: 500;
                color: var(--whiteColor);
                background-color: var(--PrimaryBkg);
                font-size: .95rem;
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
                gap: .5rem;

                &:hover {
                    cursor: pointer;
                    background-color: var(--PrimaryColor);
                    font-weight: 600;
                }

                .icon {
                    font-size: 1.425rem;
                }

                .icon.delete-section,
                .icon.add-section {
                    &:hover {
                        color: var(--tiger-lilly);
                    }
                }
            }

            .tabItem.selected {
                color: var(--whiteColor);
                background-color: var(--PrimaryColor);
                font-weight: 600;
            }

            .left-btn,
            .right-btn {
                padding: .3rem .5rem 1.5rem .5rem;
            }
        }

        .testSectionCreateTabsContainer {
            transform: translateY(0);
            margin-top: 1rem;
        }

        .testSubjectContentsContainer,
        .testSubjectCreateContentsContainer {
            position: relative;
            transform: translateY(-1.25rem);
            // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
            width: 100%;
            height: 100%;
            flex-direction: column;
            justify-content: flex-start;
            background-color: var(--whiteColorDeam);
            border-top: 8px solid var(--SecondaryColor);
            border-radius: .5rem;
            padding: 0 .5rem;
        }

        .testSubjectCreateContentsContainer {
            background-color: var(--cardBG);
        }

        .testSectionContentsContainer,
        .testSectionCreateContentsContainer {
            position: relative;
            transform: translateY(-1.25rem);
            // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
            width: 100%;
            height: 100%;
            flex-direction: column;
            // justify-content: flex-start;
            background-color: var(--whiteColor);
            border-top: 8px solid var(--PrimaryColor);
            border-radius: .5rem;
            padding: 0;
        }

        .testSectionCreateContentsContainer {
            gap: 1rem;
            padding: 1rem;
        }


    }

    .test-timer {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        .timerTitle {
            align-items: center;
            color: var(--PrimaryColor);
            gap: .3rem;

            .icon {
                font-size: 2rem;
            }
        }

        .timeDisplay {
            background-color: var(--SecondaryBkg);
            border: none;
            border-radius: .5rem;
            padding: .5rem 1rem;
            font-weight: 600;
            color: var(--midnight-blue);
            justify-content: center;
            align-items: center;

            span {
                // width: 50px;
                text-align: center;
                font-size: 2rem;
            }
        }

        .timeDisplayLimit {
            background-color: transparent;
            border: none;
            border-radius: .5rem;
            padding: .5rem 1rem;
            font-weight: 600;
            color: red;
            justify-content: center;
            align-items: center;

            span {
                text-align: center;
                font-size: 2rem;
            }
        }

        #startTest {
            font-size: 1.1rem;
        }

        .startTestDuration {
            width: 20%;
        }
    }

    .testModal {
        max-width: 80vw;
        max-height: 80vh;
        overflow-y: auto;
        background-color: var(--whiteColorDeam);
        padding: 1.5rem 1rem;
        position: fixed;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1000;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

        //     .testCreate {
        //         flex-direction: column;
        //         justify-content: center;
        //         align-items: center;
        //         gap: 1rem;

        //         .testTitle {

        //             .title {
        //                 align-items: center;
        //                 margin: 2rem 0;
        //                 font-size: 1.66rem;
        //             }
        //         }

        //         .testInfoInput {
        //             flex-direction: column;
        //             justify-content: space-between;
        //             align-items: flex-start;
        //             gap: 1.5rem;

        //             .title {
        //                 font-size: .87rem;
        //                 font-weight: 600;
        //                 margin-right: 10px;
        //             }

        //             input {
        //                 width: 200px;
        //                 border: none;
        //                 border-radius: .3rem;
        //                 padding: .5rem;
        //                 font-size: .83rem
        //             }
        //         }

        //         .btn {
        //             margin-top: 1rem;
        //             color: var(--whiteColor);
        //             font-weight: 600;
        //             justify-content: space-between;
        //             gap: .5rem;

        //             .icon {
        //                 align-self: center;
        //                 font-size: 1rem;
        //             }
        //         }

        //         #submit {
        //             transform: scale(1.2);
        //             margin: 2rem 0;

        //             span {
        //                 color: var(--midnight-blue);
        //             }
        //         }

        //         #close {
        //             position: absolute;
        //             top: 5px;
        //             right: 5px;
        //             color: var(--PrimaryColor);
        //             align-self: center;
        //             font-size: 1.66rem;
        //         }
        //     }
    }





    //MEDIA QUERIES ==================================================>
    @media all and (max-width: 1000px) {}

    @media all and (max-width: 860px) {
        .tests {
            gap: .5rem;
        }
    }

    @media all and (max-width: 800px) {
        .tests {
            padding: .5rem;
        }

        .tests-header {
            margin: .5rem 0;
            padding: .5rem 0;
        }
    }

    @media all and (max-width: 720px) {
        .tests {
            gap: .5rem;

            #createNew {
                margin: 0 0 .5rem 0;
                transform: scale(1.1);
            }
        }

        .testPage {
            width: 95%;
            padding: 1.5rem .5rem .5rem .5rem;
            gap: .5rem;
        }

        .tests-glass {
            padding: 1rem .5rem;
        }

        .testReport-btn {
            .report-btn {
                border-radius: .8rem;
                font-size: .75rem;
                padding: .2rem .8rem;
                gap: .2rem;

                .icon {
                    font-size: .94rem;
                }
            }
        }

        .test-status,
        .test-refresh,
        .testInfo-edit-btn {
            .msg {
                border-radius: .8rem;
                font-size: .8rem;
                padding: .2rem .8rem;
                gap: .2rem;

                .icon {
                    font-size: .95rem;
                }
            }
        }

        .testEdit {
            padding: .5rem 0;
            gap: .5rem;

            .test-add-btn {
                font-size: .94rem;
                padding: .7rem;
            }

            .testSubjectContentsContainer {
                padding: 0;
            }
        }

        .tests-filter {

            .filters {
                width: 80%;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0;
                gap: .5rem;

                .filterSelect {
                    width: 100%;
                }
            }
        }

        .test-timer {
            flex-direction: row;
            gap: .5rem;

            .timerTitle {
                margin-top: 0rem;
                font-size: .83rem;
            }

            .timeDisplay,
            .timeDisplayLimit {
                border-radius: .5rem;
                padding: .2rem .5rem;

                span {
                    font-size: 28px;
                }
            }
        }
    }

    @media all and (max-width: 550px) {

        .tests-header {
            margin: 0;
            padding: .5rem;
            font-size: .9rem;
        }

        .testPage {
            padding: 2rem .5rem .5rem .5rem;

            .testEdit {

                .test-add-btn {
                    font-size: .87rem;
                }
            }
        }

        .tests-filter {

            .filters {
                width: 90%;
            }
        }

        .tests-glass {
            padding: .5rem .1rem;

            .bkgImg {
                width: 90%;
            }
        }

        .test-timer {
            gap: .5rem;

            .timerTitle {
                font-size: .75rem;

                .icon {
                    font-size: 1.38rem;
                }
            }

            .timeDisplay,
            .timeDisplayLimit {
                span {
                    font-size: 1.38rem;
                }
            }
        }

        .test-status,
        .test-refresh,
        .testInfo-edit-btn {

            .msg {
                font-size: .75rem;
                padding: .2rem .5rem;

                .icon {
                    font-size: .9rem;
                }
            }
        }
    }
}