.jeeta-app,
.jeeta-app *,
.customModal,
.customModal * {
  /* Add styles for hovering over table rows */
}

.jeeta-app .slotLectureVideo,
.jeeta-app .slotLectureSummary,
.jeeta-app * .slotLectureVideo,
.jeeta-app * .slotLectureSummary,
.customModal .slotLectureVideo,
.customModal .slotLectureSummary,
.customModal * .slotLectureVideo,
.customModal * .slotLectureSummary {
  padding-top: .5rem;
  position: relative;
  width: 95%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
}

.jeeta-app .slotLectureVideo .videoPlayer,
.jeeta-app .slotLectureSummary .videoPlayer,
.jeeta-app * .slotLectureVideo .videoPlayer,
.jeeta-app * .slotLectureSummary .videoPlayer,
.customModal .slotLectureVideo .videoPlayer,
.customModal .slotLectureSummary .videoPlayer,
.customModal * .slotLectureVideo .videoPlayer,
.customModal * .slotLectureSummary .videoPlayer {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--whiteColorDeam);
  border-radius: 1rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
  overflow: hidden;
}

.jeeta-app .slotLectureVideo .videoPlayer .no-video-msg,
.jeeta-app .slotLectureVideo .videoPlayer .end-recording-msg,
.jeeta-app .slotLectureSummary .videoPlayer .no-video-msg,
.jeeta-app .slotLectureSummary .videoPlayer .end-recording-msg,
.jeeta-app * .slotLectureVideo .videoPlayer .no-video-msg,
.jeeta-app * .slotLectureVideo .videoPlayer .end-recording-msg,
.jeeta-app * .slotLectureSummary .videoPlayer .no-video-msg,
.jeeta-app * .slotLectureSummary .videoPlayer .end-recording-msg,
.customModal .slotLectureVideo .videoPlayer .no-video-msg,
.customModal .slotLectureVideo .videoPlayer .end-recording-msg,
.customModal .slotLectureSummary .videoPlayer .no-video-msg,
.customModal .slotLectureSummary .videoPlayer .end-recording-msg,
.customModal * .slotLectureVideo .videoPlayer .no-video-msg,
.customModal * .slotLectureVideo .videoPlayer .end-recording-msg,
.customModal * .slotLectureSummary .videoPlayer .no-video-msg,
.customModal * .slotLectureSummary .videoPlayer .end-recording-msg {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--tiger-lilly);
}

.jeeta-app .slotLectureVideo .videoPlayer .live-class-msg,
.jeeta-app .slotLectureSummary .videoPlayer .live-class-msg,
.jeeta-app * .slotLectureVideo .videoPlayer .live-class-msg,
.jeeta-app * .slotLectureSummary .videoPlayer .live-class-msg,
.customModal .slotLectureVideo .videoPlayer .live-class-msg,
.customModal .slotLectureSummary .videoPlayer .live-class-msg,
.customModal * .slotLectureVideo .videoPlayer .live-class-msg,
.customModal * .slotLectureSummary .videoPlayer .live-class-msg {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--PrimaryColor);
}

.jeeta-app .slotLectureVideo .videoPlayer #record,
.jeeta-app .slotLectureSummary .videoPlayer #record,
.jeeta-app * .slotLectureVideo .videoPlayer #record,
.jeeta-app * .slotLectureSummary .videoPlayer #record,
.customModal .slotLectureVideo .videoPlayer #record,
.customModal .slotLectureSummary .videoPlayer #record,
.customModal * .slotLectureVideo .videoPlayer #record,
.customModal * .slotLectureSummary .videoPlayer #record {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.jeeta-app .slotLectureVideo .videoPlayer #record:hover,
.jeeta-app .slotLectureSummary .videoPlayer #record:hover,
.jeeta-app * .slotLectureVideo .videoPlayer #record:hover,
.jeeta-app * .slotLectureSummary .videoPlayer #record:hover,
.customModal .slotLectureVideo .videoPlayer #record:hover,
.customModal .slotLectureSummary .videoPlayer #record:hover,
.customModal * .slotLectureVideo .videoPlayer #record:hover,
.customModal * .slotLectureSummary .videoPlayer #record:hover {
  background-color: var(--tiger-lilly);
}

.jeeta-app .btns,
.jeeta-app * .btns,
.customModal .btns,
.customModal * .btns {
  width: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 1rem;
}

.jeeta-app #back,
.jeeta-app #edit,
.jeeta-app * #back,
.jeeta-app * #edit,
.customModal #back,
.customModal #edit,
.customModal * #back,
.customModal * #edit {
  background-color: var(--SecondaryColor);
}

.jeeta-app #delete,
.jeeta-app * #delete,
.customModal #delete,
.customModal * #delete {
  background-color: var(--tiger-lilly);
}

.jeeta-app .confirmPopup,
.jeeta-app * .confirmPopup,
.customModal .confirmPopup,
.customModal * .confirmPopup {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: var(--whiteColorDeam);
  padding: 2.5rem 3rem 1rem 3rem;
  border-radius: 1rem;
  -webkit-box-shadow: 0px 0px 10px rgba(189, 195, 199, 0.5);
          box-shadow: 0px 0px 10px rgba(189, 195, 199, 0.5);
  z-index: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
}

.jeeta-app .confirmPopup .radio-group,
.jeeta-app * .confirmPopup .radio-group,
.customModal .confirmPopup .radio-group,
.customModal * .confirmPopup .radio-group {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: .5rem;
}

.jeeta-app .confirmPopup .radio-group .radio-option,
.jeeta-app * .confirmPopup .radio-group .radio-option,
.customModal .confirmPopup .radio-group .radio-option,
.customModal * .confirmPopup .radio-group .radio-option {
  padding: .5rem;
  font-size: 1rem;
  font-weight: 500;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: .2rem;
}

.jeeta-app .slotPinAuthModal,
.jeeta-app * .slotPinAuthModal,
.customModal .slotPinAuthModal,
.customModal * .slotPinAuthModal {
  background-color: var(--whiteColorDeam);
  padding: 2rem;
  position: absolute;
  top: 55%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 1000;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
}

.jeeta-app .slotPinAuthModal .modalTitle,
.jeeta-app * .slotPinAuthModal .modalTitle,
.customModal .slotPinAuthModal .modalTitle,
.customModal * .slotPinAuthModal .modalTitle {
  padding: 1rem;
}

.jeeta-app .slotPinAuthModal .pinInput,
.jeeta-app * .slotPinAuthModal .pinInput,
.customModal .slotPinAuthModal .pinInput,
.customModal * .slotPinAuthModal .pinInput {
  width: 80%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: .5rem;
}

.jeeta-app .slotPinAuthModal .lectureInfoInput,
.jeeta-app * .slotPinAuthModal .lectureInfoInput,
.customModal .slotPinAuthModal .lectureInfoInput,
.customModal * .slotPinAuthModal .lectureInfoInput {
  width: 80%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
}

.jeeta-app .slotPinAuthModal .lectureInfoInput .infoLabel,
.jeeta-app * .slotPinAuthModal .lectureInfoInput .infoLabel,
.customModal .slotPinAuthModal .lectureInfoInput .infoLabel,
.customModal * .slotPinAuthModal .lectureInfoInput .infoLabel {
  font-weight: 600;
}

.jeeta-app .slotPinAuthModal .lectureInfoInput .textInput,
.jeeta-app * .slotPinAuthModal .lectureInfoInput .textInput,
.customModal .slotPinAuthModal .lectureInfoInput .textInput,
.customModal * .slotPinAuthModal .lectureInfoInput .textInput {
  padding: .5rem;
  border: none;
}

.jeeta-app .slotPinAuthModal #enterPIN,
.jeeta-app * .slotPinAuthModal #enterPIN,
.customModal .slotPinAuthModal #enterPIN,
.customModal * .slotPinAuthModal #enterPIN {
  margin-top: 1rem;
  font-weight: 600;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.jeeta-app .slotPinAuthModal #close,
.jeeta-app * .slotPinAuthModal #close,
.customModal .slotPinAuthModal #close,
.customModal * .slotPinAuthModal #close {
  position: absolute;
  top: .3rem;
  right: .3rem;
  color: var(--PrimaryColor);
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
}

.jeeta-app .slotPinAuthModal #close:hover,
.jeeta-app * .slotPinAuthModal #close:hover,
.customModal .slotPinAuthModal #close:hover,
.customModal * .slotPinAuthModal #close:hover {
  color: var(--tiger-lilly);
}

.jeeta-app .slotLiveDoubts,
.jeeta-app * .slotLiveDoubts,
.customModal .slotLiveDoubts,
.customModal * .slotLiveDoubts {
  padding-top: .5rem;
  position: relative;
  width: 95%;
  height: 100%;
  gap: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.jeeta-app .doubtsContainer,
.jeeta-app * .doubtsContainer,
.customModal .doubtsContainer,
.customModal * .doubtsContainer {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--whiteColorDeam);
  border-radius: 1rem;
  padding: .5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
  min-height: 25rem;
}

.jeeta-app .doubtsContainer .title,
.jeeta-app * .doubtsContainer .title,
.customModal .doubtsContainer .title,
.customModal * .doubtsContainer .title {
  color: var(--textColor);
}

.jeeta-app .doubtsContainer .no-live-doubts-msg,
.jeeta-app * .doubtsContainer .no-live-doubts-msg,
.customModal .doubtsContainer .no-live-doubts-msg,
.customModal * .doubtsContainer .no-live-doubts-msg {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--tiger-lilly);
}

.jeeta-app #doubtsRefresh,
.jeeta-app * #doubtsRefresh,
.customModal #doubtsRefresh,
.customModal * #doubtsRefresh {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.jeeta-app .doubtsList,
.jeeta-app * .doubtsList,
.customModal .doubtsList,
.customModal * .doubtsList {
  padding: .5rem 0;
  width: 80%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 0.5rem;
}

.jeeta-app .doubtsList .doubtItem,
.jeeta-app * .doubtsList .doubtItem,
.customModal .doubtsList .doubtItem,
.customModal * .doubtsList .doubtItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  color: var(--whiteColor);
  background-color: var(--PrimaryBkg);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.jeeta-app .doubtsList .doubtItem .doubt-text,
.jeeta-app * .doubtsList .doubtItem .doubt-text,
.customModal .doubtsList .doubtItem .doubt-text,
.customModal * .doubtsList .doubtItem .doubt-text {
  color: var(--textColor);
  font-weight: 600;
}

.jeeta-app .doubtsList .doubtItem .time,
.jeeta-app * .doubtsList .doubtItem .time,
.customModal .doubtsList .doubtItem .time,
.customModal * .doubtsList .doubtItem .time {
  -ms-flex-item-align: end;
      align-self: flex-end;
}

.jeeta-app .doubtsList .doubtItem.even,
.jeeta-app * .doubtsList .doubtItem.even,
.customModal .doubtsList .doubtItem.even,
.customModal * .doubtsList .doubtItem.even {
  background-color: var(--SecondaryBkg);
}

.jeeta-app .doubtClusterList,
.jeeta-app * .doubtClusterList,
.customModal .doubtClusterList,
.customModal * .doubtClusterList {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[4];
      grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

.jeeta-app .newDoubtForm,
.jeeta-app * .newDoubtForm,
.customModal .newDoubtForm,
.customModal * .newDoubtForm {
  width: 80%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: .5rem 0;
  gap: 1rem;
}

.jeeta-app .newDoubtForm .newDoubtInput,
.jeeta-app * .newDoubtForm .newDoubtInput,
.customModal .newDoubtForm .newDoubtInput,
.customModal * .newDoubtForm .newDoubtInput {
  width: 100%;
  font-size: 1rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid var(--SecondaryColor);
}

.jeeta-app .newDoubtForm .submitDoubtBtn,
.jeeta-app * .newDoubtForm .submitDoubtBtn,
.customModal .newDoubtForm .submitDoubtBtn,
.customModal * .newDoubtForm .submitDoubtBtn {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: none;
  background-color: var(--PrimaryColor);
  color: var(--whiteColor);
  cursor: pointer;
}

.jeeta-app .newDoubtForm .submitDoubtBtn:hover,
.jeeta-app * .newDoubtForm .submitDoubtBtn:hover,
.customModal .newDoubtForm .submitDoubtBtn:hover,
.customModal * .newDoubtForm .submitDoubtBtn:hover {
  background-color: var(--SecondaryColor);
}

.jeeta-app .chapters-table-container,
.jeeta-app * .chapters-table-container,
.customModal .chapters-table-container,
.customModal * .chapters-table-container {
  margin-top: 1.2rem;
}

.jeeta-app .chapters-table,
.jeeta-app * .chapters-table,
.customModal .chapters-table,
.customModal * .chapters-table {
  width: 100%;
  border-collapse: collapse;
}

.jeeta-app .chapters-table th,
.jeeta-app .chapters-table td,
.jeeta-app * .chapters-table th,
.jeeta-app * .chapters-table td,
.customModal .chapters-table th,
.customModal .chapters-table td,
.customModal * .chapters-table th,
.customModal * .chapters-table td {
  border: 1px solid #ddd;
  padding: .5rem;
  text-align: left;
}

.jeeta-app .chapters-table th,
.jeeta-app * .chapters-table th,
.customModal .chapters-table th,
.customModal * .chapters-table th {
  background-color: #f2f2f2;
}

.jeeta-app .chapters-table tbody tr:hover,
.jeeta-app * .chapters-table tbody tr:hover,
.customModal .chapters-table tbody tr:hover,
.customModal * .chapters-table tbody tr:hover {
  background-color: #eaeaea;
  /* Light grey background on hover */
  cursor: pointer;
  /* Change cursor to indicate clickability */
}

.jeeta-app .video-chapter,
.jeeta-app * .video-chapter,
.customModal .video-chapter,
.customModal * .video-chapter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.jeeta-app #meetingSDKElement,
.jeeta-app * #meetingSDKElement,
.customModal #meetingSDKElement,
.customModal * #meetingSDKElement {
  height: 100%;
  width: 100%;
  z-index: 10;
}

.jeeta-app .MuiPopper-root,
.jeeta-app * .MuiPopper-root,
.customModal .MuiPopper-root,
.customModal * .MuiPopper-root {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  -webkit-transform: translate(-50%, -50%) !important;
          transform: translate(-50%, -50%) !important;
  z-index: 9999;
  /* Ensure it stays on top */
}

.jeeta-app .slotQuestions,
.jeeta-app * .slotQuestions,
.customModal .slotQuestions,
.customModal * .slotQuestions {
  padding-top: .5rem;
  position: relative;
  width: 95%;
  height: 100%;
  gap: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.jeeta-app .slotQuestions .title,
.jeeta-app * .slotQuestions .title,
.customModal .slotQuestions .title,
.customModal * .slotQuestions .title {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.jeeta-app .slotQuestions .slotQuestionSlider,
.jeeta-app * .slotQuestions .slotQuestionSlider,
.customModal .slotQuestions .slotQuestionSlider,
.customModal * .slotQuestions .slotQuestionSlider {
  position: relative;
  width: 95%;
  min-height: 13rem;
  background: var(--cardBG);
  border-radius: 1rem;
  -webkit-box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
          box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
  padding: 0 .5rem;
}

@media all and (max-width: 1000px) {
  .doubtClusterList {
    -ms-grid-columns: (1fr)[3];
        grid-template-columns: repeat(3, 1fr);
    gap: .5rem;
  }
  .chapters-table-wrapper {
    max-height: 35rem;
  }
}

@media all and (max-width: 1000px) and (max-width: 860px) {
  .doubtsContainer {
    min-height: auto;
  }
  .chapters-table-wrapper {
    max-height: 30rem;
  }
}

@media all and (max-width: 1000px) and (max-width: 720px) {
  .slotLectureVideo .videoPlayer,
  .slotLectureSummary .videoPlayer {
    min-height: 300px;
  }
  .slotLectureVideo .videoPlayer .no-video-msg,
  .slotLectureVideo .videoPlayer .live-class-msg,
  .slotLectureVideo .videoPlayer .end-recording-msg,
  .slotLectureSummary .videoPlayer .no-video-msg,
  .slotLectureSummary .videoPlayer .live-class-msg,
  .slotLectureSummary .videoPlayer .end-recording-msg {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  .slotLectureVideo .videoPlayer #record,
  .slotLectureSummary .videoPlayer #record {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  .slotLiveDoubts .doubtsList,
  .slotLiveDoubts .newDoubtForm {
    width: 95%;
  }
  .doubtClusterList {
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
  }
  .slotPinAuthModal {
    padding: 1.5rem;
  }
  .slotQuestions .slotQuestionSlider {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: .5rem;
    padding: 0;
  }
}

@media all and (max-width: 1000px) and (max-width: 550px) {
  .slotLectureVideo .videoPlayer .no-video-msg,
  .slotLectureVideo .videoPlayer .live-class-msg,
  .slotLectureVideo .videoPlayer .end-recording-msg,
  .slotLectureSummary .videoPlayer .no-video-msg,
  .slotLectureSummary .videoPlayer .live-class-msg,
  .slotLectureSummary .videoPlayer .end-recording-msg {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  .slotLectureVideo .videoPlayer #record,
  .slotLectureSummary .videoPlayer #record {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  .slotLiveDoubts {
    gap: .5rem;
  }
  .slotLiveDoubts #doubtsRefresh {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  .doubtsContainer {
    min-height: 20rem;
  }
  .doubtsContainer .title {
    font-size: .9rem;
  }
  .doubtClusterList {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .slotPinAuthModal {
    padding: 1rem;
  }
  .vjs-control {
    width: 7% !important;
  }
  .vjs-time-control {
    width: 10% !important;
    padding: 0 !important;
  }
  .vjs-progress-control {
    min-width: 30% !important;
  }
}
