.jeeta-app,
.jeeta-app *,
.customModal,
.customModal * {
    .syllabus {
        position: relative;
        z-index: 0;
        width: 100%;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        padding: 1rem;
        color: var(--textColor);
        animation: fadeUp 1.2s ease-out forwards;
        /* Example duration and timing */
    }

    .syllabus-header {
        position: relative;
        width: 100%;
        padding: .5rem 1rem;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        background: var(--gradientBkg);
        border-radius: 1rem;
        box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);

        .title {
            color: var(--whiteColor);
            text-shadow: 0 2px 4px rgba(140, 140, 141, 0.3);
            text-align: center;
            gap: 1rem;
        }
    }

    .syllabus-glass {
        position: relative;
        z-index: 0;
        display: grid;
        width: 100%;
        padding: 1rem 0;
        background: var(--whiteColor);
        border-radius: 2rem;
        gap: 1rem;
        grid-template-columns: 11rem auto;
        // overflow: hidden;
        box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
    }

    .syllabus-main {
        flex-grow: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        gap: .5rem;

        .title {
            font-size: 1.5rem;
            color: var(--PrimaryColor);
            padding: .5rem;
        }

        .syllabus-list {
            max-height: 15rem;
            overflow-y: auto;
            flex-direction: row;
            padding: .5rem;
            gap: 1rem;
            // max-width: 34%;
            // white-space: normal;
            flex-wrap: wrap;
            font-size: 1rem;

            .item {
                display: flex;
                align-items: center;
                gap: .5rem;

                span {
                    overflow-x: scroll;
                    white-space: normal;

                    /* Hide scrollbar for Chrome, Safari, and newer versions of Opera*/
                    &::-webkit-scrollbar {
                        display: none;
                    }

                    /* Hide scrollbar for IE, Edge, and Firefox */
                    -ms-overflow-style: none;
                    /* IE and Edge */
                    scrollbar-width: none;
                    /* Firefox */
                }
            }
        }
    }

    .syllabus-filter {
        z-index: 2;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        padding: .5rem .5rem 0 .5rem;
        gap: .5rem;

        #filter {
            font-size: .95rem;
            font-weight: 600;
        }

        .subject-grade-filter {
            flex-direction: row;
        }
    }

    .filter {
        width: 100%;
        // background: var(--glass);
        border-radius: .5rem;
        padding: .5rem;
        align-items: center;
        flex-wrap: wrap;

        .filter-label {
            // margin-top: .5rem;
            padding: .35rem .5rem;
            // border-radius: .5rem;
            color: var(--textColor);
            font-size: 1rem;
            font-weight: 600;
            white-space: nowrap;
            // margin-right: .5rem;

            span {
                overflow-x: auto;
            }
        }

        span {
            display: flex;
            gap: .5rem;
        }

        .filterDropdown {
            display: flex;
            flex-grow: 1;

            .title {
                padding: 0;
            }

            .selectionDropdownMultiple {
                width: 100%;

                .css-13cymwt-control {
                    font-size: .95rem;

                    .css-wsp0cs-MultiValueGeneric {
                        max-width: 50vh;
                    }
                }
            }
        }

    }

    .topicModal {
        position: relative;
        /* make the modal appear above other content */
        top: 50%;
        /* centering the modal vertically */
        left: 50%;
        /* centering the modal horizontally */
        transform: translate(-50%, -50%);
        /* necessary adjustments for centering */
        z-index: 1010;
        /* high value to ensure modal is on top */
        width: 500px;
        // overflow-y: auto;       /* display scrollbar only when necessary */
        background-color: var(--whiteColorDeam);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        border-radius: .5rem;
        padding: 1.5rem 1rem;
        color: var(--textColor);

        .topicCreate {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1rem;

            .topicTitle {
                align-items: center;
                margin: 1rem 0;
                font-size: 25px;
            }

            .topicInfoInput {
                width: 80%;
                flex-direction: column;
                justify-content: flex-start;
                gap: 1rem;

                .textInput {
                    width: 100%;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    gap: .5rem;

                    .title {
                        width: 90px;
                        font-size: 16px;
                        font-weight: 600;
                    }

                    input {
                        flex-grow: 1;
                        border: hsl(0, 0%, 80%) solid 1px;
                        border-radius: .3rem;
                        padding: .3rem .7rem;
                        font-size: 15px;
                    }
                }

                .topicSelectionDropdown {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: .5rem;

                    .title {
                        width: 90px;
                        font-size: 16px;
                        font-weight: 600;
                    }

                    .selectionDropdown {
                        flex-grow: 1;
                        font-size: 15px;

                        .css-wsp0cs-MultiValueGeneric {
                            max-width: 180px;
                            overflow: hidden;
                        }
                    }
                }
            }

            .btn {
                margin-top: 1rem;
                color: var(--whiteColor);
                font-weight: 600;
                justify-content: space-between;
                gap: .5rem;

                .icon {
                    align-self: center;
                    font-size: 18px;
                }
            }

            #close {
                position: absolute;
                top: 5px;
                right: 5px;
                color: var(--PrimaryColor);
                align-self: center;
                font-size: 30px;

                &:hover {
                    color: var(--tiger-lilly);
                }
            }
        }
    }

    .syllabusSlotList {
        width: 100%;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        padding: 1rem 0 0 0;
    }

    .syllabusReadingMaterialList {
        width: 100%;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        padding: 1rem 0 0 0;
    }

    .syllabusRecordedVideoList {
        width: 100%;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        padding: 1rem 0 0 0;
    }

    //MEDIA QUERIES ==================================================>
    @media screen and (min-width: 1400px) {}

    @media all and (max-width:860px) {}

    @media all and (max-width: 800px) {
        .syllabus {
            padding: .5rem;
            gap: .5rem;
        }

        .syllabus-header {
            margin: .5rem 0;
            padding: .5rem 0;
        }

        .syllabus-glass {
            grid-template-columns: 3rem auto;
        }
    }

    @media all and (max-width:550px) {

        .syllabus-header {
            margin: 0;
            padding: .5rem;
            font-size: .9rem;
        }

        .syllabus-glass {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 0;
            padding: 0;
        }

        .syllabus-main {
            padding: 0;
        }

        .syllabus-filter {
            .subject-grade-filter {
                flex-direction: column;
            }
        }

        .filter {
            padding: 0;

            .filter-label {
                font-size: .9rem;
            }

            .filterDropdown {
                padding: .2rem .5rem;

                .title {
                    font-size: 0.9rem;
                }

                .selectionDropdown,
                .selectionDropdownMultiple {
                    font-size: 0.8rem;
                }

                input {
                    font-size: 0.8rem;
                }
            }
        }

        .syllabusSlotList {
            padding: .5rem;
        }
    }
}