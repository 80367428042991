.jeeta-app .Cards,
.jeeta-app * .Cards {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
}

.jeeta-app .cardContainer,
.jeeta-app * .cardContainer {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  max-width: 450px;
}

@media all and (max-width: 1200px) {
  .jeeta-app .Cards,
  .jeeta-app * .Cards {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
    /* Creates 2 columns */
    grid-gap: 1rem;
  }
}

@media all and (max-width: 720px) {
  .jeeta-app .Cards,
  .jeeta-app * .Cards {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .jeeta-app .cardContainer,
  .jeeta-app * .cardContainer {
    min-width: 75%;
  }
}

@media all and (max-width: 550px) {
  .jeeta-app .cardContainer,
  .jeeta-app * .cardContainer {
    min-width: 90%;
  }
}
