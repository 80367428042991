.jeeta-app,
.jeeta-app *,
.customModal,
.customModal * {

    .slotTestEdit,
    .slotTestView {
        position: relative;
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: center;
        gap: 1rem;

        //Buttons in Live Quiz
        .quizSelect {
            width: 100%;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: .5rem;

            .createNewQuiz {
                color: var(--PrimaryColor);
                font-size: 1.8rem;

                &:hover {
                    cursor: pointer;
                    color: var(--SecondaryColor);
                }
            }

            .quizSelectionDropdown {
                display: flex;
                justify-content: center;
                align-items: center;

                .selectionDropdown {
                    flex-grow: 1;
                }
            }
        }

        //Test Area in Live Quiz
        .quizDiv {

            .testTimer {
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 1rem;
            }

            .testReport {
                position: absolute;
                top: 0;
                right: 0;
                justify-content: center;

                .report-btn {
                    background-color: var(--finished);
                    color: var(--whiteColor);
                    border: none;
                    border-radius: .3rem;
                    font-size: .83rem;
                    padding: .3rem .6rem;
                    font-weight: 600;
                    justify-content: center;
                    align-items: center;
                    gap: .5rem;

                    .icon {
                        align-self: center;
                        font-size: 1rem;
                    }

                    &:hover {
                        cursor: pointer;
                        background-color: var(--SecondaryColor);
                    }
                }
            }

            .testStatus {
                position: absolute;
                top: 0;
                left: 0;
                justify-content: center;

                .msg {
                    color: var(--whiteColor);
                    border: none;
                    border-radius: .3rem;
                    font-size: .83rem;
                    padding: .3rem .6rem;
                    font-weight: 600;
                    justify-content: center;
                    align-items: center;
                    gap: .5rem;
                }

                #started {
                    background-color: var(--started);
                }

                #finished {
                    background-color: var(--finished);
                }

                #not-started {
                    background-color: var(--not-started);
                }
            }

            .testRefresh {
                position: absolute;
                top: 0;
                right: 0;
                justify-content: center;

                .msg {
                    background-color: var(--started);
                    color: var(--whiteColor);
                    border: none;
                    border-radius: .3rem;
                    font-size: .83rem;
                    padding: .3rem .6rem;
                    font-weight: 600;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: .5rem;

                    .icon {
                        font-size: 1rem;
                        font-weight: 600;
                    }
                }

                &:hover {
                    cursor: pointer;
                }
            }


            .testEdit,
            .testSubject {
                width: 100%;
                flex-grow: 1;
                box-shadow: none;
                padding: 0;

                .hideIcon {
                    display: none;
                }

                .testSection,
                .testSectionContainer {
                    width: 100%;
                    flex-grow: 1;
                }
            }
        }
    }

    .slotTestCreateModal {
        position: absolute;
        top: 55vh;
        left: 50vw;
        transform: translate(-50%, -50%);
        z-index: 1000;
        background-color: var(--whiteColorDeam);
        padding: 2rem;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        .modalTitle {
            text-align: center;
            padding: 1rem;
            text-wrap: nowrap;
        }

        .testInfoInput {
            width: 90%;
            justify-content: flex-start;
            align-items: center;
            gap: .5rem;

            .infoLabel {
                // width: 120px;
                font-weight: 600;
            }

            .textInput {
                flex-grow: 1;
                padding: .5rem;
                border: none;
                min-width: 0;
            }

            .numberInput {
                width: 80px;
                padding: .5rem;
                border: none;
                box-sizing: border-box;
            }
        }

        #create {
            margin-top: 1rem;
            font-weight: 600;
            transform: scale(1.1);
        }

        #close {
            position: absolute;
            top: .3rem;
            right: .3rem;
            color: var(--PrimaryColor);
            align-self: center;

            &:hover {
                color: var(--tiger-lilly);
            }
        }
    }


    //MEDIA QUERIES ==================================================>
    @media all and (min-width: 1800px) {}

    @media all and (max-width: 1000px) {}

    @media all and (max-width: 720px) {

        .slotTestEdit,
        .slotTestView {
            gap: .5rem;

            .quizSelect {
                .quizSelectionDropdown {

                    .selectionDropdown {
                        font-size: .95rem;
                    }
                }
            }

            //Test Area in Live Quiz
            .quizDiv {
                padding: 1.5rem .5rem .5rem .5rem;
                gap: .5rem;

                .testTimer {
                    transform: scale(0.95);
                }
            }
        }
    }

    @media all and (max-width: 500px) {

        .slotTestEdit,
        .slotTestView {
            gap: .5rem;

            .quizSelect {
                .quizSelectionDropdown {

                    .selectionDropdown {
                        font-size: .9rem;
                    }
                }
            }

            //Test Area in Live Quiz
            .quizDiv {
                min-height: 20rem;

                .testTimer {
                    margin-top: .5rem;
                }
            }
        }

        .slotTestCreateModal {
            width: 90%;
        }
    }
}