.jeeta-app .doubt-cluster-card,
.jeeta-app * .doubt-cluster-card {
  background-color: var(--PrimaryBkg);
  border-radius: 1rem;
  padding: 1rem;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: .3rem;
}

.jeeta-app .doubt-cluster-card:hover,
.jeeta-app * .doubt-cluster-card:hover {
  background-color: var(--SecondaryBkg);
  cursor: pointer;
}

.jeeta-app .doubt-cluster-card .doubt-cluster-text,
.jeeta-app * .doubt-cluster-card .doubt-cluster-text {
  text-align: center;
  font-weight: 600;
  font-size: 1.3rem;
  color: var(--whiteColor);
}

.jeeta-app .doubt-cluster-card.not-expanded,
.jeeta-app * .doubt-cluster-card.not-expanded {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  max-height: 3rem;
  overflow: hidden;
}

.jeeta-app .doubt-cluster-card.expanded,
.jeeta-app * .doubt-cluster-card.expanded {
  background-color: var(--SecondaryBkg);
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  max-height: 18rem;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
}

.jeeta-app .doubt-cluster-card.expanded:hover,
.jeeta-app * .doubt-cluster-card.expanded:hover {
  background-color: var(--PrimaryBkg);
}

.jeeta-app .doubt-cluster-card.expanded .doubt-cluster-text,
.jeeta-app * .doubt-cluster-card.expanded .doubt-cluster-text {
  margin-bottom: .5rem;
}

.jeeta-app .doubt-card,
.jeeta-app * .doubt-card {
  background: var(--glass);
  border: none;
  border-radius: .5rem;
  padding: .5rem;
}

.jeeta-app .doubt-card .doubt-text,
.jeeta-app * .doubt-card .doubt-text {
  font-size: .9rem;
  color: var(--textColor);
}

@media all and (max-width: 860px) {
  .jeeta-app .doubt-cluster-card .doubt-cluster-text,
  .jeeta-app * .doubt-cluster-card .doubt-cluster-text {
    text-align: center;
    font-weight: 600;
    font-size: 1.1rem;
    color: var(--whiteColor);
  }
}

@media all and (max-width: 500px) {
  .jeeta-app .doubt-cluster-card,
  .jeeta-app * .doubt-cluster-card {
    padding: .5rem;
  }
  .jeeta-app .doubt-cluster-card .doubt-cluster-text,
  .jeeta-app * .doubt-cluster-card .doubt-cluster-text {
    font-size: 1.1rem;
  }
}
