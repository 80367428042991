.jeeta-app .syllabus,
.jeeta-app * .syllabus,
.customModal .syllabus,
.customModal * .syllabus {
  position: relative;
  z-index: 0;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
  padding: 1rem;
  color: var(--textColor);
  -webkit-animation: fadeUp 1.2s ease-out forwards;
          animation: fadeUp 1.2s ease-out forwards;
  /* Example duration and timing */
}

.jeeta-app .syllabus-header,
.jeeta-app * .syllabus-header,
.customModal .syllabus-header,
.customModal * .syllabus-header {
  position: relative;
  width: 100%;
  padding: .5rem 1rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
  background: var(--gradientBkg);
  border-radius: 1rem;
  -webkit-box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
          box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
}

.jeeta-app .syllabus-header .title,
.jeeta-app * .syllabus-header .title,
.customModal .syllabus-header .title,
.customModal * .syllabus-header .title {
  color: var(--whiteColor);
  text-shadow: 0 2px 4px rgba(140, 140, 141, 0.3);
  text-align: center;
  gap: 1rem;
}

.jeeta-app .syllabus-glass,
.jeeta-app * .syllabus-glass,
.customModal .syllabus-glass,
.customModal * .syllabus-glass {
  position: relative;
  z-index: 0;
  display: -ms-grid;
  display: grid;
  width: 100%;
  padding: 1rem 0;
  background: var(--whiteColor);
  border-radius: 2rem;
  gap: 1rem;
  -ms-grid-columns: 11rem auto;
      grid-template-columns: 11rem auto;
  -webkit-box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
          box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
}

.jeeta-app .syllabus-main,
.jeeta-app * .syllabus-main,
.customModal .syllabus-main,
.customModal * .syllabus-main {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 1rem;
  gap: .5rem;
}

.jeeta-app .syllabus-main .title,
.jeeta-app * .syllabus-main .title,
.customModal .syllabus-main .title,
.customModal * .syllabus-main .title {
  font-size: 1.5rem;
  color: var(--PrimaryColor);
  padding: .5rem;
}

.jeeta-app .syllabus-main .syllabus-list,
.jeeta-app * .syllabus-main .syllabus-list,
.customModal .syllabus-main .syllabus-list,
.customModal * .syllabus-main .syllabus-list {
  max-height: 15rem;
  overflow-y: auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding: .5rem;
  gap: 1rem;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  font-size: 1rem;
}

.jeeta-app .syllabus-main .syllabus-list .item,
.jeeta-app * .syllabus-main .syllabus-list .item,
.customModal .syllabus-main .syllabus-list .item,
.customModal * .syllabus-main .syllabus-list .item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: .5rem;
}

.jeeta-app .syllabus-main .syllabus-list .item span,
.jeeta-app * .syllabus-main .syllabus-list .item span,
.customModal .syllabus-main .syllabus-list .item span,
.customModal * .syllabus-main .syllabus-list .item span {
  overflow-x: scroll;
  white-space: normal;
  /* Hide scrollbar for Chrome, Safari, and newer versions of Opera*/
  /* Hide scrollbar for IE, Edge, and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.jeeta-app .syllabus-main .syllabus-list .item span::-webkit-scrollbar,
.jeeta-app * .syllabus-main .syllabus-list .item span::-webkit-scrollbar,
.customModal .syllabus-main .syllabus-list .item span::-webkit-scrollbar,
.customModal * .syllabus-main .syllabus-list .item span::-webkit-scrollbar {
  display: none;
}

.jeeta-app .syllabus-filter,
.jeeta-app * .syllabus-filter,
.customModal .syllabus-filter,
.customModal * .syllabus-filter {
  z-index: 2;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: .5rem .5rem 0 .5rem;
  gap: .5rem;
}

.jeeta-app .syllabus-filter #filter,
.jeeta-app * .syllabus-filter #filter,
.customModal .syllabus-filter #filter,
.customModal * .syllabus-filter #filter {
  font-size: .95rem;
  font-weight: 600;
}

.jeeta-app .syllabus-filter .subject-grade-filter,
.jeeta-app * .syllabus-filter .subject-grade-filter,
.customModal .syllabus-filter .subject-grade-filter,
.customModal * .syllabus-filter .subject-grade-filter {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.jeeta-app .filter,
.jeeta-app * .filter,
.customModal .filter,
.customModal * .filter {
  width: 100%;
  border-radius: .5rem;
  padding: .5rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.jeeta-app .filter .filter-label,
.jeeta-app * .filter .filter-label,
.customModal .filter .filter-label,
.customModal * .filter .filter-label {
  padding: .35rem .5rem;
  color: var(--textColor);
  font-size: 1rem;
  font-weight: 600;
  white-space: nowrap;
}

.jeeta-app .filter .filter-label span,
.jeeta-app * .filter .filter-label span,
.customModal .filter .filter-label span,
.customModal * .filter .filter-label span {
  overflow-x: auto;
}

.jeeta-app .filter span,
.jeeta-app * .filter span,
.customModal .filter span,
.customModal * .filter span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: .5rem;
}

.jeeta-app .filter .filterDropdown,
.jeeta-app * .filter .filterDropdown,
.customModal .filter .filterDropdown,
.customModal * .filter .filterDropdown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.jeeta-app .filter .filterDropdown .title,
.jeeta-app * .filter .filterDropdown .title,
.customModal .filter .filterDropdown .title,
.customModal * .filter .filterDropdown .title {
  padding: 0;
}

.jeeta-app .filter .filterDropdown .selectionDropdownMultiple,
.jeeta-app * .filter .filterDropdown .selectionDropdownMultiple,
.customModal .filter .filterDropdown .selectionDropdownMultiple,
.customModal * .filter .filterDropdown .selectionDropdownMultiple {
  width: 100%;
}

.jeeta-app .filter .filterDropdown .selectionDropdownMultiple .css-13cymwt-control,
.jeeta-app * .filter .filterDropdown .selectionDropdownMultiple .css-13cymwt-control,
.customModal .filter .filterDropdown .selectionDropdownMultiple .css-13cymwt-control,
.customModal * .filter .filterDropdown .selectionDropdownMultiple .css-13cymwt-control {
  font-size: .95rem;
}

.jeeta-app .filter .filterDropdown .selectionDropdownMultiple .css-13cymwt-control .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .filter .filterDropdown .selectionDropdownMultiple .css-13cymwt-control .css-wsp0cs-MultiValueGeneric,
.customModal .filter .filterDropdown .selectionDropdownMultiple .css-13cymwt-control .css-wsp0cs-MultiValueGeneric,
.customModal * .filter .filterDropdown .selectionDropdownMultiple .css-13cymwt-control .css-wsp0cs-MultiValueGeneric {
  max-width: 50vh;
}

.jeeta-app .topicModal,
.jeeta-app * .topicModal,
.customModal .topicModal,
.customModal * .topicModal {
  position: relative;
  /* make the modal appear above other content */
  top: 50%;
  /* centering the modal vertically */
  left: 50%;
  /* centering the modal horizontally */
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  /* necessary adjustments for centering */
  z-index: 1010;
  /* high value to ensure modal is on top */
  width: 500px;
  background-color: var(--whiteColorDeam);
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: .5rem;
  padding: 1.5rem 1rem;
  color: var(--textColor);
}

.jeeta-app .topicModal .topicCreate,
.jeeta-app * .topicModal .topicCreate,
.customModal .topicModal .topicCreate,
.customModal * .topicModal .topicCreate {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
}

.jeeta-app .topicModal .topicCreate .topicTitle,
.jeeta-app * .topicModal .topicCreate .topicTitle,
.customModal .topicModal .topicCreate .topicTitle,
.customModal * .topicModal .topicCreate .topicTitle {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 1rem 0;
  font-size: 25px;
}

.jeeta-app .topicModal .topicCreate .topicInfoInput,
.jeeta-app * .topicModal .topicCreate .topicInfoInput,
.customModal .topicModal .topicCreate .topicInfoInput,
.customModal * .topicModal .topicCreate .topicInfoInput {
  width: 80%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 1rem;
}

.jeeta-app .topicModal .topicCreate .topicInfoInput .textInput,
.jeeta-app * .topicModal .topicCreate .topicInfoInput .textInput,
.customModal .topicModal .topicCreate .topicInfoInput .textInput,
.customModal * .topicModal .topicCreate .topicInfoInput .textInput {
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: .5rem;
}

.jeeta-app .topicModal .topicCreate .topicInfoInput .textInput .title,
.jeeta-app * .topicModal .topicCreate .topicInfoInput .textInput .title,
.customModal .topicModal .topicCreate .topicInfoInput .textInput .title,
.customModal * .topicModal .topicCreate .topicInfoInput .textInput .title {
  width: 90px;
  font-size: 16px;
  font-weight: 600;
}

.jeeta-app .topicModal .topicCreate .topicInfoInput .textInput input,
.jeeta-app * .topicModal .topicCreate .topicInfoInput .textInput input,
.customModal .topicModal .topicCreate .topicInfoInput .textInput input,
.customModal * .topicModal .topicCreate .topicInfoInput .textInput input {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  border: #cccccc solid 1px;
  border-radius: .3rem;
  padding: .3rem .7rem;
  font-size: 15px;
}

.jeeta-app .topicModal .topicCreate .topicInfoInput .topicSelectionDropdown,
.jeeta-app * .topicModal .topicCreate .topicInfoInput .topicSelectionDropdown,
.customModal .topicModal .topicCreate .topicInfoInput .topicSelectionDropdown,
.customModal * .topicModal .topicCreate .topicInfoInput .topicSelectionDropdown {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: .5rem;
}

.jeeta-app .topicModal .topicCreate .topicInfoInput .topicSelectionDropdown .title,
.jeeta-app * .topicModal .topicCreate .topicInfoInput .topicSelectionDropdown .title,
.customModal .topicModal .topicCreate .topicInfoInput .topicSelectionDropdown .title,
.customModal * .topicModal .topicCreate .topicInfoInput .topicSelectionDropdown .title {
  width: 90px;
  font-size: 16px;
  font-weight: 600;
}

.jeeta-app .topicModal .topicCreate .topicInfoInput .topicSelectionDropdown .selectionDropdown,
.jeeta-app * .topicModal .topicCreate .topicInfoInput .topicSelectionDropdown .selectionDropdown,
.customModal .topicModal .topicCreate .topicInfoInput .topicSelectionDropdown .selectionDropdown,
.customModal * .topicModal .topicCreate .topicInfoInput .topicSelectionDropdown .selectionDropdown {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  font-size: 15px;
}

.jeeta-app .topicModal .topicCreate .topicInfoInput .topicSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .topicModal .topicCreate .topicInfoInput .topicSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal .topicModal .topicCreate .topicInfoInput .topicSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal * .topicModal .topicCreate .topicInfoInput .topicSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric {
  max-width: 180px;
  overflow: hidden;
}

.jeeta-app .topicModal .topicCreate .btn,
.jeeta-app * .topicModal .topicCreate .btn,
.customModal .topicModal .topicCreate .btn,
.customModal * .topicModal .topicCreate .btn {
  margin-top: 1rem;
  color: var(--whiteColor);
  font-weight: 600;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: .5rem;
}

.jeeta-app .topicModal .topicCreate .btn .icon,
.jeeta-app * .topicModal .topicCreate .btn .icon,
.customModal .topicModal .topicCreate .btn .icon,
.customModal * .topicModal .topicCreate .btn .icon {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  font-size: 18px;
}

.jeeta-app .topicModal .topicCreate #close,
.jeeta-app * .topicModal .topicCreate #close,
.customModal .topicModal .topicCreate #close,
.customModal * .topicModal .topicCreate #close {
  position: absolute;
  top: 5px;
  right: 5px;
  color: var(--PrimaryColor);
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  font-size: 30px;
}

.jeeta-app .topicModal .topicCreate #close:hover,
.jeeta-app * .topicModal .topicCreate #close:hover,
.customModal .topicModal .topicCreate #close:hover,
.customModal * .topicModal .topicCreate #close:hover {
  color: var(--tiger-lilly);
}

.jeeta-app .syllabusSlotList,
.jeeta-app * .syllabusSlotList,
.customModal .syllabusSlotList,
.customModal * .syllabusSlotList {
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
  padding: 1rem 0 0 0;
}

.jeeta-app .syllabusReadingMaterialList,
.jeeta-app * .syllabusReadingMaterialList,
.customModal .syllabusReadingMaterialList,
.customModal * .syllabusReadingMaterialList {
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
  padding: 1rem 0 0 0;
}

.jeeta-app .syllabusRecordedVideoList,
.jeeta-app * .syllabusRecordedVideoList,
.customModal .syllabusRecordedVideoList,
.customModal * .syllabusRecordedVideoList {
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
  padding: 1rem 0 0 0;
}

@media all and (max-width: 800px) {
  .jeeta-app .syllabus,
  .jeeta-app * .syllabus,
  .customModal .syllabus,
  .customModal * .syllabus {
    padding: .5rem;
    gap: .5rem;
  }
  .jeeta-app .syllabus-header,
  .jeeta-app * .syllabus-header,
  .customModal .syllabus-header,
  .customModal * .syllabus-header {
    margin: .5rem 0;
    padding: .5rem 0;
  }
  .jeeta-app .syllabus-glass,
  .jeeta-app * .syllabus-glass,
  .customModal .syllabus-glass,
  .customModal * .syllabus-glass {
    -ms-grid-columns: 3rem auto;
        grid-template-columns: 3rem auto;
  }
}

@media all and (max-width: 550px) {
  .jeeta-app .syllabus-header,
  .jeeta-app * .syllabus-header,
  .customModal .syllabus-header,
  .customModal * .syllabus-header {
    margin: 0;
    padding: .5rem;
    font-size: .9rem;
  }
  .jeeta-app .syllabus-glass,
  .jeeta-app * .syllabus-glass,
  .customModal .syllabus-glass,
  .customModal * .syllabus-glass {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 0;
    padding: 0;
  }
  .jeeta-app .syllabus-main,
  .jeeta-app * .syllabus-main,
  .customModal .syllabus-main,
  .customModal * .syllabus-main {
    padding: 0;
  }
  .jeeta-app .syllabus-filter .subject-grade-filter,
  .jeeta-app * .syllabus-filter .subject-grade-filter,
  .customModal .syllabus-filter .subject-grade-filter,
  .customModal * .syllabus-filter .subject-grade-filter {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .jeeta-app .filter,
  .jeeta-app * .filter,
  .customModal .filter,
  .customModal * .filter {
    padding: 0;
  }
  .jeeta-app .filter .filter-label,
  .jeeta-app * .filter .filter-label,
  .customModal .filter .filter-label,
  .customModal * .filter .filter-label {
    font-size: .9rem;
  }
  .jeeta-app .filter .filterDropdown,
  .jeeta-app * .filter .filterDropdown,
  .customModal .filter .filterDropdown,
  .customModal * .filter .filterDropdown {
    padding: .2rem .5rem;
  }
  .jeeta-app .filter .filterDropdown .title,
  .jeeta-app * .filter .filterDropdown .title,
  .customModal .filter .filterDropdown .title,
  .customModal * .filter .filterDropdown .title {
    font-size: 0.9rem;
  }
  .jeeta-app .filter .filterDropdown .selectionDropdown,
  .jeeta-app .filter .filterDropdown .selectionDropdownMultiple,
  .jeeta-app * .filter .filterDropdown .selectionDropdown,
  .jeeta-app * .filter .filterDropdown .selectionDropdownMultiple,
  .customModal .filter .filterDropdown .selectionDropdown,
  .customModal .filter .filterDropdown .selectionDropdownMultiple,
  .customModal * .filter .filterDropdown .selectionDropdown,
  .customModal * .filter .filterDropdown .selectionDropdownMultiple {
    font-size: 0.8rem;
  }
  .jeeta-app .filter .filterDropdown input,
  .jeeta-app * .filter .filterDropdown input,
  .customModal .filter .filterDropdown input,
  .customModal * .filter .filterDropdown input {
    font-size: 0.8rem;
  }
  .jeeta-app .syllabusSlotList,
  .jeeta-app * .syllabusSlotList,
  .customModal .syllabusSlotList,
  .customModal * .syllabusSlotList {
    padding: .5rem;
  }
}
