// fonts
.jeeta-app,
.jeeta-app *,
.customModal,
.customModal * {
    .roboto-regular {
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        font-style: normal;
    }

    .roboto-medium {
        font-family: "Roboto", sans-serif;
        font-weight: 500;
        font-style: normal;
    }

    .roboto-bold {
        font-family: "Roboto", sans-serif;
        font-weight: 700;
        font-style: normal;
    }

    .NTA {
        width: 100%;

        .title {
            justify-content: flex-start;
            width: 100%;
        }
    }

    .NTAModal {
        width: 90vw;
        height: 90vh;
        overflow-y: auto;
        background-color: var(--whiteColor);
        // padding: 1.5rem 1rem;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    }

    .NTAInstructions {
        width: 100%;
        height: 100%;
        flex-direction: column;
        gap: 1rem;

        .title {
            padding: 1.5rem;
            background-color: var(--whiteColorDeam);
            color: var(--midnight-blue);
        }

        .main {
            flex-direction: column;
            gap: 1rem;
            padding: 1rem;

            .caution {
                text-align: center;
            }
        }

        .language {
            flex-direction: column;
            gap: 1rem;
            padding: 1rem 2rem;
            color: var(--hard);
        }

        .agree-input {
            padding: 1rem 2rem;

            #agreeCheckbox {
                vertical-align: middle;
                display: inline;
            }
        }

        .olInstruction {
            padding: 1rem 0 .5rem 0;
            list-style: none;
            /* Removes default numbering */
            counter-reset: item;
            /* Creates a counter named 'item' */
            padding-left: 2rem;
            /* Optional: Adjusts padding */

            .list-title {
                text-decoration: underline;
                padding: .5rem 0;
            }

            .olItem {
                counter-increment: item;
                /* Increments the counter */
                margin-bottom: .5rem;
                /* Optional: Adds space between items */

                &:before {
                    content: counter(item) ".";
                    /* Displays the counter */
                    // text-decoration: underline; /* Underlines the number */
                    padding-right: .5rem;
                    /* Adds space between the number and text */
                }

                img {
                    display: inline !important;
                    vertical-align: middle;
                    // height: 1em; /* Makes the image height relative to the font size of the element */
                    // width: auto; /* Maintains the aspect ratio of the image */
                }
            }
        }

        .olInstruction-child {
            padding: 1rem 0 .5rem 0;
            list-style: none;
            /* Removes default numbering */
            counter-reset: item;
            /* Creates a counter named 'item' */
            padding-left: 2rem;
            /* Optional: Adjusts padding */
        }

        .ulInstruction {
            list-style-type: circle;
            /* Uses hollow circles for list item markers */
            padding-left: 1rem;
            /* Adjusts padding for better alignment */

            .ulItem {
                margin-bottom: .5rem;
                /* Optional: Adds space between items for better readability */
            }
        }
    }

    .NTATestPaper {
        flex-direction: column;
        padding: 1rem;
        align-items: center;
        gap: 1rem;

        .test-name {
            width: 100%;
            padding: .5rem;
            background-color: var(--whiteColorDeam);
        }
    }

    .NTATestPaperSection {
        flex-direction: column;
        width: 100%;
        gap: .5rem;

        .sectionTitle {
            padding-left: 1rem;
        }
    }

    .NTATestPaperQuestion,
    .NTATestPaperParagraph {
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        padding: .5rem 1rem 1rem 1rem;
        font-size: 1rem;
        width: 100%;
        /* Ensures it takes up 100% of the parent's width */
        box-sizing: border-box;
        /* Includes padding and border in the element's width */

        .question-text {
            flex-shrink: 0;

            span {
                font-family: 'Frank Ruhl Libre', serif;
            }
        }

        .question-image,
        .paragraph-images {
            flex-shrink: 0;

            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: auto;
            gap: .5rem;

            .img {
                max-width: 100%;
                max-height: 50vh;
                height: auto;
                object-fit: contain;
            }
        }

        .question-image-only {
            flex-shrink: 0;
            display: block;
            // display: flex;
            // justify-content: center;
            // align-items: center;
            max-width: 90%;
            // max-height: 50%;
            object-fit: contain;

            .wide {
                max-width: 100%;
                // max-height: 50vh;
                height: auto;
            }

            .long {
                max-width: 80%;
                // max-height: 50vh;
                height: auto;
            }
        }

        .solution {
            flex-shrink: 0;
        }

        .NTAanswers {
            flex-shrink: 0;

            width: 100%;
            flex-direction: column;
            justify-content: flex-start;
            gap: 1rem;
            padding: 0 1rem;

            .answer-container {
                width: 100%;
                display: flex;
                align-items: center;

                .option {
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    gap: .5rem;
                    font-family: 'Frank Ruhl Libre', serif;

                    .answer-check {
                        margin-top: 1px;
                        transform: scale(1.2);
                    }

                    .answer-label {
                        font-weight: 700;
                        font-family: 'Frank Ruhl Libre', serif;
                    }

                    span {
                        font-family: 'Frank Ruhl Libre', serif;
                    }
                }

                .subjective {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: .5rem;

                    span,
                    input {
                        width: 100%;
                        font-family: 'Frank Ruhl Libre', serif;
                    }

                    .subjective-input {
                        padding: .2rem .5rem;
                    }

                    .subjective-given-answer {
                        font-weight: 600;
                    }
                }
            }



            &.correct {
                border-color: green;
            }
        }

        .NTAAnswerResponse {
            padding: 1rem 0;
            width: 100%;
            justify-content: space-between;
            align-items: center;

            .NTAbtns {
                align-items: center;
                gap: .5rem;

                .NTAbtn {
                    padding: .2rem .5rem;
                    border: 1px solid #ddd;


                    &:hover {
                        cursor: pointer;
                        color: var(--whiteColor);
                        background-color: var(--PrimaryColor);
                    }
                }
            }
        }
    }



    // .question-text {
    //     flex-shrink: 0;

    //     span {
    //         font-family: 'Frank Ruhl Libre', serif;
    //     }
    // }

    // .question-image,
    // .paragraph-images {
    //     flex-shrink: 0;

    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     width: 100%;
    //     height: auto;
    //     gap: .5rem;

    //     .img {
    //         max-width: 100%;
    //         max-height: 50vh;
    //         height: auto;
    //         object-fit: contain;
    //     }
    // }

    // .question-image-only {
    //     flex-shrink: 0;

    //     display: block;
    //     // display: flex;
    //     // justify-content: center;
    //     // align-items: center;
    //     max-width: 50%;
    //     // max-height: 50%;
    //     object-fit: contain;

    //     .wide {
    //         max-width: 100%;
    //         // max-height: 50vh;
    //         height: auto;
    //     }

    //     .long {
    //         max-width: 80%;
    //         // max-height: 50vh;
    //         height: auto;
    //     }
    // }


    .instruction_area {

        span.not_visited {
            background: url(../../../Assets/questions-sprite.png) no-repeat scroll 0 0 transparent;
            background-position: -105px -49px;
            color: var(--whiteColor);
            cursor: pointer;
            float: left;
            font-weight: bold;
            width: 34px;
            height: 38px;
            line-height: 42px;
            margin: 3px;
            text-align: center;
        }

        span.not_answered {
            background: url(../../../Assets/questions-sprite.png) no-repeat scroll 0 0 transparent;
            background-position: -39px -48px;
            color: var(--whiteColor);
            cursor: pointer;
            float: left;
            font-weight: bold;
            width: 34px;
            height: 38px;
            line-height: 42px;
            margin: 3px;
            text-align: center;
        }

        span.answered {
            background: url(../../../Assets/questions-sprite.png) no-repeat scroll 0 0 transparent;
            background-position: -5px -48px;
            color: var(--whiteColor);
            cursor: pointer;
            float: left;
            font-weight: bold;
            width: 34px;
            height: 38px;
            line-height: 42px;
            margin: 3px;
            text-align: center;
        }

        span.review {
            background: url(../../../Assets/questions-sprite.png) no-repeat scroll 0 0 transparent;
            background-position: -72px -48px;
            color: var(--whiteColor);
            cursor: pointer;
            float: left;
            font-weight: bold;
            width: 34px;
            height: 38px;
            line-height: 42px;
            margin: 3px;
            text-align: center;
        }

        span.review_marked_considered {
            background: url(../../../Assets/questions-sprite.png) no-repeat scroll 0 0 transparent;
            background-position: -6px -81px;
            color: var(--whiteColor);
            cursor: pointer;
            float: left;
            font-weight: bold;
            width: 34px;
            height: 38px;
            line-height: 42px;
            margin: 3px;
            text-align: center;
        }
    }

    .NTA-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        gap: .5rem;
        font-weight: 700;
        border-color: var(--whiteColor);
        border: none;
        font-size: 1.2rem;

        .icon {
            font-size: 1.5rem;
        }

        &:hover {
            cursor: pointer;
            color: var(--whiteColor);
            background-color: var(--PrimaryColor);
        }
    }

    .NTA-btn.not-agreed {
        &:hover {
            cursor: not-allowed;
        }

        opacity: 0.5;
        /* Makes the button look faded */
        color: #aaa;
        /* Changes the text color to light grey */
    }

    #NTA-popup {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        padding: 2rem;
        background-color: var(--whiteColorDeam);
        z-index: 1001;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        font-size: 1rem;

        .NTA-popup-btns {
            gap: 1rem;
        }

        .btn {}

        .NTA-popup-close {
            width: 100%;
            justify-content: space-between;
            align-items: center;
            background-color: var(--PrimaryColor);
            border: none;
            padding: .5rem 1rem;
            color: var(--whiteColor);

            div {
                display: flex;
                align-items: center;
                gap: .5rem;

                .icon {
                    font-size: 1.2rem;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }

        .NTA-popup-msg {
            padding: 1rem;
            white-space: nowrap;
            text-align: center;
            line-height: 2;
        }

        .NTA-popup-OK {
            width: auto;
            background-color: #248653;
            border: none;
            padding: .5rem 1rem;
            color: var(--whiteColor);

            &:hover {
                cursor: pointer;
            }
        }
    }

    .instruction_icon {
        background: url(../../../Assets/Icon-sprite.png) no-repeat -140px -7px;
        width: 18px;
        height: 18px;
    }

    .NTATestHeader {
        width: 100%;
        padding: .5rem;
        background-color: var(--blackColor);
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        flex-shrink: 0;

        .test-name {
            gap: .2rem;
            align-items: center;
            color: var(--amber);

            span {
                &:hover {
                    cursor: pointer;
                }
            }
        }

        .test-refresh,
        .test-status,
        .test-report-btn {
            position: relative;
            gap: .5rem;
            color: var(--whiteColor);

            .msg {
                border-radius: .5rem;
                padding: .1rem .5rem;
            }

            #NTAreport {
                display: flex;
                gap: .2rem;
                align-items: center;
                color: var(--whiteColor);
                background-color: var(--SecondaryColor);
                font-size: .9rem;

                .icon {
                    font-size: 1.2rem;
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .test-header-popup {
            gap: 1rem;

            .test-instruction {
                align-items: center;
                color: var(--whiteColor);
                gap: .2rem;

                &:hover {
                    cursor: pointer;
                }
            }
        }

    }

    .NTATestSectionsContainerAndProfile {
        flex-shrink: 0;
        width: 100%;
        // height: 100%;
        justify-content: space-between;
    }

    .NTASectionTabsandInfo {
        flex-grow: 1;
        flex-direction: column;
        gap: .5rem;
        overflow-x: auto;
    }

    .NTASectionTabsHeaderandContainer {
        width: 100%;
        // height: 100%;
        flex-direction: column;
        justify-content: flex-end;
        // padding: .5rem 0;
        gap: .5rem;
    }

    .NTASectionTabsHeader {
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding-top: .1rem;
        padding-left: .5rem;
    }

    .NTATimer {
        width: 10rem;
        justify-content: flex-start;
        align-items: center;
        gap: .5rem;
    }

    .NTATimer-label.half {
        color: var(--medium);
    }

    .NTATimer-label.little {
        color: var(--hard);
    }

    .NTASectionTabsContainer {
        position: relative;
        justify-content: center;
        align-items: center;
        width: 100%;
        // height: 100%;
        border: 1px solid #ddd;

        .not-started-msg {
            font-size: 1.5rem;
            color: red;
            padding: .95rem;
        }

        .left-btn,
        .right-btn {
            display: flex;
            justify-content: center;
            background-color: transparent;
            border: none;
            padding: .2rem 0;

            .icon {
                font-size: 1rem;
            }

            &:hover {
                cursor: pointer;
            }
        }

        .left-btn {
            float: left;
        }

        .right-btn {
            float: right;
        }

        .NTASectionTabs {
            flex-grow: 1;
            overflow-x: auto;

            &::-webkit-scrollbar {
                display: none;
                /* Hide scrollbar for Webkit browsers */
            }

            .tabItem {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: .2rem;
                background-color: transparent;
                border: 1px solid #ddd;
                padding: .2rem .5rem;
                white-space: nowrap;

                &:hover {
                    cursor: pointer;
                }
            }

            .tabItem.selected {
                background-color: var(--PrimaryColor);
                color: var(--whiteColor);
            }
        }
    }

    .NTAProfile {
        width: 20rem;
        flex-shrink: 0;
        gap: .5rem;
        border: 1px solid #ddd;

        .profile_image {
            width: 7.5rem;
            // height: auto;
            object-fit: contain;
        }

        .profile_userName {}
    }

    .NTAAnswersCountsTooltipSection,
    .NTAAnswersCountsTooltipOverall {
        position: absolute;
        width: max-content;
        font-size: small;
        left: 0;
        background-color: var(--whiteColorDeam);
        box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
        border: none;
        border-radius: .1rem;
        color: var(--textColor);
        flex-direction: column;
        align-items: flex-start;
        gap: .2rem;
        padding: .5rem;
        // white-space: nowrap;
        z-index: 10;
    }

    .NTAAnswersCountsTooltipSection {
        margin-top: 1.65rem;
    }

    .NTAAnswersCountsTooltipOverall {
        top: 2.55rem;
    }

    .NTASectionQuestionType {
        flex-grow: 1;
        align-items: center;
        gap: .2rem;
        text-transform: capitalize;
    }

    .NTASectionBriefMarkingScheme {
        align-items: center;
        gap: .5rem;

        .NTA-marks-correct {
            color: blue;
        }

        .NTA-marks-partial {
            color: green;
        }

        .NTA-marks-wrong {
            color: red;
        }
    }

    .NTATestLanguage {
        width: 100%;
        padding: .5rem;
        background-color: var(--PrimaryColor);
    }

    .NTATestView {
        width: 100%;
        // height: 85%;
        box-sizing: border-box;
        /* Includes padding and border in the element's width */
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        overflow-y: auto;

        .NTATestViewLeft {
            flex-grow: 1;
            height: 100%;
            box-sizing: border-box;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            overflow-y: auto;
            // gap: .5rem;
            // overflow-y: scroll !important;

            .expand_icon {
                background: url(../../../Assets/Icon-sprite.png) no-repeat -30px -63px;
                width: 17px;
                height: 48px;
                cursor: pointer;
                display: inline;
                right: 0;
                position: absolute;
                top: 53%;
                z-index: 100;
            }

            #hideExpandIcon {
                display: none;
            }
        }

        .NTATestViewRight {
            position: relative;
            width: 20rem;
            height: 100%;
            flex-shrink: 0;
            border: 2px solid var(--blackColor);
            box-sizing: border-box;
            /* Includes padding and border in the element's width */

            .collapse_icon {
                background: url(../../../Assets/Icon-sprite.png) no-repeat -5px -63px;
                width: 17px;
                height: 48px;
                cursor: pointer;
                display: inline-block;
                left: -5.5%;
                position: absolute;
                top: 43%;
                z-index: 100;
            }
        }

        #hideQuestionBox {
            display: none;
        }
    }



    .NTAQuestionNumber {
        width: 100%;
        max-height: 5%;
        padding: 1rem;
        flex-shrink: 0;
        // text-align: left;
    }

    .NTAMarkingScheme {
        flex-shrink: 0;
        // overflow-y: auto;
        // max-height: 40%;
        flex-direction: column;
        padding: .5rem;
        margin: .5rem 1rem 0 1rem;
        border: 2px solid var(--blackColor);
        gap: .5rem;
        font-size: .9rem;

        .instructions {
            padding-left: 1rem;

            li {
                font-family: 'Frank Ruhl Libre', serif;
                list-style-type: disc;
            }
        }

        .markingSchemeTitle {
            text-align: center;
        }

        .testMarksList {
            margin-left: 1rem;
            line-height: 1.5;

            .numbersInput {
                justify-content: flex-start;
                align-items: flex-start;
                flex-wrap: nowrap;
                gap: .3rem;
                font-size: .85rem;

                label {
                    font-family: 'Frank Ruhl Libre', serif;
                    // width: 7rem;
                    font-weight: 600;
                    white-space: nowrap;
                    padding-top: .7px;

                    &:after {
                        content: ':';
                        // margin-right: 0.5rem;
                    }
                }

                span,
                strong {
                    font-family: 'Frank Ruhl Libre', serif;
                }
            }
        }
    }

    .NTAQuestionBox {
        width: 100%;
        flex-shrink: 0;
        gap: 0.5rem;
        border: 1px solid #ddd;
        flex-direction: column;
        // height: 100%;
        box-sizing: border-box;
        /* Includes padding and border in the element's width */
    }

    .NTANotationTypeDescription {
        width: 100%;
        padding: .5rem;
        grid-template-columns: repeat(2, 1fr);
        gap: .5rem;

        .NTANotationType {
            width: 100%;
            justify-content: flex-start;
            align-items: center;
            font-size: .8rem;

            .label {
                white-space: auto;
            }
        }

        .NTANotationType.review_answered {
            grid-column: span 2;
        }
    }

    .NTANotationType {
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        font-size: .8rem;

        .label {
            white-space: auto;
        }
    }

    .NTAAnswerNotation {
        background: url(../../../Assets/questions-sprite.png) no-repeat;
        color: #262626;
        line-height: 15px;
        text-align: center;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 1em;
        flex-shrink: 0;
    }

    .NTAAnswerNotation.answered,
    .NTAAnswerNotation.correct {
        background-position: -7px -55px;
        float: left;
        height: 26px;
        line-height: 20px;
        margin-right: 10px;
        width: 29px;
        color: #fff;
        padding-top: 3px;
    }

    .NTAAnswerNotation.not_answered,
    .NTAAnswerNotation.incorrect {
        background-position: -42px -56px;
        float: left;
        height: 26px;
        line-height: 22px;
        margin-right: 10px;
        width: 29px;
        color: #fff;
    }

    .NTAAnswerNotation.not_visited,
    .NTAAnswerNotation.no_response {
        background-position: -107px -56px;
        float: left;
        height: 26px;
        line-height: 21px;
        margin-right: 10px;
        width: 29px;
        color: #000;
        padding-top: 2px;
    }

    .NTAAnswerNotation.review {
        background-position: -75px -54px;
        float: left;
        height: 28px;
        line-height: 18px;
        margin-right: 10px;
        width: 28px;
        color: #fff;
        padding-top: 5px;
    }

    .NTAAnswerNotation.review_answered {
        background-position: -9px -87px;
        float: left;
        height: 29px;
        line-height: 15px;
        margin-right: 10px;
        width: 28px;
        color: #fff;
        padding-top: 5px;
    }

    .NTAChooseAQuestion {
        position: relative;
        width: 100%;
        flex-grow: 1;
        /* Ensures it takes up 100% of the parent's width */
        flex-direction: column;
        background-color: var(--alice-blue);

        .NTATestSectionTitle {
            padding: .5rem 1rem;
            background-color: var(--PrimaryColor);
            color: var(--whiteColor);
        }

        .NTAQuestionBtns {
            grid-template-columns: repeat(auto-fill, 50px);
            /* Adjust the minmax values based on your needs */
            gap: .5rem;
            /* Space between grid items */
            padding: 1rem;
            align-items: start;
            /* Ensures all items are aligned to the top of their cell */
            overflow-y: auto !important;
        }

        .submit-btn {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            justify-content: center;
            align-items: center;
            gap: .5rem;
            margin: 1rem;
            padding: .5rem 2rem;
            background-color: var(--PrimaryBkg);
            color: var(--whiteColor);
            border: 1px solid #ddd;
            border-radius: .3rem;

            &:hover {
                cursor: pointer;
                background-color: var(--PrimaryColor);
            }
        }
    }

    .NTATestSubmitted {
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        justify-content: center;

        span {
            color: var(--PrimaryColor);
        }
    }

    .NTAQuestionSlide,
    .NTATestPaperQuestion {
        flex-grow: 1;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        padding: .5rem 1rem 1rem 1rem;
        font-size: 1rem;
        width: 100%;
        /* Ensures it takes up 100% of the parent's width */
        box-sizing: border-box;
        /* Includes padding and border in the element's width */



        .question-info {
            position: relative;
            width: 100%;
            flex-direction: row;
            justify-content: flex-start;
            padding: .5rem;
            gap: .5rem;

            .question-id {
                background-color: var(--PrimaryColor);
                align-self: flex-start;
                padding: .2rem .4rem;
                border-radius: .5rem;
                color: white;
                font-size: .94rem;
                font-weight: 600;
            }

            .question-saved {
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: .2rem;
                gap: .2rem;

                .saved-btn {
                    border: none;
                    background-color: transparent;
                    display: flex;
                    justify-content: center;

                    .icon {
                        font-size: 1.6rem;
                    }
                }

                #star-btn {
                    color: var(--tiger-lilly);

                }

                #review-btn {
                    padding-top: .2rem;
                    color: var(--kelly-green);
                }
            }
        }

        #Physics {
            background-color: var(--Physics);
        }

        #Chemistry {
            background-color: var(--Chemistry);
        }

        #Mathematics {
            background-color: var(--Mathematics);
        }

        .question-text {
            flex-shrink: 0;

            span {
                font-family: 'Frank Ruhl Libre', serif;
            }
        }

        .question-image,
        .paragraph-images {
            flex-shrink: 0;

            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: auto;
            gap: .5rem;

            .img {
                max-width: 100%;
                max-height: 50vh;
                height: auto;
                object-fit: contain;
            }
        }

        .question-image-only {
            flex-shrink: 0;
            display: block;
            // display: flex;
            // justify-content: center;
            // align-items: center;
            max-width: 90%;
            // max-height: 50%;
            object-fit: contain;

            .wide {
                max-width: 100%;
                // max-height: 50vh;
                height: auto;
            }

            .long {
                max-width: 80%;
                // max-height: 50vh;
                height: auto;
            }
        }

        .solution {
            flex-shrink: 0;
        }

        .NTAanswers {
            flex-shrink: 0;

            width: 100%;
            flex-direction: column;
            justify-content: flex-start;
            gap: 1rem;
            padding: 0 1rem;

            .answer-container {
                width: 100%;
                display: flex;
                align-items: center;

                .option {
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    gap: .5rem;
                    font-family: 'Frank Ruhl Libre', serif;

                    .answer-check {
                        margin-top: 1px;
                        transform: scale(1.2);
                    }

                    .answer-label {
                        font-weight: 700;
                        font-family: 'Frank Ruhl Libre', serif;
                    }

                    span {
                        font-family: 'Frank Ruhl Libre', serif;
                    }
                }

                .subjective {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: .5rem;

                    span,
                    input {
                        width: 100%;
                        font-family: 'Frank Ruhl Libre', serif;
                    }

                    .subjective-input {
                        padding: .2rem .5rem;
                    }

                    .subjective-given-answer {
                        font-weight: 600;
                    }
                }
            }



            &.correct {
                border-color: green;
            }
        }

        .NTAAnswerResponse {
            padding: 1rem 0;
            width: 100%;
            justify-content: space-between;
            align-items: center;

            .NTAbtns {
                align-items: center;
                gap: .5rem;

                .NTAbtn {
                    padding: .2rem .5rem;
                    border: 1px solid #ddd;


                    &:hover {
                        cursor: pointer;
                        color: var(--whiteColor);
                        background-color: var(--PrimaryColor);
                    }
                }
            }
        }
    }

    .NTAQuestionBtn {
        background: url(../../../Assets/questions-sprite.png) no-repeat;
        color: #fff;
        float: left;
        font-size: 1.417em;
        font-weight: normal;
        height: 43px;
        line-height: 42px;
        margin-bottom: 10px;
        margin-right: 2px;
        text-align: center;
        width: 50px;

        &:hover {
            cursor: pointer;
        }
    }

    .NTAQuestionBtn.not_answered,
    .NTAQuestionBtn.incorrect {
        background-position: -57px -6px;
        width: 49px;
        height: 43px;
        margin-top: 5px;
    }

    .NTAQuestionBtn.answered,
    .NTAQuestionBtn.correct {
        background-position: -4px -5px;
        margin-top: 5px;
    }

    .NTAQuestionBtn.not_visited,
    .NTAQuestionBtn.no_response {
        background-position: -157px -4px;
        color: #474747;
        height: 43px;
        line-height: 43px;
        margin-top: 5px;
    }

    .NTAQuestionBtn.review:hover {
        background-position: -108px -122px;
    }

    .NTAQuestionBtn.review {
        background-position: -108px -1px;
        height: 50px;
        line-height: 50px;
        margin-bottom: 3px;
    }

    .NTAQuestionBtn.not_answered:hover {
        background-position: -57px -127px;
    }

    .NTAQuestionBtn.review_answered {
        background-position: -66px -178px;
        height: 53px;
        line-height: 51px;
        margin-bottom: 0;
        /* margin-right: 5px; */
        width: 49px;
    }

    #scrollToBottom {
        cursor: pointer;
        margin-top: 5px;
        height: 40px;
        width: 40px;
        color: #fff;
        font-size: 16pt;
        text-align: center;
        text-decoration: none;
        line-height: 40px;
        display: none;
        animation: 2s linear 0s normal none 1 running fadeinout;
        -webkit-animation: 2s linear 0s normal none 1 running fadeinout;
    }

    #scrollToTop {
        cursor: pointer;
        margin-top: 5px;
        height: 40px;
        width: 40px;
        color: #fff;
        font-size: 16pt;
        text-align: center;
        text-decoration: none;
        line-height: 40px;
        display: none;
    }

    //MEDIA QUERIES ==================================================>
    @media all and (max-width: 1000px) {}

    @media all and (max-width: 860px) {}

    @media all and (max-width: 500px) {}
}