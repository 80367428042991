.jeeta-app .analytics-chart-container,
.jeeta-app * .analytics-chart-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 1rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 1rem;
}

.jeeta-app .analytics-chart-section,
.jeeta-app * .analytics-chart-section {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  justify-items: center;
  gap: .5rem;
}

.jeeta-app .analytics-chart-section .analytics-chart-section-title,
.jeeta-app * .analytics-chart-section .analytics-chart-section-title {
  width: 100%;
  color: var(--textColor);
  text-align: center;
}
