.customTable {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: .5rem;
}

.customTable .title {
  padding: .5rem;
  color: var(--textColor);
}

.MuiTableRow-root > * {
  padding: .6rem;
}

.MuiTableRow-root.MuiTableRow-head > * {
  font-weight: bold !important;
}

.customTable td,
th {
  border: none !important;
}

.customTable :first-child {
  border-radius: 0.7rem !important;
}
