.jeeta-app,
.jeeta-app * {
  /* logo */
  /* menu */
}

.jeeta-app .analytics-sidebar,
.jeeta-app * .analytics-sidebar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  padding-top: 1rem;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.jeeta-app .analytics-logo,
.jeeta-app * .analytics-logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-weight: bold;
  font-size: 1.5rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.jeeta-app .analytics-logo span,
.jeeta-app * .analytics-logo span {
  color: var(--PrimaryColor);
}

.jeeta-app .analytics-logo span span,
.jeeta-app * .analytics-logo span span {
  color: var(--SecondaryColor);
}

.jeeta-app .analytics-logo img,
.jeeta-app * .analytics-logo img {
  width: 70%;
  height: auto;
}

.jeeta-app .analytics-menu,
.jeeta-app * .analytics-menu {
  margin-top: 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 2rem;
  color: var(--textColor);
}

.jeeta-app .analytics-menu .menuItem,
.jeeta-app * .analytics-menu .menuItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: .7rem;
  height: 2.5rem;
  margin-left: 1rem;
  position: relative;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  border-radius: 0.7rem;
  font-size: 1rem;
}

.jeeta-app .analytics-menu .menuItem:hover,
.jeeta-app * .analytics-menu .menuItem:hover {
  cursor: pointer;
  font-weight: 600;
  color: var(--SecondaryColor);
}

.jeeta-app .analytics-menu .menuItem .icon,
.jeeta-app * .analytics-menu .menuItem .icon {
  font-size: 1.5rem;
}

.jeeta-app .analytics-menu .active,
.jeeta-app * .analytics-menu .active {
  background: var(--SecondaryBkg);
  margin-left: 0;
}

.jeeta-app .analytics-menu .active::before,
.jeeta-app * .analytics-menu .active::before {
  content: "";
  width: 8px;
  height: 100%;
  background: var(--SecondaryColor);
}

@media all and (max-width: 860px) {
  .jeeta-app .analytics-sidebar,
  .jeeta-app * .analytics-sidebar {
    max-width: 3rem;
    padding-top: 5rem;
  }
  .jeeta-app .analytics-logo span,
  .jeeta-app * .analytics-logo span {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .jeeta-app .analytics-logo img,
  .jeeta-app * .analytics-logo img {
    display: none;
  }
  .jeeta-app .analytics-menu,
  .jeeta-app * .analytics-menu {
    margin-top: 5rem;
    gap: 0;
  }
  .jeeta-app .analytics-menu .menuItem span,
  .jeeta-app * .analytics-menu .menuItem span {
    display: none;
  }
}

@media all and (max-width: 550px) {
  .jeeta-app .analytics-sidebar,
  .jeeta-app * .analytics-sidebar {
    width: 100%;
    max-width: none;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    padding: .5rem 0 .5rem 1.5rem;
    gap: 1rem;
  }
  .jeeta-app .analytics-sidebar::before,
  .jeeta-app * .analytics-sidebar::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    /* Full width of the container */
    height: 2px;
    /* Thickness of the bottom line */
    background: var(--gradientBkg);
  }
  .jeeta-app .analytics-logo span,
  .jeeta-app * .analytics-logo span {
    -webkit-transform: none;
            transform: none;
  }
  .jeeta-app .analytics-logo img,
  .jeeta-app * .analytics-logo img {
    display: none;
  }
  .jeeta-app .analytics-menu,
  .jeeta-app * .analytics-menu {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    margin-top: 0;
    gap: .5rem;
    color: var(--PrimaryColor);
  }
  .jeeta-app .analytics-menu .menuItem,
  .jeeta-app * .analytics-menu .menuItem {
    margin-left: 0;
    padding: .5rem;
    gap: .2rem;
  }
  .jeeta-app .analytics-menu .menuItem .icon,
  .jeeta-app * .analytics-menu .menuItem .icon {
    font-size: 1.25rem;
  }
  .jeeta-app .analytics-menu .active,
  .jeeta-app * .analytics-menu .active {
    background: transparent;
    color: var(--SecondaryColor);
    font-weight: 500;
  }
  .jeeta-app .analytics-menu .active span,
  .jeeta-app * .analytics-menu .active span {
    display: block;
  }
  .jeeta-app .analytics-menu .active::before,
  .jeeta-app * .analytics-menu .active::before {
    content: "";
    width: 0;
    /* Full width of the container */
    height: 0;
    /* Thickness of the bottom line */
    background-color: transparent;
  }
}
