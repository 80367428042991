.jeeta-app,
.jeeta-app *,
.customModal,
.customModal * {

    .slotLectureVideo,
    .slotLectureSummary {
        padding-top: .5rem;
        position: relative;
        width: 95%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;

        .videoPlayer {
            position: relative;
            width: 100%;
            height: 100%;
            // min-height: 25rem;
            // border: 2px solid var(--PrimaryBkg);
            background-color: var(--whiteColorDeam);
            border-radius: 1rem;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            overflow: hidden;
            // padding: .5rem;

            .no-video-msg,
            .end-recording-msg {
                font-size: 1.2rem;
                font-weight: 600;
                color: var(--tiger-lilly);
            }

            .live-class-msg {
                font-size: 1.2rem;
                font-weight: 600;
                color: var(--PrimaryColor);
            }

            #record {
                transform: scale(1.2);

                &:hover {
                    background-color: var(--tiger-lilly);
                }
            }
        }
    }

    .btns {
        width: 100%;
        justify-content: center;
        gap: 1rem;
    }

    #back,
    #edit {
        background-color: var(--SecondaryColor);
    }

    #delete {
        background-color: var(--tiger-lilly);
    }

    .confirmPopup {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: var(--whiteColorDeam);
        padding: 2.5rem 3rem 1rem 3rem;
        border-radius: 1rem;
        box-shadow: 0px 0px 10px rgba(189, 195, 199, 0.5);
        z-index: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        .radio-group {
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: .5rem;

            .radio-option {
                padding: .5rem;
                font-size: 1rem;
                font-weight: 500;
                flex-direction: row;
                align-items: center;
                gap: .2rem;
            }
        }
    }

    .slotPinAuthModal {
        background-color: var(--whiteColorDeam);
        padding: 2rem;
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1000;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        .modalTitle {
            padding: 1rem;
        }

        .pinInput {
            width: 80%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: .5rem;
        }

        .lectureInfoInput {
            width: 80%;
            justify-content: center;
            align-items: center;
            gap: 1rem;

            .infoLabel {
                font-weight: 600;
            }

            .textInput {
                padding: .5rem;
                border: none;
            }
        }

        #enterPIN {
            margin-top: 1rem;
            font-weight: 600;
            transform: scale(1.1);
        }

        #close {
            position: absolute;
            top: .3rem;
            right: .3rem;
            color: var(--PrimaryColor);
            align-self: center;

            &:hover {
                color: var(--tiger-lilly);
            }
        }
    }

    .slotLiveDoubts {
        padding-top: .5rem;
        position: relative;
        width: 95%;
        height: 100%;
        gap: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .doubtsContainer {
        position: relative;
        width: 100%;
        height: 100%;
        // border: 2px solid var(--PrimaryBkg);
        background-color: var(--whiteColorDeam);
        border-radius: 1rem;
        padding: .5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        min-height: 25rem;

        .title {
            color: var(--textColor);
        }

        .no-live-doubts-msg {
            font-size: 1.2rem;
            font-weight: 600;
            color: var(--tiger-lilly);
        }
    }

    #doubtsRefresh {
        transform: scale(1.1);
    }

    .doubtsList {
        padding: .5rem 0;
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;

        .doubtItem {
            display: flex;
            justify-content: space-between;
            width: 100%;
            color: var(--whiteColor);
            background-color: var(--PrimaryBkg);
            padding: 0.5rem 1rem;
            border-radius: 0.5rem;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

            .doubt-text {
                color: var(--textColor);
                font-weight: 600;
            }

            .time {
                align-self: flex-end;
            }
        }

        .doubtItem.even {
            background-color: var(--SecondaryBkg);
        }
    }

    .doubtClusterList {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 1rem;
    }

    .newDoubtForm {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: .5rem 0;
        gap: 1rem;

        .newDoubtInput {
            width: 100%;
            font-size: 1rem;
            // flex-grow: 1;
            padding: 0.5rem;
            border-radius: 0.5rem;
            border: 1px solid var(--SecondaryColor);
        }

        .submitDoubtBtn {
            padding: 0.5rem 1rem;
            border-radius: 0.5rem;
            border: none;
            background-color: var(--PrimaryColor);
            color: var(--whiteColor);
            cursor: pointer;

            &:hover {
                background-color: var(--SecondaryColor);
            }
        }
    }

    .chapters-table-container {
        margin-top: 1.2rem;
    }

    .chapters-table {
        width: 100%;
        border-collapse: collapse;
    }

    .chapters-table th,
    .chapters-table td {
        border: 1px solid #ddd;
        padding: .5rem;
        text-align: left;
    }

    .chapters-table th {
        background-color: #f2f2f2;
    }

    /* Add styles for hovering over table rows */
    .chapters-table tbody tr:hover {
        background-color: #eaeaea;
        /* Light grey background on hover */
        cursor: pointer;
        /* Change cursor to indicate clickability */
    }

    .video-chapter {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    #meetingSDKElement {
        height: 100%;
        width: 100%;
        z-index: 10;
    }


    // .css-1vu2yqv, .css-1nwgrc8, .css-d2sft1 {
    //     width: 100%;
    //     height:100%
    // }

    // .css-ulr84r {
    //     height: 70%;
    // }

    .MuiPopper-root {
        position: fixed !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
        z-index: 9999;
        /* Ensure it stays on top */
    }

    .slotQuestions {
        padding-top: .5rem;
        position: relative;
        width: 95%;
        height: 100%;
        gap: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            flex-direction: column;
            align-items: center;
        }

        .slotQuestionSlider {
            position: relative;
            width: 95%;
            min-height: 13rem;
            background: var(--cardBG);
            border-radius: 1rem;
            box-shadow: 0 2px 4px rgba(140, 140, 141, .549);
            padding: 0 .5rem;
        }
    }
}


//MEDIA QUERIES ==================================================>
@media all and (min-width: 1200px) {}

@media all and (max-width: 1000px) {

    .doubtClusterList {
        grid-template-columns: repeat(3, 1fr);
        gap: .5rem;
    }

    .chapters-table-wrapper {
        max-height: 35rem;
    }


    @media all and (max-width: 860px) {

        .doubtsContainer {
            min-height: auto;
        }

        .chapters-table-wrapper {
            max-height: 30rem;
        }
    }

    @media all and (max-width: 800px) {}

    @media all and (max-width: 720px) {

        .slotLectureVideo,
        .slotLectureSummary {

            .videoPlayer {
                min-height: 300px;

                .no-video-msg,
                .live-class-msg,
                .end-recording-msg {
                    transform: scale(0.95);
                }

                #record {
                    transform: scale(1.1);
                }
            }
        }

        .slotLiveDoubts {

            .doubtsList,
            .newDoubtForm {
                width: 95%;
            }
        }

        .doubtClusterList {
            grid-template-columns: repeat(2, 1fr);
        }

        .slotPinAuthModal {
            padding: 1.5rem;
        }

        .slotQuestions {
            .slotQuestionSlider {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: .5rem;
                padding: 0;
            }
        }
    }

    @media all and (max-width: 550px) {

        .slotLectureVideo,
        .slotLectureSummary {

            .videoPlayer {

                .no-video-msg,
                .live-class-msg,
                .end-recording-msg {
                    transform: scale(0.9);
                }

                #record {
                    transform: scale(1);
                }
            }
        }

        .slotLiveDoubts {
            gap: .5rem;

            #doubtsRefresh {
                transform: scale(.95);
            }
        }

        .doubtsContainer {
            min-height: 20rem;

            .title {
                font-size: .9rem;
            }
        }

        .doubtClusterList {
            display: flex;
            flex-direction: column;
            // gap: .5rem;
        }

        .slotPinAuthModal {
            padding: 1rem;
        }

        .vjs-control {
            width: 7% !important;
        }

        .vjs-time-control {
            width: 10% !important;
            padding: 0 !important;
        }

        .vjs-progress-control {
            min-width: 30% !important;
        }
    }
}