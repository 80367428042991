.jeeta-app .questionSlider,
.jeeta-app * .questionSlider,
.customModal .questionSlider, .customModal * .questionSlider {
  position: relative;
  width: 100%;
  padding: 2rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  overflow: hidden;
}

.jeeta-app .slide-wrapper,
.jeeta-app * .slide-wrapper,
.customModal .slide-wrapper, .customModal * .slide-wrapper {
  width: 100%;
  min-height: 400px;
  position: relative;
  background-color: var(--whiteColor);
  border-radius: 1rem;
  border: 3px solid var(--PrimaryBkg);
  padding: 1rem 4rem 3rem 4rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: -webkit-transform ease-out 500ms;
  transition: -webkit-transform ease-out 500ms;
  transition: transform ease-out 500ms;
  transition: transform ease-out 500ms, -webkit-transform ease-out 500ms;
}

.jeeta-app .slider-controls,
.jeeta-app * .slider-controls,
.customModal .slider-controls, .customModal * .slider-controls {
  /* Positioning for the whole dots wrapper */
}

.jeeta-app .slider-controls .slider-btns,
.jeeta-app * .slider-controls .slider-btns,
.customModal .slider-controls .slider-btns, .customModal * .slider-controls .slider-btns {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.jeeta-app .slider-controls .slider-btns .prev-btn,
.jeeta-app .slider-controls .slider-btns .next-tbn,
.jeeta-app * .slider-controls .slider-btns .prev-btn,
.jeeta-app * .slider-controls .slider-btns .next-tbn,
.customModal .slider-controls .slider-btns .prev-btn,
.customModal .slider-controls .slider-btns .next-tbn, .customModal * .slider-controls .slider-btns .prev-btn,
.customModal * .slider-controls .slider-btns .next-tbn {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  border: none;
  background-color: var(--whiteColor);
  color: var(--PrimaryColor);
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.jeeta-app .slider-controls .slider-btns .prev-btn:hover,
.jeeta-app .slider-controls .slider-btns .next-tbn:hover,
.jeeta-app * .slider-controls .slider-btns .prev-btn:hover,
.jeeta-app * .slider-controls .slider-btns .next-tbn:hover,
.customModal .slider-controls .slider-btns .prev-btn:hover,
.customModal .slider-controls .slider-btns .next-tbn:hover, .customModal * .slider-controls .slider-btns .prev-btn:hover,
.customModal * .slider-controls .slider-btns .next-tbn:hover {
  cursor: pointer;
  background-color: var(--whiteColorDeam);
  color: var(--SecondaryColor);
}

.jeeta-app .slider-controls .slider-btns .prev-btn .icon,
.jeeta-app .slider-controls .slider-btns .next-tbn .icon,
.jeeta-app * .slider-controls .slider-btns .prev-btn .icon,
.jeeta-app * .slider-controls .slider-btns .next-tbn .icon,
.customModal .slider-controls .slider-btns .prev-btn .icon,
.customModal .slider-controls .slider-btns .next-tbn .icon, .customModal * .slider-controls .slider-btns .prev-btn .icon,
.customModal * .slider-controls .slider-btns .next-tbn .icon {
  width: 3rem;
  height: 3rem;
}

.jeeta-app .slider-controls .dots-wrapper,
.jeeta-app * .slider-controls .dots-wrapper,
.customModal .slider-controls .dots-wrapper, .customModal * .slider-controls .dots-wrapper {
  position: absolute;
  width: 100%;
  bottom: 1rem;
  right: 0;
  left: 0;
  /* Container styles */
}

.jeeta-app .slider-controls .dots-wrapper .dots-container,
.jeeta-app * .slider-controls .dots-wrapper .dots-container,
.customModal .slider-controls .dots-wrapper .dots-container, .customModal * .slider-controls .dots-wrapper .dots-container {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 5px;
  /* Base styling for the dot */
  /* Additional styling for the active dot */
}

.jeeta-app .slider-controls .dots-wrapper .dots-container .dot,
.jeeta-app * .slider-controls .dots-wrapper .dots-container .dot,
.customModal .slider-controls .dots-wrapper .dots-container .dot, .customModal * .slider-controls .dots-wrapper .dots-container .dot {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 10px;
  height: 10px;
  background-color: var(--PrimaryColor);
  border-radius: 50%;
  opacity: 0.5;
  /* Default state with reduced opacity */
}

.jeeta-app .slider-controls .dots-wrapper .dots-container .dot-active,
.jeeta-app * .slider-controls .dots-wrapper .dots-container .dot-active,
.customModal .slider-controls .dots-wrapper .dots-container .dot-active, .customModal * .slider-controls .dots-wrapper .dots-container .dot-active {
  padding: 2px;
  opacity: 1;
  /* Full opacity for the active dot */
}

.jeeta-app #check-answer,
.jeeta-app * #check-answer,
.customModal #check-answer, .customModal * #check-answer {
  margin-bottom: 1rem;
}

.jeeta-app .paragraph-slide,
.jeeta-app .question-slide,
.jeeta-app * .paragraph-slide,
.jeeta-app * .question-slide,
.customModal .paragraph-slide,
.customModal .question-slide, .customModal * .paragraph-slide,
.customModal * .question-slide {
  position: relative;
  width: 100%;
  height: 100%;
  background: transparent;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
  font-size: 1.1rem;
}

.jeeta-app .paragraph-slide .paragraph-questions,
.jeeta-app .question-slide .paragraph-questions,
.jeeta-app * .paragraph-slide .paragraph-questions,
.jeeta-app * .question-slide .paragraph-questions,
.customModal .paragraph-slide .paragraph-questions,
.customModal .question-slide .paragraph-questions, .customModal * .paragraph-slide .paragraph-questions,
.customModal * .question-slide .paragraph-questions {
  width: 100%;
  height: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: .3rem;
  padding: 0 1rem;
}

.jeeta-app .paragraph-slide .question-info,
.jeeta-app .question-slide .question-info,
.jeeta-app * .paragraph-slide .question-info,
.jeeta-app * .question-slide .question-info,
.customModal .paragraph-slide .question-info,
.customModal .question-slide .question-info, .customModal * .paragraph-slide .question-info,
.customModal * .question-slide .question-info {
  position: relative;
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  padding: .5rem;
  gap: .5rem;
}

.jeeta-app .paragraph-slide .question-info .question-saved,
.jeeta-app .question-slide .question-info .question-saved,
.jeeta-app * .paragraph-slide .question-info .question-saved,
.jeeta-app * .question-slide .question-info .question-saved,
.customModal .paragraph-slide .question-info .question-saved,
.customModal .question-slide .question-info .question-saved, .customModal * .paragraph-slide .question-info .question-saved,
.customModal * .question-slide .question-info .question-saved {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: .2rem;
  gap: .2rem;
}

.jeeta-app .paragraph-slide .question-info .question-saved .saved-btn,
.jeeta-app .question-slide .question-info .question-saved .saved-btn,
.jeeta-app * .paragraph-slide .question-info .question-saved .saved-btn,
.jeeta-app * .question-slide .question-info .question-saved .saved-btn,
.customModal .paragraph-slide .question-info .question-saved .saved-btn,
.customModal .question-slide .question-info .question-saved .saved-btn, .customModal * .paragraph-slide .question-info .question-saved .saved-btn,
.customModal * .question-slide .question-info .question-saved .saved-btn {
  border: none;
  background-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.jeeta-app .paragraph-slide .question-info .question-saved .saved-btn .icon,
.jeeta-app .question-slide .question-info .question-saved .saved-btn .icon,
.jeeta-app * .paragraph-slide .question-info .question-saved .saved-btn .icon,
.jeeta-app * .question-slide .question-info .question-saved .saved-btn .icon,
.customModal .paragraph-slide .question-info .question-saved .saved-btn .icon,
.customModal .question-slide .question-info .question-saved .saved-btn .icon, .customModal * .paragraph-slide .question-info .question-saved .saved-btn .icon,
.customModal * .question-slide .question-info .question-saved .saved-btn .icon {
  font-size: 1.6rem;
}

.jeeta-app .paragraph-slide .question-info .question-saved #star-btn,
.jeeta-app .question-slide .question-info .question-saved #star-btn,
.jeeta-app * .paragraph-slide .question-info .question-saved #star-btn,
.jeeta-app * .question-slide .question-info .question-saved #star-btn,
.customModal .paragraph-slide .question-info .question-saved #star-btn,
.customModal .question-slide .question-info .question-saved #star-btn, .customModal * .paragraph-slide .question-info .question-saved #star-btn,
.customModal * .question-slide .question-info .question-saved #star-btn {
  color: var(--tiger-lilly);
}

.jeeta-app .paragraph-slide .question-info .question-saved #review-btn,
.jeeta-app .question-slide .question-info .question-saved #review-btn,
.jeeta-app * .paragraph-slide .question-info .question-saved #review-btn,
.jeeta-app * .question-slide .question-info .question-saved #review-btn,
.customModal .paragraph-slide .question-info .question-saved #review-btn,
.customModal .question-slide .question-info .question-saved #review-btn, .customModal * .paragraph-slide .question-info .question-saved #review-btn,
.customModal * .question-slide .question-info .question-saved #review-btn {
  padding-top: .2rem;
  color: var(--kelly-green);
}

.jeeta-app .paragraph-slide .question-difficulty,
.jeeta-app .question-slide .question-difficulty,
.jeeta-app * .paragraph-slide .question-difficulty,
.jeeta-app * .question-slide .question-difficulty,
.customModal .paragraph-slide .question-difficulty,
.customModal .question-slide .question-difficulty, .customModal * .paragraph-slide .question-difficulty,
.customModal * .question-slide .question-difficulty {
  background-color: var(--SecondaryColor);
  padding: .2rem .4rem;
  border-radius: .5rem;
  color: white;
  font-size: .94rem;
  font-weight: 600;
}

.jeeta-app .paragraph-slide #easy,
.jeeta-app .question-slide #easy,
.jeeta-app * .paragraph-slide #easy,
.jeeta-app * .question-slide #easy,
.customModal .paragraph-slide #easy,
.customModal .question-slide #easy, .customModal * .paragraph-slide #easy,
.customModal * .question-slide #easy {
  background-color: var(--easy);
}

.jeeta-app .paragraph-slide #medium,
.jeeta-app .question-slide #medium,
.jeeta-app * .paragraph-slide #medium,
.jeeta-app * .question-slide #medium,
.customModal .paragraph-slide #medium,
.customModal .question-slide #medium, .customModal * .paragraph-slide #medium,
.customModal * .question-slide #medium {
  background-color: var(--medium);
}

.jeeta-app .paragraph-slide #hard,
.jeeta-app .question-slide #hard,
.jeeta-app * .paragraph-slide #hard,
.jeeta-app * .question-slide #hard,
.customModal .paragraph-slide #hard,
.customModal .question-slide #hard, .customModal * .paragraph-slide #hard,
.customModal * .question-slide #hard {
  background-color: var(--hard);
}

.jeeta-app .paragraph-slide .question-id,
.jeeta-app .question-slide .question-id,
.jeeta-app * .paragraph-slide .question-id,
.jeeta-app * .question-slide .question-id,
.customModal .paragraph-slide .question-id,
.customModal .question-slide .question-id, .customModal * .paragraph-slide .question-id,
.customModal * .question-slide .question-id {
  background-color: var(--PrimaryColor);
  padding: .2rem .4rem;
  border-radius: .5rem;
  color: white;
  font-size: .94rem;
  font-weight: 600;
}

.jeeta-app .paragraph-slide #Physics,
.jeeta-app .question-slide #Physics,
.jeeta-app * .paragraph-slide #Physics,
.jeeta-app * .question-slide #Physics,
.customModal .paragraph-slide #Physics,
.customModal .question-slide #Physics, .customModal * .paragraph-slide #Physics,
.customModal * .question-slide #Physics {
  background-color: var(--Physics);
}

.jeeta-app .paragraph-slide #Chemistry,
.jeeta-app .question-slide #Chemistry,
.jeeta-app * .paragraph-slide #Chemistry,
.jeeta-app * .question-slide #Chemistry,
.customModal .paragraph-slide #Chemistry,
.customModal .question-slide #Chemistry, .customModal * .paragraph-slide #Chemistry,
.customModal * .question-slide #Chemistry {
  background-color: var(--Chemistry);
}

.jeeta-app .paragraph-slide #Mathematics,
.jeeta-app .question-slide #Mathematics,
.jeeta-app * .paragraph-slide #Mathematics,
.jeeta-app * .question-slide #Mathematics,
.customModal .paragraph-slide #Mathematics,
.customModal .question-slide #Mathematics, .customModal * .paragraph-slide #Mathematics,
.customModal * .question-slide #Mathematics {
  background-color: var(--Mathematics);
}

.jeeta-app .paragraph-slide .question-text,
.jeeta-app .paragraph-slide .paragraph-text,
.jeeta-app .question-slide .question-text,
.jeeta-app .question-slide .paragraph-text,
.jeeta-app * .paragraph-slide .question-text,
.jeeta-app * .paragraph-slide .paragraph-text,
.jeeta-app * .question-slide .question-text,
.jeeta-app * .question-slide .paragraph-text,
.customModal .paragraph-slide .question-text,
.customModal .paragraph-slide .paragraph-text,
.customModal .question-slide .question-text,
.customModal .question-slide .paragraph-text, .customModal * .paragraph-slide .question-text,
.customModal * .paragraph-slide .paragraph-text,
.customModal * .question-slide .question-text,
.customModal * .question-slide .paragraph-text {
  width: 100%;
  padding: .5rem;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  font-family: 'Frank Ruhl Libre', serif;
}

.jeeta-app .paragraph-slide .question-text span,
.jeeta-app .paragraph-slide .paragraph-text span,
.jeeta-app .question-slide .question-text span,
.jeeta-app .question-slide .paragraph-text span,
.jeeta-app * .paragraph-slide .question-text span,
.jeeta-app * .paragraph-slide .paragraph-text span,
.jeeta-app * .question-slide .question-text span,
.jeeta-app * .question-slide .paragraph-text span,
.customModal .paragraph-slide .question-text span,
.customModal .paragraph-slide .paragraph-text span,
.customModal .question-slide .question-text span,
.customModal .question-slide .paragraph-text span, .customModal * .paragraph-slide .question-text span,
.customModal * .paragraph-slide .paragraph-text span,
.customModal * .question-slide .question-text span,
.customModal * .question-slide .paragraph-text span {
  font-family: 'Frank Ruhl Libre', serif;
  line-height: 1.4;
}

.jeeta-app .paragraph-slide .question-text .question-index,
.jeeta-app .paragraph-slide .paragraph-text .question-index,
.jeeta-app .question-slide .question-text .question-index,
.jeeta-app .question-slide .paragraph-text .question-index,
.jeeta-app * .paragraph-slide .question-text .question-index,
.jeeta-app * .paragraph-slide .paragraph-text .question-index,
.jeeta-app * .question-slide .question-text .question-index,
.jeeta-app * .question-slide .paragraph-text .question-index,
.customModal .paragraph-slide .question-text .question-index,
.customModal .paragraph-slide .paragraph-text .question-index,
.customModal .question-slide .question-text .question-index,
.customModal .question-slide .paragraph-text .question-index, .customModal * .paragraph-slide .question-text .question-index,
.customModal * .paragraph-slide .paragraph-text .question-index,
.customModal * .question-slide .question-text .question-index,
.customModal * .question-slide .paragraph-text .question-index {
  margin-top: 3px;
  margin-right: 5px;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  font-weight: 600;
}

.jeeta-app .paragraph-slide .question-image,
.jeeta-app .paragraph-slide .paragraph-images,
.jeeta-app .question-slide .question-image,
.jeeta-app .question-slide .paragraph-images,
.jeeta-app * .paragraph-slide .question-image,
.jeeta-app * .paragraph-slide .paragraph-images,
.jeeta-app * .question-slide .question-image,
.jeeta-app * .question-slide .paragraph-images,
.customModal .paragraph-slide .question-image,
.customModal .paragraph-slide .paragraph-images,
.customModal .question-slide .question-image,
.customModal .question-slide .paragraph-images, .customModal * .paragraph-slide .question-image,
.customModal * .paragraph-slide .paragraph-images,
.customModal * .question-slide .question-image,
.customModal * .question-slide .paragraph-images {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: auto;
  gap: .5rem;
}

.jeeta-app .paragraph-slide .question-image .img,
.jeeta-app .paragraph-slide .paragraph-images .img,
.jeeta-app .question-slide .question-image .img,
.jeeta-app .question-slide .paragraph-images .img,
.jeeta-app * .paragraph-slide .question-image .img,
.jeeta-app * .paragraph-slide .paragraph-images .img,
.jeeta-app * .question-slide .question-image .img,
.jeeta-app * .question-slide .paragraph-images .img,
.customModal .paragraph-slide .question-image .img,
.customModal .paragraph-slide .paragraph-images .img,
.customModal .question-slide .question-image .img,
.customModal .question-slide .paragraph-images .img, .customModal * .paragraph-slide .question-image .img,
.customModal * .paragraph-slide .paragraph-images .img,
.customModal * .question-slide .question-image .img,
.customModal * .question-slide .paragraph-images .img {
  max-width: 100%;
  max-height: 50vh;
  height: auto;
  -o-object-fit: contain;
     object-fit: contain;
}

.jeeta-app .paragraph-slide .question-image-only,
.jeeta-app .question-slide .question-image-only,
.jeeta-app * .paragraph-slide .question-image-only,
.jeeta-app * .question-slide .question-image-only,
.customModal .paragraph-slide .question-image-only,
.customModal .question-slide .question-image-only, .customModal * .paragraph-slide .question-image-only,
.customModal * .question-slide .question-image-only {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: auto;
}

.jeeta-app .paragraph-slide .question-image-only .wide,
.jeeta-app .question-slide .question-image-only .wide,
.jeeta-app * .paragraph-slide .question-image-only .wide,
.jeeta-app * .question-slide .question-image-only .wide,
.customModal .paragraph-slide .question-image-only .wide,
.customModal .question-slide .question-image-only .wide, .customModal * .paragraph-slide .question-image-only .wide,
.customModal * .question-slide .question-image-only .wide {
  max-width: 100%;
  max-height: 50vh;
  height: auto;
  -o-object-fit: contain;
     object-fit: contain;
}

.jeeta-app .paragraph-slide .question-image-only .long,
.jeeta-app .question-slide .question-image-only .long,
.jeeta-app * .paragraph-slide .question-image-only .long,
.jeeta-app * .question-slide .question-image-only .long,
.customModal .paragraph-slide .question-image-only .long,
.customModal .question-slide .question-image-only .long, .customModal * .paragraph-slide .question-image-only .long,
.customModal * .question-slide .question-image-only .long {
  max-width: 80%;
  max-height: 50vh;
  height: auto;
  -o-object-fit: contain;
     object-fit: contain;
}

.jeeta-app .paragraph-slide .answers,
.jeeta-app .question-slide .answers,
.jeeta-app * .paragraph-slide .answers,
.jeeta-app * .question-slide .answers,
.customModal .paragraph-slide .answers,
.customModal .question-slide .answers, .customModal * .paragraph-slide .answers,
.customModal * .question-slide .answers {
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 1rem;
  padding: 0 1rem;
}

.jeeta-app .paragraph-slide .answers .answer-container,
.jeeta-app .question-slide .answers .answer-container,
.jeeta-app * .paragraph-slide .answers .answer-container,
.jeeta-app * .question-slide .answers .answer-container,
.customModal .paragraph-slide .answers .answer-container,
.customModal .question-slide .answers .answer-container, .customModal * .paragraph-slide .answers .answer-container,
.customModal * .question-slide .answers .answer-container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 1.05rem;
}

.jeeta-app .paragraph-slide .answers .answer-container .option,
.jeeta-app .question-slide .answers .answer-container .option,
.jeeta-app * .paragraph-slide .answers .answer-container .option,
.jeeta-app * .question-slide .answers .answer-container .option,
.customModal .paragraph-slide .answers .answer-container .option,
.customModal .question-slide .answers .answer-container .option, .customModal * .paragraph-slide .answers .answer-container .option,
.customModal * .question-slide .answers .answer-container .option {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: .5rem;
  font-family: 'Frank Ruhl Libre', serif;
}

.jeeta-app .paragraph-slide .answers .answer-container .option .answer-check,
.jeeta-app .question-slide .answers .answer-container .option .answer-check,
.jeeta-app * .paragraph-slide .answers .answer-container .option .answer-check,
.jeeta-app * .question-slide .answers .answer-container .option .answer-check,
.customModal .paragraph-slide .answers .answer-container .option .answer-check,
.customModal .question-slide .answers .answer-container .option .answer-check, .customModal * .paragraph-slide .answers .answer-container .option .answer-check,
.customModal * .question-slide .answers .answer-container .option .answer-check {
  margin-top: 2px;
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

.jeeta-app .paragraph-slide .answers .answer-container .option .answer-label,
.jeeta-app .question-slide .answers .answer-container .option .answer-label,
.jeeta-app * .paragraph-slide .answers .answer-container .option .answer-label,
.jeeta-app * .question-slide .answers .answer-container .option .answer-label,
.customModal .paragraph-slide .answers .answer-container .option .answer-label,
.customModal .question-slide .answers .answer-container .option .answer-label, .customModal * .paragraph-slide .answers .answer-container .option .answer-label,
.customModal * .question-slide .answers .answer-container .option .answer-label {
  font-weight: 700;
  font-family: 'Frank Ruhl Libre', serif;
}

.jeeta-app .paragraph-slide .answers .answer-container .option span,
.jeeta-app .question-slide .answers .answer-container .option span,
.jeeta-app * .paragraph-slide .answers .answer-container .option span,
.jeeta-app * .question-slide .answers .answer-container .option span,
.customModal .paragraph-slide .answers .answer-container .option span,
.customModal .question-slide .answers .answer-container .option span, .customModal * .paragraph-slide .answers .answer-container .option span,
.customModal * .question-slide .answers .answer-container .option span {
  font-family: 'Frank Ruhl Libre', serif;
}

.jeeta-app .paragraph-slide .answers .answer-container .subjective,
.jeeta-app .question-slide .answers .answer-container .subjective,
.jeeta-app * .paragraph-slide .answers .answer-container .subjective,
.jeeta-app * .question-slide .answers .answer-container .subjective,
.customModal .paragraph-slide .answers .answer-container .subjective,
.customModal .question-slide .answers .answer-container .subjective, .customModal * .paragraph-slide .answers .answer-container .subjective,
.customModal * .question-slide .answers .answer-container .subjective {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: .5rem;
}

.jeeta-app .paragraph-slide .answers .answer-container .subjective span, .jeeta-app .paragraph-slide .answers .answer-container .subjective input,
.jeeta-app .question-slide .answers .answer-container .subjective span,
.jeeta-app .question-slide .answers .answer-container .subjective input,
.jeeta-app * .paragraph-slide .answers .answer-container .subjective span,
.jeeta-app * .paragraph-slide .answers .answer-container .subjective input,
.jeeta-app * .question-slide .answers .answer-container .subjective span,
.jeeta-app * .question-slide .answers .answer-container .subjective input,
.customModal .paragraph-slide .answers .answer-container .subjective span,
.customModal .paragraph-slide .answers .answer-container .subjective input,
.customModal .question-slide .answers .answer-container .subjective span,
.customModal .question-slide .answers .answer-container .subjective input, .customModal * .paragraph-slide .answers .answer-container .subjective span, .customModal * .paragraph-slide .answers .answer-container .subjective input,
.customModal * .question-slide .answers .answer-container .subjective span,
.customModal * .question-slide .answers .answer-container .subjective input {
  width: 100%;
  font-family: 'Frank Ruhl Libre', serif;
}

.jeeta-app .paragraph-slide .answers .answer-container .subjective .subjective-input,
.jeeta-app .question-slide .answers .answer-container .subjective .subjective-input,
.jeeta-app * .paragraph-slide .answers .answer-container .subjective .subjective-input,
.jeeta-app * .question-slide .answers .answer-container .subjective .subjective-input,
.customModal .paragraph-slide .answers .answer-container .subjective .subjective-input,
.customModal .question-slide .answers .answer-container .subjective .subjective-input, .customModal * .paragraph-slide .answers .answer-container .subjective .subjective-input,
.customModal * .question-slide .answers .answer-container .subjective .subjective-input {
  padding: .2rem .5rem;
}

.jeeta-app .paragraph-slide .answers .answer-container .subjective .subjective-given-answer,
.jeeta-app .question-slide .answers .answer-container .subjective .subjective-given-answer,
.jeeta-app * .paragraph-slide .answers .answer-container .subjective .subjective-given-answer,
.jeeta-app * .question-slide .answers .answer-container .subjective .subjective-given-answer,
.customModal .paragraph-slide .answers .answer-container .subjective .subjective-given-answer,
.customModal .question-slide .answers .answer-container .subjective .subjective-given-answer, .customModal * .paragraph-slide .answers .answer-container .subjective .subjective-given-answer,
.customModal * .question-slide .answers .answer-container .subjective .subjective-given-answer {
  font-weight: 600;
}

.jeeta-app .paragraph-slide .answers.correct,
.jeeta-app .question-slide .answers.correct,
.jeeta-app * .paragraph-slide .answers.correct,
.jeeta-app * .question-slide .answers.correct,
.customModal .paragraph-slide .answers.correct,
.customModal .question-slide .answers.correct, .customModal * .paragraph-slide .answers.correct,
.customModal * .question-slide .answers.correct {
  border-color: green;
}

.jeeta-app .paragraph-slide .delete-btn,
.jeeta-app .question-slide .delete-btn,
.jeeta-app * .paragraph-slide .delete-btn,
.jeeta-app * .question-slide .delete-btn,
.customModal .paragraph-slide .delete-btn,
.customModal .question-slide .delete-btn, .customModal * .paragraph-slide .delete-btn,
.customModal * .question-slide .delete-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--whiteColor);
  color: var(--SecondaryColor);
  border: none;
  font-size: .87rem;
  padding: .3rem 1rem;
  font-weight: 600;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: .5rem;
}

.jeeta-app .paragraph-slide .delete-btn .icon,
.jeeta-app .question-slide .delete-btn .icon,
.jeeta-app * .paragraph-slide .delete-btn .icon,
.jeeta-app * .question-slide .delete-btn .icon,
.customModal .paragraph-slide .delete-btn .icon,
.customModal .question-slide .delete-btn .icon, .customModal * .paragraph-slide .delete-btn .icon,
.customModal * .question-slide .delete-btn .icon {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  font-size: 1.11rem;
}

.jeeta-app .paragraph-slide .delete-btn:hover,
.jeeta-app .question-slide .delete-btn:hover,
.jeeta-app * .paragraph-slide .delete-btn:hover,
.jeeta-app * .question-slide .delete-btn:hover,
.customModal .paragraph-slide .delete-btn:hover,
.customModal .question-slide .delete-btn:hover, .customModal * .paragraph-slide .delete-btn:hover,
.customModal * .question-slide .delete-btn:hover {
  cursor: pointer;
  color: var(--tiger-lilly);
}

.jeeta-app .paragraph-slide .edit-btn,
.jeeta-app .question-slide .edit-btn,
.jeeta-app * .paragraph-slide .edit-btn,
.jeeta-app * .question-slide .edit-btn,
.customModal .paragraph-slide .edit-btn,
.customModal .question-slide .edit-btn, .customModal * .paragraph-slide .edit-btn,
.customModal * .question-slide .edit-btn {
  position: absolute;
  right: 0;
  background-color: var(--whiteColor);
  color: var(--PrimaryColor);
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.jeeta-app .paragraph-slide .edit-btn .icon,
.jeeta-app .question-slide .edit-btn .icon,
.jeeta-app * .paragraph-slide .edit-btn .icon,
.jeeta-app * .question-slide .edit-btn .icon,
.customModal .paragraph-slide .edit-btn .icon,
.customModal .question-slide .edit-btn .icon, .customModal * .paragraph-slide .edit-btn .icon,
.customModal * .question-slide .edit-btn .icon {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  font-size: 1.66rem;
}

.jeeta-app .paragraph-slide .edit-btn:hover,
.jeeta-app .question-slide .edit-btn:hover,
.jeeta-app * .paragraph-slide .edit-btn:hover,
.jeeta-app * .question-slide .edit-btn:hover,
.customModal .paragraph-slide .edit-btn:hover,
.customModal .question-slide .edit-btn:hover, .customModal * .paragraph-slide .edit-btn:hover,
.customModal * .question-slide .edit-btn:hover {
  cursor: pointer;
  color: var(--SecondaryColor);
}

.jeeta-app .paragraph-slide .delete-popup,
.jeeta-app .question-slide .delete-popup,
.jeeta-app * .paragraph-slide .delete-popup,
.jeeta-app * .question-slide .delete-popup,
.customModal .paragraph-slide .delete-popup,
.customModal .question-slide .delete-popup, .customModal * .paragraph-slide .delete-popup,
.customModal * .question-slide .delete-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: .5rem;
  padding: 1rem;
  background-color: var(--whiteColorDeam);
  border-radius: .5rem;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.jeeta-app .paragraph-slide .delete-popup .btns,
.jeeta-app .question-slide .delete-popup .btns,
.jeeta-app * .paragraph-slide .delete-popup .btns,
.jeeta-app * .question-slide .delete-popup .btns,
.customModal .paragraph-slide .delete-popup .btns,
.customModal .question-slide .delete-popup .btns, .customModal * .paragraph-slide .delete-popup .btns,
.customModal * .question-slide .delete-popup .btns {
  gap: 1rem;
}

.jeeta-app .paragraph-slide .answer-marks,
.jeeta-app .question-slide .answer-marks,
.jeeta-app * .paragraph-slide .answer-marks,
.jeeta-app * .question-slide .answer-marks,
.customModal .paragraph-slide .answer-marks,
.customModal .question-slide .answer-marks, .customModal * .paragraph-slide .answer-marks,
.customModal * .question-slide .answer-marks {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: .2rem;
}

.jeeta-app .paragraph-slide .answer-marks .mark,
.jeeta-app .question-slide .answer-marks .mark,
.jeeta-app * .paragraph-slide .answer-marks .mark,
.jeeta-app * .question-slide .answer-marks .mark,
.customModal .paragraph-slide .answer-marks .mark,
.customModal .question-slide .answer-marks .mark, .customModal * .paragraph-slide .answer-marks .mark,
.customModal * .question-slide .answer-marks .mark {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: .1rem;
}

.jeeta-app .paragraph-slide .answer-marks .mark p,
.jeeta-app .question-slide .answer-marks .mark p,
.jeeta-app * .paragraph-slide .answer-marks .mark p,
.jeeta-app * .question-slide .answer-marks .mark p,
.customModal .paragraph-slide .answer-marks .mark p,
.customModal .question-slide .answer-marks .mark p, .customModal * .paragraph-slide .answer-marks .mark p,
.customModal * .question-slide .answer-marks .mark p {
  font-size: 1.27rem;
  font-weight: 500;
}

.jeeta-app .paragraph-slide .answer-marks .mark .icon,
.jeeta-app .question-slide .answer-marks .mark .icon,
.jeeta-app * .paragraph-slide .answer-marks .mark .icon,
.jeeta-app * .question-slide .answer-marks .mark .icon,
.customModal .paragraph-slide .answer-marks .mark .icon,
.customModal .question-slide .answer-marks .mark .icon, .customModal * .paragraph-slide .answer-marks .mark .icon,
.customModal * .question-slide .answer-marks .mark .icon {
  font-size: 1.55rem;
}

.jeeta-app .paragraph-slide .answer-marks #correct .icon,
.jeeta-app .question-slide .answer-marks #correct .icon,
.jeeta-app * .paragraph-slide .answer-marks #correct .icon,
.jeeta-app * .question-slide .answer-marks #correct .icon,
.customModal .paragraph-slide .answer-marks #correct .icon,
.customModal .question-slide .answer-marks #correct .icon, .customModal * .paragraph-slide .answer-marks #correct .icon,
.customModal * .question-slide .answer-marks #correct .icon {
  color: var(--Chemistry);
}

.jeeta-app .paragraph-slide .answer-marks #incorrect .icon,
.jeeta-app .question-slide .answer-marks #incorrect .icon,
.jeeta-app * .paragraph-slide .answer-marks #incorrect .icon,
.jeeta-app * .question-slide .answer-marks #incorrect .icon,
.customModal .paragraph-slide .answer-marks #incorrect .icon,
.customModal .question-slide .answer-marks #incorrect .icon, .customModal * .paragraph-slide .answer-marks #incorrect .icon,
.customModal * .question-slide .answer-marks #incorrect .icon {
  color: var(--tiger-lilly);
}

.jeeta-app .paragraph-slide .answer-marks #unanswered .icon,
.jeeta-app .question-slide .answer-marks #unanswered .icon,
.jeeta-app * .paragraph-slide .answer-marks #unanswered .icon,
.jeeta-app * .question-slide .answer-marks #unanswered .icon,
.customModal .paragraph-slide .answer-marks #unanswered .icon,
.customModal .question-slide .answer-marks #unanswered .icon, .customModal * .paragraph-slide .answer-marks #unanswered .icon,
.customModal * .question-slide .answer-marks #unanswered .icon {
  color: var(--greyText);
}

.jeeta-app .solution,
.jeeta-app * .solution,
.customModal .solution, .customModal * .solution {
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: .1rem;
  background-color: rgba(24, 201, 168, 0.2);
  border-radius: .3rem;
  padding: .5rem;
}

.jeeta-app .solution .solution-btn,
.jeeta-app * .solution .solution-btn,
.customModal .solution .solution-btn, .customModal * .solution .solution-btn {
  background-color: transparent;
  color: var(--PrimaryColor);
  border: none;
  font-size: .87rem;
  font-weight: 600;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: .5rem;
}

.jeeta-app .solution .solution-btn .icon,
.jeeta-app * .solution .solution-btn .icon,
.customModal .solution .solution-btn .icon, .customModal * .solution .solution-btn .icon {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  font-size: 1.11rem;
}

.jeeta-app .solution .solution-btn:hover, .jeeta-app .solution .solution-btn:active,
.jeeta-app * .solution .solution-btn:hover,
.jeeta-app * .solution .solution-btn:active,
.customModal .solution .solution-btn:hover,
.customModal .solution .solution-btn:active, .customModal * .solution .solution-btn:hover, .customModal * .solution .solution-btn:active {
  cursor: pointer;
  border: 2px solid var(--SecondaryColor);
}

.jeeta-app .solution .solution-step,
.jeeta-app * .solution .solution-step,
.customModal .solution .solution-step, .customModal * .solution .solution-step {
  background: var(--glass);
  color: var(--textColor);
  font-weight: 600;
  font-size: .9rem;
  padding: .3rem .5rem;
  border-radius: .3rem;
}

.jeeta-app .solution .solution-text,
.jeeta-app * .solution .solution-text,
.customModal .solution .solution-text, .customModal * .solution .solution-text {
  font-family: 'Frank Ruhl Libre', serif;
  font-size: .9rem;
  overflow-x: auto;
  padding: .5rem;
}

.jeeta-app .solution .solution-text::-webkit-scrollbar,
.jeeta-app * .solution .solution-text::-webkit-scrollbar,
.customModal .solution .solution-text::-webkit-scrollbar, .customModal * .solution .solution-text::-webkit-scrollbar {
  height: .4rem;
}

.jeeta-app .solution .solution-text span,
.jeeta-app * .solution .solution-text span,
.customModal .solution .solution-text span, .customModal * .solution .solution-text span {
  font-family: 'Frank Ruhl Libre', serif;
  line-height: 1.4;
}

.jeeta-app .msg,
.jeeta-app * .msg,
.customModal .msg, .customModal * .msg {
  text-align: center;
  color: var(--PrimaryColor);
  font-size: 1rem;
}

.jeeta-app #correct-answer,
.jeeta-app * #correct-answer,
.customModal #correct-answer, .customModal * #correct-answer {
  background-color: greenyellow;
  font-weight: 600;
}

.jeeta-app #wrongAnswer,
.jeeta-app * #wrongAnswer,
.customModal #wrongAnswer, .customModal * #wrongAnswer {
  color: var(--tiger-lilly);
}

.jeeta-app #correctAnswer,
.jeeta-app * #correctAnswer,
.customModal #correctAnswer, .customModal * #correctAnswer {
  color: var(--PrimaryColor);
}

.jeeta-app .answerResponse,
.jeeta-app * .answerResponse,
.customModal .answerResponse, .customModal * .answerResponse {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media all and (max-width: 720px) {
  .jeeta-app .questionSlider,
  .jeeta-app * .questionSlider,
  .customModal .questionSlider, .customModal * .questionSlider {
    padding: 1rem .5rem;
  }
  .jeeta-app .questionSlider .slide-wrapper,
  .jeeta-app * .questionSlider .slide-wrapper,
  .customModal .questionSlider .slide-wrapper, .customModal * .questionSlider .slide-wrapper {
    padding: 1rem 3rem 3rem 3rem;
  }
}

@media all and (max-width: 500px) {
  .jeeta-app .questionSlider,
  .jeeta-app * .questionSlider,
  .customModal .questionSlider, .customModal * .questionSlider {
    padding: .5rem;
  }
  .jeeta-app .questionSlider .slide-wrapper,
  .jeeta-app * .questionSlider .slide-wrapper,
  .customModal .questionSlider .slide-wrapper, .customModal * .questionSlider .slide-wrapper {
    padding: .5rem .5rem 3rem .5rem;
  }
  .jeeta-app .questionSlider .slide-wrapper .msg,
  .jeeta-app * .questionSlider .slide-wrapper .msg,
  .customModal .questionSlider .slide-wrapper .msg, .customModal * .questionSlider .slide-wrapper .msg {
    font-size: .83rem;
  }
  .jeeta-app .questionSlider .slide-wrapper .slider-controls .slider-btns,
  .jeeta-app * .questionSlider .slide-wrapper .slider-controls .slider-btns,
  .customModal .questionSlider .slide-wrapper .slider-controls .slider-btns, .customModal * .questionSlider .slide-wrapper .slider-controls .slider-btns {
    display: none;
  }
}
