.jeeta-app .testSection,
.jeeta-app * .testSection,
.customModal .testSection, .customModal * .testSection {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 40vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: var(--glass);
  color: var(--textColor);
  gap: .5rem;
  padding: 0 1rem 1rem 1rem;
}

.jeeta-app .testSectionTop,
.jeeta-app * .testSectionTop,
.customModal .testSectionTop, .customModal * .testSectionTop {
  position: relative;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.jeeta-app .testSectionTop h3,
.jeeta-app .testSectionTop .testSectionTitle,
.jeeta-app * .testSectionTop h3,
.jeeta-app * .testSectionTop .testSectionTitle,
.customModal .testSectionTop h3,
.customModal .testSectionTop .testSectionTitle, .customModal * .testSectionTop h3,
.customModal * .testSectionTop .testSectionTitle {
  width: 100%;
  height: 35px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 1.11rem;
  line-height: 40px;
  border: none;
  outline: none;
  border-bottom: 1px solid var(--PrimaryColor);
}

.jeeta-app .testSectionTop .testSectionDescription,
.jeeta-app * .testSectionTop .testSectionDescription,
.customModal .testSectionTop .testSectionDescription, .customModal * .testSectionTop .testSectionDescription {
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: .83rem;
  line-height: 40px;
  border: none;
  outline: none;
}

.jeeta-app .testSectionTop .edit-delete-section,
.jeeta-app * .testSectionTop .edit-delete-section,
.customModal .testSectionTop .edit-delete-section, .customModal * .testSectionTop .edit-delete-section {
  position: absolute;
  top: 2px;
  right: 5px;
  color: var(--PrimaryColor);
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  font-size: 1.67rem;
}

.jeeta-app .testSectionTop .edit-delete-section .delete-section:hover, .jeeta-app .testSectionTop .edit-delete-section .edit-section:hover,
.jeeta-app * .testSectionTop .edit-delete-section .delete-section:hover,
.jeeta-app * .testSectionTop .edit-delete-section .edit-section:hover,
.customModal .testSectionTop .edit-delete-section .delete-section:hover,
.customModal .testSectionTop .edit-delete-section .edit-section:hover, .customModal * .testSectionTop .edit-delete-section .delete-section:hover, .customModal * .testSectionTop .edit-delete-section .edit-section:hover {
  color: var(--tiger-lilly);
}

.jeeta-app .testSectionNumbers,
.jeeta-app * .testSectionNumbers,
.customModal .testSectionNumbers, .customModal * .testSectionNumbers {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 1rem;
  gap: 1rem;
}

.jeeta-app .testSectionNumbers .numbersTitle,
.jeeta-app * .testSectionNumbers .numbersTitle,
.customModal .testSectionNumbers .numbersTitle, .customModal * .testSectionNumbers .numbersTitle {
  font-size: 1rem;
  font-weight: 600;
}

.jeeta-app .testSectionNumbers .numbersInput,
.jeeta-app * .testSectionNumbers .numbersInput,
.customModal .testSectionNumbers .numbersInput, .customModal * .testSectionNumbers .numbersInput {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: .5rem;
}

.jeeta-app .testSectionNumbers .numbersInput label,
.jeeta-app * .testSectionNumbers .numbersInput label,
.customModal .testSectionNumbers .numbersInput label, .customModal * .testSectionNumbers .numbersInput label {
  color: var(--PrimaryColor);
  font-size: .87rem;
  font-style: italic;
  font-weight: 600;
}

.jeeta-app .testSectionNumbers .numbersInput .testMarks,
.jeeta-app * .testSectionNumbers .numbersInput .testMarks,
.customModal .testSectionNumbers .numbersInput .testMarks, .customModal * .testSectionNumbers .numbersInput .testMarks {
  width: 50px;
  font-size: .77rem;
  padding: .2rem 0 .2rem .5rem;
  border: 1px solid var(--PrimaryColor);
}

.jeeta-app .testQuestionAddBtns,
.jeeta-app * .testQuestionAddBtns,
.customModal .testQuestionAddBtns, .customModal * .testQuestionAddBtns {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: .5rem;
  gap: 1rem;
}

.jeeta-app .testInfo,
.jeeta-app * .testInfo,
.customModal .testInfo, .customModal * .testInfo {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  padding: .5rem 1rem;
  gap: .5rem;
}

.jeeta-app .testInfo .instructions,
.jeeta-app * .testInfo .instructions,
.customModal .testInfo .instructions, .customModal * .testInfo .instructions {
  width: 100%;
  font-size: 1rem;
  line-height: 1.5;
}

.jeeta-app .testInfo .instructions li,
.jeeta-app * .testInfo .instructions li,
.customModal .testInfo .instructions li, .customModal * .testInfo .instructions li {
  font-family: 'Frank Ruhl Libre', serif;
  list-style-type: disc;
}

.jeeta-app .testInfo .testMarksList,
.jeeta-app * .testInfo .testMarksList,
.customModal .testInfo .testMarksList, .customModal * .testInfo .testMarksList {
  font-size: .94rem;
  margin-left: 1rem;
  line-height: 1.5;
}

.jeeta-app .testInfo .testMarksList .numbersInput,
.jeeta-app * .testInfo .testMarksList .numbersInput,
.customModal .testInfo .testMarksList .numbersInput, .customModal * .testInfo .testMarksList .numbersInput {
  font-family: 'Frank Ruhl Libre', serif;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: .5rem;
}

.jeeta-app .testInfo .testMarksList .numbersInput label,
.jeeta-app * .testInfo .testMarksList .numbersInput label,
.customModal .testInfo .testMarksList .numbersInput label, .customModal * .testInfo .testMarksList .numbersInput label {
  width: 7rem;
  font-size: .87rem;
  font-style: italic;
  font-weight: 600;
}

.jeeta-app .testInfo .testMarksList .numbersInput .testMarks,
.jeeta-app * .testInfo .testMarksList .numbersInput .testMarks,
.customModal .testInfo .testMarksList .numbersInput .testMarks, .customModal * .testInfo .testMarksList .numbersInput .testMarks {
  width: 2.2rem;
  font-size: .77rem;
  padding: .2rem 0 .2rem .5rem;
  border: 1px solid var(--PrimaryColor);
}

.jeeta-app .testSectionModal,
.jeeta-app * .testSectionModal,
.customModal .testSectionModal, .customModal * .testSectionModal {
  background-color: var(--whiteColorDeam);
  position: fixed;
  /* make the modal appear above other content */
  top: 55%;
  /* centering the modal vertically */
  left: 50%;
  /* centering the modal horizontally */
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  /* necessary adjustments for centering */
  z-index: 1000;
  /* high value to ensure modal is on top */
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 2rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1.5rem;
}

.jeeta-app .testSectionModal .modalTitle,
.jeeta-app * .testSectionModal .modalTitle,
.customModal .testSectionModal .modalTitle, .customModal * .testSectionModal .modalTitle {
  text-align: center;
  font-size: 1.11rem;
  font-weight: 600;
}

.jeeta-app .testSectionModal .subjectCreateSelectionDropdown,
.jeeta-app * .testSectionModal .subjectCreateSelectionDropdown,
.customModal .testSectionModal .subjectCreateSelectionDropdown, .customModal * .testSectionModal .subjectCreateSelectionDropdown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
}

.jeeta-app .testSectionModal .subjectCreateSelectionDropdown .title,
.jeeta-app * .testSectionModal .subjectCreateSelectionDropdown .title,
.customModal .testSectionModal .subjectCreateSelectionDropdown .title, .customModal * .testSectionModal .subjectCreateSelectionDropdown .title {
  font-size: .89rem;
  font-weight: 600;
}

.jeeta-app .testSectionModal .subjectCreateSelectionDropdown .selectionDropdown,
.jeeta-app .testSectionModal .subjectCreateSelectionDropdown .selectionDropdownMultiple,
.jeeta-app * .testSectionModal .subjectCreateSelectionDropdown .selectionDropdown,
.jeeta-app * .testSectionModal .subjectCreateSelectionDropdown .selectionDropdownMultiple,
.customModal .testSectionModal .subjectCreateSelectionDropdown .selectionDropdown,
.customModal .testSectionModal .subjectCreateSelectionDropdown .selectionDropdownMultiple, .customModal * .testSectionModal .subjectCreateSelectionDropdown .selectionDropdown,
.customModal * .testSectionModal .subjectCreateSelectionDropdown .selectionDropdownMultiple {
  width: 200px;
  font-size: .83rem;
}

.jeeta-app .testSectionModal .buttons,
.jeeta-app * .testSectionModal .buttons,
.customModal .testSectionModal .buttons, .customModal * .testSectionModal .buttons {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 1rem;
}

.jeeta-app .testSectionModal .btn,
.jeeta-app * .testSectionModal .btn,
.customModal .testSectionModal .btn, .customModal * .testSectionModal .btn {
  color: var(--whiteColor);
  font-weight: 600;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: .5rem;
}

.jeeta-app .testSectionModal .btn .icon,
.jeeta-app * .testSectionModal .btn .icon,
.customModal .testSectionModal .btn .icon, .customModal * .testSectionModal .btn .icon {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  font-size: 1rem;
}

.jeeta-app .testSectionModal #close,
.jeeta-app * .testSectionModal #close,
.customModal .testSectionModal #close, .customModal * .testSectionModal #close {
  position: absolute;
  top: .5rem;
  right: .5rem;
  color: var(--PrimaryColor);
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  font-size: 1.67rem;
}

@media all and (max-width: 720px) {
  .jeeta-app .testSection .testSectionContainer,
  .jeeta-app * .testSection .testSectionContainer,
  .customModal .testSection .testSectionContainer, .customModal * .testSection .testSectionContainer {
    padding: .5rem;
  }
}

@media all and (max-width: 550px) {
  .jeeta-app .testSectionNumbers,
  .jeeta-app * .testSectionNumbers,
  .customModal .testSectionNumbers, .customModal * .testSectionNumbers {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    gap: .5rem;
  }
  .jeeta-app .testSectionNumbers .numbersTitle,
  .jeeta-app * .testSectionNumbers .numbersTitle,
  .customModal .testSectionNumbers .numbersTitle, .customModal * .testSectionNumbers .numbersTitle {
    font-size: 1rem;
    font-weight: 600;
  }
  .jeeta-app .testSectionNumbers .numbersInput,
  .jeeta-app * .testSectionNumbers .numbersInput,
  .customModal .testSectionNumbers .numbersInput, .customModal * .testSectionNumbers .numbersInput {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: .5rem;
  }
  .jeeta-app .testSectionNumbers .numbersInput label,
  .jeeta-app * .testSectionNumbers .numbersInput label,
  .customModal .testSectionNumbers .numbersInput label, .customModal * .testSectionNumbers .numbersInput label {
    color: var(--PrimaryColor);
    font-size: .87rem;
    font-style: italic;
    font-weight: 600;
  }
  .jeeta-app .testSectionNumbers .numbersInput .testMarks,
  .jeeta-app * .testSectionNumbers .numbersInput .testMarks,
  .customModal .testSectionNumbers .numbersInput .testMarks, .customModal * .testSectionNumbers .numbersInput .testMarks {
    width: 50px;
    font-size: .77rem;
    padding: .2rem 0 .2rem .5rem;
    border: 1px solid var(--PrimaryColor);
  }
  .jeeta-app .testInfo,
  .jeeta-app * .testInfo,
  .customModal .testInfo, .customModal * .testInfo {
    padding: .5rem;
  }
  .jeeta-app .testInfo .instructions,
  .jeeta-app * .testInfo .instructions,
  .customModal .testInfo .instructions, .customModal * .testInfo .instructions {
    font-size: .9rem;
  }
  .jeeta-app .testInfo .testMarksList,
  .jeeta-app * .testInfo .testMarksList,
  .customModal .testInfo .testMarksList, .customModal * .testInfo .testMarksList {
    font-size: .9rem;
    margin-left: 0rem;
  }
  .jeeta-app .testInfo .testMarksList .numbersInput,
  .jeeta-app * .testInfo .testMarksList .numbersInput,
  .customModal .testInfo .testMarksList .numbersInput, .customModal * .testInfo .testMarksList .numbersInput {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .jeeta-app .testInfo .testMarksList .numbersInput label,
  .jeeta-app * .testInfo .testMarksList .numbersInput label,
  .customModal .testInfo .testMarksList .numbersInput label, .customModal * .testInfo .testMarksList .numbersInput label {
    width: auto;
    white-space: nowrap;
  }
  .jeeta-app .testQuestionAddBtns,
  .jeeta-app * .testQuestionAddBtns,
  .customModal .testQuestionAddBtns, .customModal * .testQuestionAddBtns {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: .5rem;
  }
  .jeeta-app .testQuestionAddBtns .btn,
  .jeeta-app * .testQuestionAddBtns .btn,
  .customModal .testQuestionAddBtns .btn, .customModal * .testQuestionAddBtns .btn {
    margin: 0;
  }
}
