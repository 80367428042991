@import url('https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@300;400;500;600;700;800&family=Poppins:wght@300;400;500;600;700&family=STIX+Two+Text:wght@400;500;600;700&display=swap');

:root {
    --PrimaryColor: hsl(214, 77%, 58%);
    --SecondaryColor: hsl(169, 79%, 44%);
    --gradientColor: linear-gradient(to right, hsl(169, 79%, 44%), hsl(214, 77%, 58%));
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(201, 33%, 16%);
    --textColor: hsl(240, 4%, 36%);
    --whiteColorDeam: hsl(0, 0%, 93%);
    --greyText: rgb(190, 190, 190);
    --inputColor: rgb(239, 239, 239);
    --bodyColor: rgb(240, 240, 246);
    --cardBG: rgb(225, 225, 235);
    --PrimaryBkg: rgba(65, 137, 230, 0.5);
    --SecondaryBkg: rgba(24, 201, 168, 0.5);
    --gradientBkg: linear-gradient(to right, rgba(24, 201, 168, 0.7), rgba(65, 137, 230, 0.7));
    --peach: #FF9F5B;
    --kelly-green: #94AB5B;
    --amber: #F2C762;
    --tiger-lilly: #F06C57;
    --midnight-blue: #191970;
    --greyBkg: rgba(90, 90, 90, .2);
    --rt-color-white: #fff;
    --rt-color-dark: #222;
    --rt-color-success: #8dc572;
    --rt-color-error: #be6464;
    --rt-color-warning: #f0ad4e;
    --rt-color-info: #337ab7;
    --rt-opacity: 0.9;
    --alice-blue: #f0f8ff;
    --easy: #6CC644;
    --medium: #FDB515;
    --hard: #D9534F;
    --finished: #003366;
    --finished-accent: #C0C0C0;
    --not-started: #4CAF50;
    --not-started-accent: #FFFF00;
    --started: #800000;
    --started-accent: #FFD700;
    --Physics: #003366;
    --physics: #003366;
    --Physics-accent: #C0C0C0;
    --Chemistry: #4CAF50;
    --chemistry: #4CAF50;
    --Chemistry-accent: #FFFF00;
    --Mathematics: #800000;
    --mathematics: #800000 --Mathematics-accent: #FFD700;
    --yellow: linear-gradient(180deg, #F8D49A -146.42%, #FAD79D -46.42%);
    --orange: #fca61f;
    --black: #242d49;
    --gray: #788097;
    --purple: linear-gradient(180deg, #BB67FF 0%, #C484F3 100%);
    --pink: #FF919D;
    --glass: rgba(255, 255, 255, 0.54);
    --boxShadow: 0px 19px 60px rgb(0 0 0 / 8%);
    --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
    --activeItem: #f799a354;
    --lemonBkg: rgba(253, 253, 150, 0.9);
    --pink-gradient: linear-gradient(180deg, #FF919D 0%, #FC929D 100%);
    --purple-gradient: linear-gradient(180deg, #BB67FF 0%, #C484F3 100%);
    --green-gradient: linear-gradient(rgb(138, 203, 155), rgb(178, 223, 185));
}

body {
    background: var(--bodyColor);
    margin: 0px;
}

html {
    scroll-behavior: smooth;
}


.jeeta-app,
.jeeta-app *,
.customModal,
.customModal * {
    box-sizing: border-box;
    font-family: 'poppins', serif;
    margin: 0;
    padding: 0;

    .katex * {
        font-family: 'KaTeX_Main', 'Frank Ruhl Libre', serif !important;
    }

    /*Colors and styling*/

    ::-webkit-scrollbar {
        width: 1rem;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 1rem;
        background: var(--PrimaryBkg);
        transition: all .5s ease-in-out;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: var(--PrimaryColor);
    }

    .scrollbar-hide {
        overflow: auto;
        /* Ensure the element is scrollable */
        scrollbar-width: none;
        /* Firefox: Hide the scrollbar */
        -ms-overflow-style: none;
        /* IE and Edge: Hide the scrollbar */
    }

    .scrollbar-hide::-webkit-scrollbar {
        display: none;
        /* Chrome, Safari, and Edge: Hide the scrollbar */
    }

    a {
        text-decoration: none;
        cursor: pointer;
    }

    li {
        list-style: none;
    }

    .section {
        /* position: relative; */
        padding: 1rem 0;
    }

    .container {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .icon {
        font-size: 2rem;
        cursor: pointer;
    }

    .grid {
        display: grid;
        align-items: center;
    }

    .btn {
        width: max-content;
        padding: .6rem 1.5rem;
        background: var(--gradientColor);
        border-radius: 3rem;
        border: none;
        outline: none;
        cursor: pointer;
        align-self: center;
    }

    .btn a {
        color: var(--whiteColor);
        font-weight: 500;
    }

    .btn:hover {
        background: var(--SecondaryColor);
    }

    .deletePopup {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: var(--whiteColorDeam);
        padding: 1rem;
        border-radius: .5rem;
        border: none;
        box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
        z-index: 1;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;

        .msg {
            font-size: 1rem;
            font-weight: 600;
            text-transform: none;
        }

        .buttons {
            flex-direction: row;
            justify-content: center;
            gap: 1rem;
        }
    }

    video {
        max-width: 100%;
        object-fit: contain;
        height: auto;
        display: block;
    }


    /* animations */
    // @keyframes zoomIn {
    //     from {
    //         transform: scale(0.5);
    //         opacity: 0;
    //     }

    //     to {
    //         transform: scale(1);
    //         opacity: 1;
    //     }
    // }

    // @keyframes on-load {
    //     0% {
    //         opacity: 0;
    //         transform: scale(.3);
    //     }

    //     70% {
    //         opacity: .7;
    //         transform: scale(1.1);
    //     }

    //     100% {
    //         opacity: 1;
    //         transform: scale(1);
    //     }
    // }

    // @keyframes fadeUp {
    //     from {
    //         opacity: 0;
    //         transform: translateY(20vh);
    //         /* Start from 20 pixels down */
    //     }

    //     to {
    //         opacity: 1;
    //         transform: translateY(0);
    //         /* Move to the natural position */
    //     }
    // }

    // @keyframes spin {
    //     to {
    //         transform: rotate(360deg);
    //     }
    // }

    // @keyframes spinReverse {
    //     from {
    //         transform: translate(-50%, -50%) rotate(-5deg);
    //     }

    //     to {
    //         transform: translate(-50%, -50%) rotate(-365deg);
    //     }
    // }
}

html,
body {
    .zoom-app {
        min-width: 0 !important;
    }
}

#zmmtg-root {
    display: none;
    min-width: 0 !important;

}

.zoom-app {
    iframe#webpack-dev-server-client-overlay {
        display: none !important
    }

}

/*Default Style to all the sections*/

/* Media Queries */

@media screen and (min-width: 2560px) {}

@media screen and (min-width: 1240px) {}


.customModal {
    display: flex;
    justify-content: center;
    z-index: 9999;
    position: fixed;
    inset: 0px;
    background-color: rgba(255, 255, 255, 0.75);
}