.jeeta-app .teacherTestView,
.jeeta-app * .teacherTestView,
.customModal .teacherTestView, .customModal * .teacherTestView {
  position: relative;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
}
