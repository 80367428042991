.jeeta-app .tagModal,
.jeeta-app * .tagModal,
.customModal .tagModal, .customModal * .tagModal {
  position: relative;
  /* make the modal appear above other content */
  top: 50%;
  /* centering the modal vertically */
  left: 50%;
  /* centering the modal horizontally */
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  /* necessary adjustments for centering */
  z-index: 1010;
  /* high value to ensure modal is on top */
  width: 500px;
  background-color: var(--whiteColorDeam);
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: .5rem;
  padding: 1.5rem 1rem;
  color: var(--textColor);
}

.jeeta-app .tagModal .tagCreate,
.jeeta-app * .tagModal .tagCreate,
.customModal .tagModal .tagCreate, .customModal * .tagModal .tagCreate {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
}

.jeeta-app .tagModal .tagCreate .tagTitle,
.jeeta-app * .tagModal .tagCreate .tagTitle,
.customModal .tagModal .tagCreate .tagTitle, .customModal * .tagModal .tagCreate .tagTitle {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 1rem 0;
  font-size: 25px;
}

.jeeta-app .tagModal .tagCreate .tagInfoInput,
.jeeta-app * .tagModal .tagCreate .tagInfoInput,
.customModal .tagModal .tagCreate .tagInfoInput, .customModal * .tagModal .tagCreate .tagInfoInput {
  width: 80%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 1rem;
}

.jeeta-app .tagModal .tagCreate .tagInfoInput .tagTypeSelectionDropdown,
.jeeta-app * .tagModal .tagCreate .tagInfoInput .tagTypeSelectionDropdown,
.customModal .tagModal .tagCreate .tagInfoInput .tagTypeSelectionDropdown, .customModal * .tagModal .tagCreate .tagInfoInput .tagTypeSelectionDropdown {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: .5rem;
}

.jeeta-app .tagModal .tagCreate .tagInfoInput .tagTypeSelectionDropdown .title,
.jeeta-app * .tagModal .tagCreate .tagInfoInput .tagTypeSelectionDropdown .title,
.customModal .tagModal .tagCreate .tagInfoInput .tagTypeSelectionDropdown .title, .customModal * .tagModal .tagCreate .tagInfoInput .tagTypeSelectionDropdown .title {
  font-size: 16px;
  font-weight: 600;
}

.jeeta-app .tagModal .tagCreate .tagInfoInput .tagTypeSelectionDropdown .title .icon,
.jeeta-app * .tagModal .tagCreate .tagInfoInput .tagTypeSelectionDropdown .title .icon,
.customModal .tagModal .tagCreate .tagInfoInput .tagTypeSelectionDropdown .title .icon, .customModal * .tagModal .tagCreate .tagInfoInput .tagTypeSelectionDropdown .title .icon {
  color: var(--textColor);
  font-size: 16px;
}

.jeeta-app .tagModal .tagCreate .tagInfoInput .tagTypeSelectionDropdown .text,
.jeeta-app * .tagModal .tagCreate .tagInfoInput .tagTypeSelectionDropdown .text,
.customModal .tagModal .tagCreate .tagInfoInput .tagTypeSelectionDropdown .text, .customModal * .tagModal .tagCreate .tagInfoInput .tagTypeSelectionDropdown .text {
  width: 100%;
  height: auto;
  font-size: 15px;
  padding: .2rem .5rem;
  color: var(--textColor);
  border: #cccccc solid 1px;
  border-radius: .3rem;
  line-height: 1.3;
}

.jeeta-app .tagModal .tagCreate .tagInfoInput .tagTypeSelectionDropdown .selectionDropdown,
.jeeta-app .tagModal .tagCreate .tagInfoInput .tagTypeSelectionDropdown .selectionDropdownMultiple,
.jeeta-app * .tagModal .tagCreate .tagInfoInput .tagTypeSelectionDropdown .selectionDropdown,
.jeeta-app * .tagModal .tagCreate .tagInfoInput .tagTypeSelectionDropdown .selectionDropdownMultiple,
.customModal .tagModal .tagCreate .tagInfoInput .tagTypeSelectionDropdown .selectionDropdown,
.customModal .tagModal .tagCreate .tagInfoInput .tagTypeSelectionDropdown .selectionDropdownMultiple, .customModal * .tagModal .tagCreate .tagInfoInput .tagTypeSelectionDropdown .selectionDropdown,
.customModal * .tagModal .tagCreate .tagInfoInput .tagTypeSelectionDropdown .selectionDropdownMultiple {
  width: 50%;
  font-size: 15px;
}

.jeeta-app .tagModal .tagCreate .tagInfoInput .tagTypeSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app .tagModal .tagCreate .tagInfoInput .tagTypeSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .tagModal .tagCreate .tagInfoInput .tagTypeSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .tagModal .tagCreate .tagInfoInput .tagTypeSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal .tagModal .tagCreate .tagInfoInput .tagTypeSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal .tagModal .tagCreate .tagInfoInput .tagTypeSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric, .customModal * .tagModal .tagCreate .tagInfoInput .tagTypeSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal * .tagModal .tagCreate .tagInfoInput .tagTypeSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric {
  max-width: 180px;
  overflow: hidden;
}

.jeeta-app .tagModal .tagCreate .tagInfoInput .textInput,
.jeeta-app .tagModal .tagCreate .tagInfoInput .checkBoxInput,
.jeeta-app * .tagModal .tagCreate .tagInfoInput .textInput,
.jeeta-app * .tagModal .tagCreate .tagInfoInput .checkBoxInput,
.customModal .tagModal .tagCreate .tagInfoInput .textInput,
.customModal .tagModal .tagCreate .tagInfoInput .checkBoxInput, .customModal * .tagModal .tagCreate .tagInfoInput .textInput,
.customModal * .tagModal .tagCreate .tagInfoInput .checkBoxInput {
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: .5rem;
}

.jeeta-app .tagModal .tagCreate .tagInfoInput .textInput .title,
.jeeta-app .tagModal .tagCreate .tagInfoInput .checkBoxInput .title,
.jeeta-app * .tagModal .tagCreate .tagInfoInput .textInput .title,
.jeeta-app * .tagModal .tagCreate .tagInfoInput .checkBoxInput .title,
.customModal .tagModal .tagCreate .tagInfoInput .textInput .title,
.customModal .tagModal .tagCreate .tagInfoInput .checkBoxInput .title, .customModal * .tagModal .tagCreate .tagInfoInput .textInput .title,
.customModal * .tagModal .tagCreate .tagInfoInput .checkBoxInput .title {
  width: 90px;
  font-size: 16px;
  font-weight: 600;
}

.jeeta-app .tagModal .tagCreate .tagInfoInput .textInput input,
.jeeta-app .tagModal .tagCreate .tagInfoInput .checkBoxInput input,
.jeeta-app * .tagModal .tagCreate .tagInfoInput .textInput input,
.jeeta-app * .tagModal .tagCreate .tagInfoInput .checkBoxInput input,
.customModal .tagModal .tagCreate .tagInfoInput .textInput input,
.customModal .tagModal .tagCreate .tagInfoInput .checkBoxInput input, .customModal * .tagModal .tagCreate .tagInfoInput .textInput input,
.customModal * .tagModal .tagCreate .tagInfoInput .checkBoxInput input {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  border: #cccccc solid 1px;
  border-radius: .3rem;
  padding: .3rem .7rem;
  font-size: 15px;
}

.jeeta-app .tagModal .tagCreate .checkBoxInput .checkBox,
.jeeta-app * .tagModal .tagCreate .checkBoxInput .checkBox,
.customModal .tagModal .tagCreate .checkBoxInput .checkBox, .customModal * .tagModal .tagCreate .checkBoxInput .checkBox {
  justify-self: flex-start;
}

.jeeta-app .tagModal .tagCreate .btn,
.jeeta-app * .tagModal .tagCreate .btn,
.customModal .tagModal .tagCreate .btn, .customModal * .tagModal .tagCreate .btn {
  margin-top: 1rem;
  color: var(--whiteColor);
  font-weight: 600;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: .5rem;
}

.jeeta-app .tagModal .tagCreate .btn .icon,
.jeeta-app * .tagModal .tagCreate .btn .icon,
.customModal .tagModal .tagCreate .btn .icon, .customModal * .tagModal .tagCreate .btn .icon {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  font-size: 18px;
}

.jeeta-app .tagModal .tagCreate #close,
.jeeta-app * .tagModal .tagCreate #close,
.customModal .tagModal .tagCreate #close, .customModal * .tagModal .tagCreate #close {
  position: absolute;
  top: 5px;
  right: 5px;
  color: var(--PrimaryColor);
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  font-size: 30px;
}

.jeeta-app .tagModal .tagCreate #close:hover,
.jeeta-app * .tagModal .tagCreate #close:hover,
.customModal .tagModal .tagCreate #close:hover, .customModal * .tagModal .tagCreate #close:hover {
  color: var(--tiger-lilly);
}
