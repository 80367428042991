.jeeta-app .analytics, .jeeta-app * .analytics {
  width: 100%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
  padding: 1rem;
  z-index: 200;
  /* padding: 0.5rem 3.5rem; */
  color: var(--black);
  -webkit-animation: fadeUp 1.2s ease-out forwards;
          animation: fadeUp 1.2s ease-out forwards;
  /* Example duration and timing */
}

.jeeta-app .analytics-title, .jeeta-app * .analytics-title {
  position: relative;
  width: 100%;
  padding: .5rem 1rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
  background: var(--gradientBkg);
  border-radius: 1rem;
  -webkit-box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
          box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
}

.jeeta-app .analytics-title .title, .jeeta-app * .analytics-title .title {
  color: var(--whiteColor);
  text-shadow: 0 2px 4px rgba(140, 140, 141, 0.3);
  text-align: center;
  gap: 1rem;
}

.jeeta-app .analysis-glass, .jeeta-app * .analysis-glass {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  display: -ms-grid;
  display: grid;
  width: 100%;
  padding: 1rem 0;
  background: var(--whiteColor);
  border-radius: 2rem;
  gap: 1rem;
  -ms-grid-columns: 11rem auto;
      grid-template-columns: 11rem auto;
  overflow: hidden;
  -webkit-box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
          box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
}

.jeeta-app .analysis-glass .analysis-main, .jeeta-app * .analysis-glass .analysis-main {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  padding: 1rem;
  gap: 1rem;
}

.jeeta-app .analysis-cardList, .jeeta-app * .analysis-cardList {
  position: relative;
  width: 95%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[3];
      grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(200px, auto);
  gap: 1rem;
  background: var(--cardBG);
  border-radius: 1rem;
  -webkit-box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
          box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
  padding: 1rem;
  overflow: hidden;
}

.jeeta-app .analysis-cardList .lecture-card, .jeeta-app * .analysis-cardList .lecture-card {
  width: 100%;
  min-height: 200px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: .5rem;
  font-size: .9rem;
}

.jeeta-app .analysis-cardList .lecture-card .bkg-cover, .jeeta-app * .analysis-cardList .lecture-card .bkg-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: .5rem;
  background-color: rgba(255, 255, 255, 0.3);
}

.jeeta-app .analysis-cardList .lecture-card .bkg-cover.CURRENT, .jeeta-app * .analysis-cardList .lecture-card .bkg-cover.CURRENT {
  z-index: 0;
}

.jeeta-app .analysis-cardList .lecture-card .bkg-cover.FINISHED, .jeeta-app * .analysis-cardList .lecture-card .bkg-cover.FINISHED {
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2;
}

.jeeta-app .analysis-cardList .lecture-card .classroom,
.jeeta-app .analysis-cardList .lecture-card .subject,
.jeeta-app .analysis-cardList .lecture-card .time, .jeeta-app * .analysis-cardList .lecture-card .classroom,
.jeeta-app * .analysis-cardList .lecture-card .subject,
.jeeta-app * .analysis-cardList .lecture-card .time {
  position: relative;
  text-align: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  padding: .1rem;
  color: var(--whiteColor);
  z-index: 1;
}

.jeeta-app .analysis-cardList .lecture-card .subject.Mathematics, .jeeta-app * .analysis-cardList .lecture-card .subject.Mathematics {
  background-color: var(--Mathematics);
}

.jeeta-app .analysis-cardList .lecture-card .subject.Physics, .jeeta-app * .analysis-cardList .lecture-card .subject.Physics {
  background-color: var(--Physics);
}

.jeeta-app .analysis-cardList .lecture-card .subject.Chemistry, .jeeta-app * .analysis-cardList .lecture-card .subject.Chemistry {
  background-color: var(--Chemistry);
}

.jeeta-app .analysis-cardList .lecture-card.Mathematics, .jeeta-app * .analysis-cardList .lecture-card.Mathematics {
  background-image: url("../../Assets/Mathematics.jpg");
}

.jeeta-app .analysis-cardList .lecture-card.Physics, .jeeta-app * .analysis-cardList .lecture-card.Physics {
  background-image: url("../../Assets/Physics.jpg");
}

.jeeta-app .analysis-cardList .lecture-card.Chemistry, .jeeta-app * .analysis-cardList .lecture-card.Chemistry {
  background-image: url("../../Assets/Chemistry.jpg");
}

.jeeta-app .pentagon-box, .jeeta-app * .pentagon-box {
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.jeeta-app .pentagon-box .radarChart, .jeeta-app * .pentagon-box .radarChart {
  max-width: 1000px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.jeeta-app .subtitle, .jeeta-app * .subtitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: .5rem;
}

.jeeta-app .subtitle h2, .jeeta-app * .subtitle h2 {
  color: var(--PrimaryColor);
  text-align: center;
}

.jeeta-app .subtitle span, .jeeta-app * .subtitle span {
  color: var(--SecondaryColor);
}

.jeeta-app .period-buttons, .jeeta-app * .period-buttons {
  margin: .5rem .5rem .5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  gap: .5rem;
  font-size: .8rem;
}

.jeeta-app .period-button, .jeeta-app * .period-button {
  padding: .3rem .5rem;
  background-color: var(--PrimaryBkg);
  color: var(--whiteColor);
  border-radius: .5rem;
}

.jeeta-app .period-button:hover, .jeeta-app * .period-button:hover {
  cursor: pointer;
  font-weight: 600;
  background-color: var(--PrimaryColor);
}

.jeeta-app .period-button.selected, .jeeta-app * .period-button.selected {
  font-weight: 600;
  background-color: var(--PrimaryColor);
}

@media all and (max-width: 1000px) {
  .jeeta-app .analysisPage .analysis-cardList, .jeeta-app * .analysisPage .analysis-cardList {
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
  }
}

@media all and (max-width: 860px) {
  .jeeta-app .analysis-glass, .jeeta-app * .analysis-glass {
    -ms-grid-columns: 3rem auto;
        grid-template-columns: 3rem auto;
  }
  .jeeta-app .analysisPage .dashboard-header, .jeeta-app * .analysisPage .dashboard-header {
    padding: 1rem 0;
  }
  .jeeta-app .analysisPage .dashboard-header .title, .jeeta-app * .analysisPage .dashboard-header .title {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  .jeeta-app .analysisPage .analysis-cardList, .jeeta-app * .analysisPage .analysis-cardList {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: .5rem;
  }
  .jeeta-app .analysisPage .analysis-cardList .lecture-card, .jeeta-app * .analysisPage .analysis-cardList .lecture-card {
    width: 100%;
    min-height: 180px;
    font-size: .85rem;
  }
}

@media all and (max-width: 720px) {
  .jeeta-app .analysisPage, .jeeta-app * .analysisPage {
    gap: .5rem;
    padding: .5rem;
  }
  .jeeta-app .analysisPage .LecturePIN, .jeeta-app * .analysisPage .LecturePIN {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  .jeeta-app .analytics-title, .jeeta-app * .analytics-title {
    padding: .5rem;
  }
  .jeeta-app .analytics-title .title, .jeeta-app * .analytics-title .title {
    font-size: .95rem;
  }
  .jeeta-app .analysis-cardList, .jeeta-app * .analysis-cardList {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: .5rem;
  }
  .jeeta-app .analysis-cardList .lecture-card, .jeeta-app * .analysis-cardList .lecture-card {
    width: 100%;
    min-height: 150px;
    font-size: .75rem;
  }
  .jeeta-app .analysis-glass, .jeeta-app * .analysis-glass {
    -ms-grid-columns: 3rem auto;
        grid-template-columns: 3rem auto;
  }
}

@media all and (max-width: 550px) {
  .jeeta-app .analysis-glass, .jeeta-app * .analysis-glass {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 0;
    padding: 0;
  }
}
