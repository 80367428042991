@import url("https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@300;400;500;600;700;800&family=Poppins:wght@300;400;500;600;700&family=STIX+Two+Text:wght@400;500;600;700&display=swap");
:root {
  --PrimaryColor: hsl(214, 77%, 58%);
  --SecondaryColor: hsl(169, 79%, 44%);
  --gradientColor: linear-gradient(to right, hsl(169, 79%, 44%), hsl(214, 77%, 58%));
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(201, 33%, 16%);
  --textColor: hsl(240, 4%, 36%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyText: rgb(190, 190, 190);
  --inputColor: rgb(239, 239, 239);
  --bodyColor: rgb(240, 240, 246);
  --cardBG: rgb(225, 225, 235);
  --PrimaryBkg: rgba(65, 137, 230, 0.5);
  --SecondaryBkg: rgba(24, 201, 168, 0.5);
  --gradientBkg: linear-gradient(to right, rgba(24, 201, 168, 0.7), rgba(65, 137, 230, 0.7));
  --peach: #FF9F5B;
  --kelly-green: #94AB5B;
  --amber: #F2C762;
  --tiger-lilly: #F06C57;
  --midnight-blue: #191970;
  --greyBkg: rgba(90, 90, 90, .2);
  --rt-color-white: #fff;
  --rt-color-dark: #222;
  --rt-color-success: #8dc572;
  --rt-color-error: #be6464;
  --rt-color-warning: #f0ad4e;
  --rt-color-info: #337ab7;
  --rt-opacity: 0.9;
  --alice-blue: #f0f8ff;
  --easy: #6CC644;
  --medium: #FDB515;
  --hard: #D9534F;
  --finished: #003366;
  --finished-accent: #C0C0C0;
  --not-started: #4CAF50;
  --not-started-accent: #FFFF00;
  --started: #800000;
  --started-accent: #FFD700;
  --Physics: #003366;
  --physics: #003366;
  --Physics-accent: #C0C0C0;
  --Chemistry: #4CAF50;
  --chemistry: #4CAF50;
  --Chemistry-accent: #FFFF00;
  --Mathematics: #800000;
  --mathematics: #800000 --Mathematics-accent: #FFD700;
  --yellow: linear-gradient(180deg, #F8D49A -146.42%, #FAD79D -46.42%);
  --orange: #fca61f;
  --black: #242d49;
  --gray: #788097;
  --purple: linear-gradient(180deg, #BB67FF 0%, #C484F3 100%);
  --pink: #FF919D;
  --glass: rgba(255, 255, 255, 0.54);
  --boxShadow: 0px 19px 60px rgb(0 0 0 / 8%);
  --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
  --activeItem: #f799a354;
  --lemonBkg: rgba(253, 253, 150, 0.9);
  --pink-gradient: linear-gradient(180deg, #FF919D 0%, #FC929D 100%);
  --purple-gradient: linear-gradient(180deg, #BB67FF 0%, #C484F3 100%);
  --green-gradient: linear-gradient(rgb(138, 203, 155), rgb(178, 223, 185));
}

body {
  background: var(--bodyColor);
  margin: 0px;
}

html {
  scroll-behavior: smooth;
}

.jeeta-app,
.jeeta-app *,
.customModal,
.customModal * {
  box-sizing: border-box;
  font-family: "poppins", serif;
  margin: 0;
  padding: 0;
  /*Colors and styling*/
  /* animations */
}
.jeeta-app .katex *,
.jeeta-app * .katex *,
.customModal .katex *,
.customModal * .katex * {
  font-family: "KaTeX_Main", "Frank Ruhl Libre", serif !important;
}
.jeeta-app ::-webkit-scrollbar,
.jeeta-app * ::-webkit-scrollbar,
.customModal ::-webkit-scrollbar,
.customModal * ::-webkit-scrollbar {
  width: 1rem;
}
.jeeta-app ::-webkit-scrollbar-thumb,
.jeeta-app * ::-webkit-scrollbar-thumb,
.customModal ::-webkit-scrollbar-thumb,
.customModal * ::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background: var(--PrimaryBkg);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.jeeta-app ::-webkit-scrollbar-thumb:hover,
.jeeta-app * ::-webkit-scrollbar-thumb:hover,
.customModal ::-webkit-scrollbar-thumb:hover,
.customModal * ::-webkit-scrollbar-thumb:hover {
  background: var(--PrimaryColor);
}
.jeeta-app .scrollbar-hide,
.jeeta-app * .scrollbar-hide,
.customModal .scrollbar-hide,
.customModal * .scrollbar-hide {
  overflow: auto;
  /* Ensure the element is scrollable */
  scrollbar-width: none;
  /* Firefox: Hide the scrollbar */
  -ms-overflow-style: none;
  /* IE and Edge: Hide the scrollbar */
}
.jeeta-app .scrollbar-hide::-webkit-scrollbar,
.jeeta-app * .scrollbar-hide::-webkit-scrollbar,
.customModal .scrollbar-hide::-webkit-scrollbar,
.customModal * .scrollbar-hide::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, and Edge: Hide the scrollbar */
}
.jeeta-app a,
.jeeta-app * a,
.customModal a,
.customModal * a {
  text-decoration: none;
  cursor: pointer;
}
.jeeta-app li,
.jeeta-app * li,
.customModal li,
.customModal * li {
  list-style: none;
}
.jeeta-app .section,
.jeeta-app * .section,
.customModal .section,
.customModal * .section {
  /* position: relative; */
  padding: 1rem 0;
}
.jeeta-app .container,
.jeeta-app * .container,
.customModal .container,
.customModal * .container {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.jeeta-app .icon,
.jeeta-app * .icon,
.customModal .icon,
.customModal * .icon {
  font-size: 2rem;
  cursor: pointer;
}
.jeeta-app .grid,
.jeeta-app * .grid,
.customModal .grid,
.customModal * .grid {
  display: grid;
  align-items: center;
}
.jeeta-app .btn,
.jeeta-app * .btn,
.customModal .btn,
.customModal * .btn {
  width: -moz-max-content;
  width: max-content;
  padding: 0.6rem 1.5rem;
  background: var(--gradientColor);
  border-radius: 3rem;
  border: none;
  outline: none;
  cursor: pointer;
  align-self: center;
}
.jeeta-app .btn a,
.jeeta-app * .btn a,
.customModal .btn a,
.customModal * .btn a {
  color: var(--whiteColor);
  font-weight: 500;
}
.jeeta-app .btn:hover,
.jeeta-app * .btn:hover,
.customModal .btn:hover,
.customModal * .btn:hover {
  background: var(--SecondaryColor);
}
.jeeta-app .deletePopup,
.jeeta-app * .deletePopup,
.customModal .deletePopup,
.customModal * .deletePopup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--whiteColorDeam);
  padding: 1rem;
  border-radius: 0.5rem;
  border: none;
  box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
  z-index: 1;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}
.jeeta-app .deletePopup .msg,
.jeeta-app * .deletePopup .msg,
.customModal .deletePopup .msg,
.customModal * .deletePopup .msg {
  font-size: 1rem;
  font-weight: 600;
  text-transform: none;
}
.jeeta-app .deletePopup .buttons,
.jeeta-app * .deletePopup .buttons,
.customModal .deletePopup .buttons,
.customModal * .deletePopup .buttons {
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
}
.jeeta-app video,
.jeeta-app * video,
.customModal video,
.customModal * video {
  max-width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  height: auto;
  display: block;
}

html .zoom-app,
body .zoom-app {
  min-width: 0 !important;
}

#zmmtg-root {
  display: none;
  min-width: 0 !important;
}

.zoom-app iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

/*Default Style to all the sections*/
/* Media Queries */
.customModal {
  display: flex;
  justify-content: center;
  z-index: 9999;
  position: fixed;
  inset: 0px;
  background-color: rgba(255, 255, 255, 0.75);
}/*# sourceMappingURL=app.css.map */