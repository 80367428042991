.jeeta-app,
.jeeta-app * {
    .forward-timer {
        position: relative;
        // width: 100%;
        padding: .2rem .4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--SecondaryBkg);
        color: var(--whiteColor);
        border-radius: .5rem;
        font-size: .94rem;
        font-weight: 600;
        gap: .2rem;

        &:hover {
            cursor: pointer;
            background-color: var(--SecondaryColor);
        }

        .icon {
            font-size: 1.1rem;
        }
    }

    .forward-timer.visible {
        background-color: var(--SecondaryColor);

        &:hover {
            background-color: var(--SecondaryBkg);
        }
    }


    .timer-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .timer-container.on-top {
        background: var(--whiteColor);
        border: none;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        position: fixed;
        top: 4rem;
        right: .5rem;
        z-index: 10;
    }

    .timer {
        position: relative;
        width: 8rem;
        height: 8rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .progress-ring {
        width: 100%;
        height: 100%;
        /* Sets the SVG size to 10rem by 10rem */
        display: flex;
        /* Ensure it's flex to align child svg */
        align-items: center;
        /* Center content vertically */
        justify-content: center;
        /* Center content horizontally */
    }

    .progress-ring__circle {
        stroke: var(--easy);
        stroke-width: 1rem;
        fill: transparent;
        transition: stroke-dashoffset 0.35s;
        transform: rotate(-90deg);
        transform-origin: 50% 50%;
    }

    .progress-ring__circle.half {
        stroke: var(--medium);
    }

    .progress-ring__circle.little {
        stroke: var(--hard);
    }


    @media all and (max-width: 550px) {
        .timer-container {
            transform: scale(.9);
        }
    }
}