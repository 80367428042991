.jeeta-app .testQuestionView,
.jeeta-app * .testQuestionView,
.customModal .testQuestionView, .customModal * .testQuestionView {
  position: relative;
  width: 100%;
  min-height: 30vh;
  padding: 1rem;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
  border-radius: .5rem;
  background-color: rgba(24, 201, 168, 0.1);
}

.jeeta-app .testQuestionView .btn,
.jeeta-app * .testQuestionView .btn,
.customModal .testQuestionView .btn, .customModal * .testQuestionView .btn {
  margin: .5rem 0;
  color: var(--whiteColor);
  font-weight: 600;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: .5rem;
}

.jeeta-app .testQuestionView .btn .icon,
.jeeta-app * .testQuestionView .btn .icon,
.customModal .testQuestionView .btn .icon, .customModal * .testQuestionView .btn .icon {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  font-size: 18px;
}

.jeeta-app .testQuestionView .questionNumberSlide,
.jeeta-app * .testQuestionView .questionNumberSlide,
.customModal .testQuestionView .questionNumberSlide, .customModal * .testQuestionView .questionNumberSlide {
  width: 80%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.jeeta-app .testQuestionView .questionNumber,
.jeeta-app * .testQuestionView .questionNumber,
.customModal .testQuestionView .questionNumber, .customModal * .testQuestionView .questionNumber {
  padding: .2rem .5rem;
  border-radius: .3rem;
  background-color: var(--midnight-blue);
  color: var(--whiteColor);
  font-size: 1.1rem;
  font-weight: 700;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  text-align: start;
}

.jeeta-app .testQuestionView .questionButtons,
.jeeta-app * .testQuestionView .questionButtons,
.customModal .testQuestionView .questionButtons, .customModal * .testQuestionView .questionButtons {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 1rem .5rem;
  gap: .5rem;
}

.jeeta-app .testQuestionView .questionButtons .questionIdxBtn,
.jeeta-app .testQuestionView .questionButtons .paragraphIdxBtn,
.jeeta-app * .testQuestionView .questionButtons .questionIdxBtn,
.jeeta-app * .testQuestionView .questionButtons .paragraphIdxBtn,
.customModal .testQuestionView .questionButtons .questionIdxBtn,
.customModal .testQuestionView .questionButtons .paragraphIdxBtn, .customModal * .testQuestionView .questionButtons .questionIdxBtn,
.customModal * .testQuestionView .questionButtons .paragraphIdxBtn {
  -ms-grid-columns: (1fr)[5];
      grid-template-columns: repeat(5, 1fr);
  justify-items: start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: start;
  gap: .5rem;
}

.jeeta-app .testQuestionView .questionButtons .questionIdxBtn .idxBtn,
.jeeta-app .testQuestionView .questionButtons .paragraphIdxBtn .idxBtn,
.jeeta-app * .testQuestionView .questionButtons .questionIdxBtn .idxBtn,
.jeeta-app * .testQuestionView .questionButtons .paragraphIdxBtn .idxBtn,
.customModal .testQuestionView .questionButtons .questionIdxBtn .idxBtn,
.customModal .testQuestionView .questionButtons .paragraphIdxBtn .idxBtn, .customModal * .testQuestionView .questionButtons .questionIdxBtn .idxBtn,
.customModal * .testQuestionView .questionButtons .paragraphIdxBtn .idxBtn {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 2rem;
  height: 2rem;
  background-color: var(--PrimaryColor);
  color: var(--whiteColor);
  padding: .1rem;
  border: none;
  border-radius: .5rem;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}

.jeeta-app .testQuestionView .questionButtons .questionIdxBtn .idxBtn.correct-response,
.jeeta-app .testQuestionView .questionButtons .paragraphIdxBtn .idxBtn.correct-response,
.jeeta-app * .testQuestionView .questionButtons .questionIdxBtn .idxBtn.correct-response,
.jeeta-app * .testQuestionView .questionButtons .paragraphIdxBtn .idxBtn.correct-response,
.customModal .testQuestionView .questionButtons .questionIdxBtn .idxBtn.correct-response,
.customModal .testQuestionView .questionButtons .paragraphIdxBtn .idxBtn.correct-response, .customModal * .testQuestionView .questionButtons .questionIdxBtn .idxBtn.correct-response,
.customModal * .testQuestionView .questionButtons .paragraphIdxBtn .idxBtn.correct-response {
  background-color: green;
}

.jeeta-app .testQuestionView .questionButtons .questionIdxBtn .idxBtn.incorrect-response,
.jeeta-app .testQuestionView .questionButtons .paragraphIdxBtn .idxBtn.incorrect-response,
.jeeta-app * .testQuestionView .questionButtons .questionIdxBtn .idxBtn.incorrect-response,
.jeeta-app * .testQuestionView .questionButtons .paragraphIdxBtn .idxBtn.incorrect-response,
.customModal .testQuestionView .questionButtons .questionIdxBtn .idxBtn.incorrect-response,
.customModal .testQuestionView .questionButtons .paragraphIdxBtn .idxBtn.incorrect-response, .customModal * .testQuestionView .questionButtons .questionIdxBtn .idxBtn.incorrect-response,
.customModal * .testQuestionView .questionButtons .paragraphIdxBtn .idxBtn.incorrect-response {
  background-color: red;
}

.jeeta-app .testQuestionView .questionButtons .questionIdxBtn .idxBtn.saved-response,
.jeeta-app .testQuestionView .questionButtons .paragraphIdxBtn .idxBtn.saved-response,
.jeeta-app * .testQuestionView .questionButtons .questionIdxBtn .idxBtn.saved-response,
.jeeta-app * .testQuestionView .questionButtons .paragraphIdxBtn .idxBtn.saved-response,
.customModal .testQuestionView .questionButtons .questionIdxBtn .idxBtn.saved-response,
.customModal .testQuestionView .questionButtons .paragraphIdxBtn .idxBtn.saved-response, .customModal * .testQuestionView .questionButtons .questionIdxBtn .idxBtn.saved-response,
.customModal * .testQuestionView .questionButtons .paragraphIdxBtn .idxBtn.saved-response {
  background-color: orange;
}

.jeeta-app .testQuestionView .questionButtons .questionIdxBtn #clicked-btn,
.jeeta-app .testQuestionView .questionButtons .paragraphIdxBtn #clicked-btn,
.jeeta-app * .testQuestionView .questionButtons .questionIdxBtn #clicked-btn,
.jeeta-app * .testQuestionView .questionButtons .paragraphIdxBtn #clicked-btn,
.customModal .testQuestionView .questionButtons .questionIdxBtn #clicked-btn,
.customModal .testQuestionView .questionButtons .paragraphIdxBtn #clicked-btn, .customModal * .testQuestionView .questionButtons .questionIdxBtn #clicked-btn,
.customModal * .testQuestionView .questionButtons .paragraphIdxBtn #clicked-btn {
  background-color: var(--midnight-blue);
}

.jeeta-app .testQuestionView .questionButtons .questionIdxBtn #clicked-btn.correct-response,
.jeeta-app .testQuestionView .questionButtons .paragraphIdxBtn #clicked-btn.correct-response,
.jeeta-app * .testQuestionView .questionButtons .questionIdxBtn #clicked-btn.correct-response,
.jeeta-app * .testQuestionView .questionButtons .paragraphIdxBtn #clicked-btn.correct-response,
.customModal .testQuestionView .questionButtons .questionIdxBtn #clicked-btn.correct-response,
.customModal .testQuestionView .questionButtons .paragraphIdxBtn #clicked-btn.correct-response, .customModal * .testQuestionView .questionButtons .questionIdxBtn #clicked-btn.correct-response,
.customModal * .testQuestionView .questionButtons .paragraphIdxBtn #clicked-btn.correct-response {
  background-color: #006400;
}

.jeeta-app .testQuestionView .questionButtons .questionIdxBtn #clicked-btn.incorrect-response,
.jeeta-app .testQuestionView .questionButtons .paragraphIdxBtn #clicked-btn.incorrect-response,
.jeeta-app * .testQuestionView .questionButtons .questionIdxBtn #clicked-btn.incorrect-response,
.jeeta-app * .testQuestionView .questionButtons .paragraphIdxBtn #clicked-btn.incorrect-response,
.customModal .testQuestionView .questionButtons .questionIdxBtn #clicked-btn.incorrect-response,
.customModal .testQuestionView .questionButtons .paragraphIdxBtn #clicked-btn.incorrect-response, .customModal * .testQuestionView .questionButtons .questionIdxBtn #clicked-btn.incorrect-response,
.customModal * .testQuestionView .questionButtons .paragraphIdxBtn #clicked-btn.incorrect-response {
  background-color: #8B0000;
}

.jeeta-app .testQuestionView .questionButtons .questionIdxBtn #clicked-btn.saved-response,
.jeeta-app .testQuestionView .questionButtons .paragraphIdxBtn #clicked-btn.saved-response,
.jeeta-app * .testQuestionView .questionButtons .questionIdxBtn #clicked-btn.saved-response,
.jeeta-app * .testQuestionView .questionButtons .paragraphIdxBtn #clicked-btn.saved-response,
.customModal .testQuestionView .questionButtons .questionIdxBtn #clicked-btn.saved-response,
.customModal .testQuestionView .questionButtons .paragraphIdxBtn #clicked-btn.saved-response, .customModal * .testQuestionView .questionButtons .questionIdxBtn #clicked-btn.saved-response,
.customModal * .testQuestionView .questionButtons .paragraphIdxBtn #clicked-btn.saved-response {
  background-color: #FF8C00;
}

.jeeta-app .testQuestionView .questionButtons .delete-bin,
.jeeta-app * .testQuestionView .questionButtons .delete-bin,
.customModal .testQuestionView .questionButtons .delete-bin, .customModal * .testQuestionView .questionButtons .delete-bin {
  width: 100%;
  padding: 1rem .5rem;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: .5rem;
  background-color: var(--tiger-lilly);
  color: var(--whiteColor);
  border-radius: .5rem;
  font-weight: 600;
}

.jeeta-app .testQuestionView .questionButtons .delete-bin .icon,
.jeeta-app * .testQuestionView .questionButtons .delete-bin .icon,
.customModal .testQuestionView .questionButtons .delete-bin .icon, .customModal * .testQuestionView .questionButtons .delete-bin .icon {
  font-size: 2rem;
}

.jeeta-app .btns,
.jeeta-app * .btns,
.customModal .btns, .customModal * .btns {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
}

@media all and (max-width: 720px) {
  .jeeta-app .testQuestionView,
  .jeeta-app * .testQuestionView,
  .customModal .testQuestionView, .customModal * .testQuestionView {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    padding: .5rem .5rem 1rem .5rem;
  }
  .jeeta-app .testQuestionView .questionNumberSlide,
  .jeeta-app * .testQuestionView .questionNumberSlide,
  .customModal .testQuestionView .questionNumberSlide, .customModal * .testQuestionView .questionNumberSlide {
    width: 100%;
  }
  .jeeta-app .testQuestionView .questionNumber,
  .jeeta-app * .testQuestionView .questionNumber,
  .customModal .testQuestionView .questionNumber, .customModal * .testQuestionView .questionNumber {
    font-size: .95rem;
  }
  .jeeta-app .testQuestionView .questionButtons,
  .jeeta-app * .testQuestionView .questionButtons,
  .customModal .testQuestionView .questionButtons, .customModal * .testQuestionView .questionButtons {
    width: 100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: .1rem;
  }
  .jeeta-app .testQuestionView .questionButtons .questionIdxBtn,
  .jeeta-app .testQuestionView .questionButtons .paragraphIdxBtn,
  .jeeta-app * .testQuestionView .questionButtons .questionIdxBtn,
  .jeeta-app * .testQuestionView .questionButtons .paragraphIdxBtn,
  .customModal .testQuestionView .questionButtons .questionIdxBtn,
  .customModal .testQuestionView .questionButtons .paragraphIdxBtn, .customModal * .testQuestionView .questionButtons .questionIdxBtn,
  .customModal * .testQuestionView .questionButtons .paragraphIdxBtn {
    width: 100%;
    padding: 0;
    -ms-grid-columns: (1fr)[15];
        grid-template-columns: repeat(15, 1fr);
    gap: .1rem;
  }
  .jeeta-app .testQuestionView .questionButtons .questionIdxBtn .idxBtn,
  .jeeta-app .testQuestionView .questionButtons .paragraphIdxBtn .idxBtn,
  .jeeta-app * .testQuestionView .questionButtons .questionIdxBtn .idxBtn,
  .jeeta-app * .testQuestionView .questionButtons .paragraphIdxBtn .idxBtn,
  .customModal .testQuestionView .questionButtons .questionIdxBtn .idxBtn,
  .customModal .testQuestionView .questionButtons .paragraphIdxBtn .idxBtn, .customModal * .testQuestionView .questionButtons .questionIdxBtn .idxBtn,
  .customModal * .testQuestionView .questionButtons .paragraphIdxBtn .idxBtn {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  .jeeta-app .testQuestionView .questionButtons .delete-bin,
  .jeeta-app * .testQuestionView .questionButtons .delete-bin,
  .customModal .testQuestionView .questionButtons .delete-bin, .customModal * .testQuestionView .questionButtons .delete-bin {
    padding: .5rem;
  }
  .jeeta-app .testQuestionView .questionButtons .delete-bin .icon,
  .jeeta-app * .testQuestionView .questionButtons .delete-bin .icon,
  .customModal .testQuestionView .questionButtons .delete-bin .icon, .customModal * .testQuestionView .questionButtons .delete-bin .icon {
    font-size: 1.5rem;
  }
}

@media all and (max-width: 620px) {
  .jeeta-app .testQuestionView .questionButtons .questionIdxBtn,
  .jeeta-app .testQuestionView .questionButtons .paragraphIdxBtn,
  .jeeta-app * .testQuestionView .questionButtons .questionIdxBtn,
  .jeeta-app * .testQuestionView .questionButtons .paragraphIdxBtn,
  .customModal .testQuestionView .questionButtons .questionIdxBtn,
  .customModal .testQuestionView .questionButtons .paragraphIdxBtn, .customModal * .testQuestionView .questionButtons .questionIdxBtn,
  .customModal * .testQuestionView .questionButtons .paragraphIdxBtn {
    -ms-grid-columns: (1fr)[13];
        grid-template-columns: repeat(13, 1fr);
  }
}

@media all and (max-width: 550px) {
  .jeeta-app .testQuestionView,
  .jeeta-app * .testQuestionView,
  .customModal .testQuestionView, .customModal * .testQuestionView {
    gap: .5rem;
  }
  .jeeta-app .testQuestionView .questionNumber,
  .jeeta-app * .testQuestionView .questionNumber,
  .customModal .testQuestionView .questionNumber, .customModal * .testQuestionView .questionNumber {
    font-size: .9rem;
  }
  .jeeta-app .testQuestionView .paragraph-slide,
  .jeeta-app .testQuestionView .question-slide,
  .jeeta-app * .testQuestionView .paragraph-slide,
  .jeeta-app * .testQuestionView .question-slide,
  .customModal .testQuestionView .paragraph-slide,
  .customModal .testQuestionView .question-slide, .customModal * .testQuestionView .paragraph-slide,
  .customModal * .testQuestionView .question-slide {
    font-size: 1rem;
  }
  .jeeta-app .testQuestionView .questionButtons .questionIdxBtn,
  .jeeta-app .testQuestionView .questionButtons .paragraphIdxBtn,
  .jeeta-app * .testQuestionView .questionButtons .questionIdxBtn,
  .jeeta-app * .testQuestionView .questionButtons .paragraphIdxBtn,
  .customModal .testQuestionView .questionButtons .questionIdxBtn,
  .customModal .testQuestionView .questionButtons .paragraphIdxBtn, .customModal * .testQuestionView .questionButtons .questionIdxBtn,
  .customModal * .testQuestionView .questionButtons .paragraphIdxBtn {
    width: 100%;
    padding: 0;
    -ms-grid-columns: (1fr)[10];
        grid-template-columns: repeat(10, 1fr);
    gap: .05rem;
  }
  .jeeta-app .testQuestionView .questionButtons .questionIdxBtn .idxBtn,
  .jeeta-app .testQuestionView .questionButtons .paragraphIdxBtn .idxBtn,
  .jeeta-app * .testQuestionView .questionButtons .questionIdxBtn .idxBtn,
  .jeeta-app * .testQuestionView .questionButtons .paragraphIdxBtn .idxBtn,
  .customModal .testQuestionView .questionButtons .questionIdxBtn .idxBtn,
  .customModal .testQuestionView .questionButtons .paragraphIdxBtn .idxBtn, .customModal * .testQuestionView .questionButtons .questionIdxBtn .idxBtn,
  .customModal * .testQuestionView .questionButtons .paragraphIdxBtn .idxBtn {
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
  }
  .jeeta-app .testQuestionView .btn,
  .jeeta-app * .testQuestionView .btn,
  .customModal .testQuestionView .btn, .customModal * .testQuestionView .btn {
    margin: 0.1rem 0;
  }
  .jeeta-app .btns,
  .jeeta-app * .btns,
  .customModal .btns, .customModal * .btns {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: .2rem;
  }
}

@media all and (max-width: 425px) {
  .jeeta-app .testQuestionView .questionButtons .questionIdxBtn,
  .jeeta-app .testQuestionView .questionButtons .paragraphIdxBtn,
  .jeeta-app * .testQuestionView .questionButtons .questionIdxBtn,
  .jeeta-app * .testQuestionView .questionButtons .paragraphIdxBtn,
  .customModal .testQuestionView .questionButtons .questionIdxBtn,
  .customModal .testQuestionView .questionButtons .paragraphIdxBtn, .customModal * .testQuestionView .questionButtons .questionIdxBtn,
  .customModal * .testQuestionView .questionButtons .paragraphIdxBtn {
    -ms-grid-columns: (1fr)[7];
        grid-template-columns: repeat(7, 1fr);
  }
}
