.jeeta-app .slotTestEdit,
.jeeta-app .slotTestView,
.jeeta-app * .slotTestEdit,
.jeeta-app * .slotTestView,
.customModal .slotTestEdit,
.customModal .slotTestView,
.customModal * .slotTestEdit,
.customModal * .slotTestView {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
}

.jeeta-app .slotTestEdit .quizSelect,
.jeeta-app .slotTestView .quizSelect,
.jeeta-app * .slotTestEdit .quizSelect,
.jeeta-app * .slotTestView .quizSelect,
.customModal .slotTestEdit .quizSelect,
.customModal .slotTestView .quizSelect,
.customModal * .slotTestEdit .quizSelect,
.customModal * .slotTestView .quizSelect {
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: .5rem;
}

.jeeta-app .slotTestEdit .quizSelect .createNewQuiz,
.jeeta-app .slotTestView .quizSelect .createNewQuiz,
.jeeta-app * .slotTestEdit .quizSelect .createNewQuiz,
.jeeta-app * .slotTestView .quizSelect .createNewQuiz,
.customModal .slotTestEdit .quizSelect .createNewQuiz,
.customModal .slotTestView .quizSelect .createNewQuiz,
.customModal * .slotTestEdit .quizSelect .createNewQuiz,
.customModal * .slotTestView .quizSelect .createNewQuiz {
  color: var(--PrimaryColor);
  font-size: 1.8rem;
}

.jeeta-app .slotTestEdit .quizSelect .createNewQuiz:hover,
.jeeta-app .slotTestView .quizSelect .createNewQuiz:hover,
.jeeta-app * .slotTestEdit .quizSelect .createNewQuiz:hover,
.jeeta-app * .slotTestView .quizSelect .createNewQuiz:hover,
.customModal .slotTestEdit .quizSelect .createNewQuiz:hover,
.customModal .slotTestView .quizSelect .createNewQuiz:hover,
.customModal * .slotTestEdit .quizSelect .createNewQuiz:hover,
.customModal * .slotTestView .quizSelect .createNewQuiz:hover {
  cursor: pointer;
  color: var(--SecondaryColor);
}

.jeeta-app .slotTestEdit .quizSelect .quizSelectionDropdown,
.jeeta-app .slotTestView .quizSelect .quizSelectionDropdown,
.jeeta-app * .slotTestEdit .quizSelect .quizSelectionDropdown,
.jeeta-app * .slotTestView .quizSelect .quizSelectionDropdown,
.customModal .slotTestEdit .quizSelect .quizSelectionDropdown,
.customModal .slotTestView .quizSelect .quizSelectionDropdown,
.customModal * .slotTestEdit .quizSelect .quizSelectionDropdown,
.customModal * .slotTestView .quizSelect .quizSelectionDropdown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.jeeta-app .slotTestEdit .quizSelect .quizSelectionDropdown .selectionDropdown,
.jeeta-app .slotTestView .quizSelect .quizSelectionDropdown .selectionDropdown,
.jeeta-app * .slotTestEdit .quizSelect .quizSelectionDropdown .selectionDropdown,
.jeeta-app * .slotTestView .quizSelect .quizSelectionDropdown .selectionDropdown,
.customModal .slotTestEdit .quizSelect .quizSelectionDropdown .selectionDropdown,
.customModal .slotTestView .quizSelect .quizSelectionDropdown .selectionDropdown,
.customModal * .slotTestEdit .quizSelect .quizSelectionDropdown .selectionDropdown,
.customModal * .slotTestView .quizSelect .quizSelectionDropdown .selectionDropdown {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.jeeta-app .slotTestEdit .quizDiv .testTimer,
.jeeta-app .slotTestView .quizDiv .testTimer,
.jeeta-app * .slotTestEdit .quizDiv .testTimer,
.jeeta-app * .slotTestView .quizDiv .testTimer,
.customModal .slotTestEdit .quizDiv .testTimer,
.customModal .slotTestView .quizDiv .testTimer,
.customModal * .slotTestEdit .quizDiv .testTimer,
.customModal * .slotTestView .quizDiv .testTimer {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
}

.jeeta-app .slotTestEdit .quizDiv .testReport,
.jeeta-app .slotTestView .quizDiv .testReport,
.jeeta-app * .slotTestEdit .quizDiv .testReport,
.jeeta-app * .slotTestView .quizDiv .testReport,
.customModal .slotTestEdit .quizDiv .testReport,
.customModal .slotTestView .quizDiv .testReport,
.customModal * .slotTestEdit .quizDiv .testReport,
.customModal * .slotTestView .quizDiv .testReport {
  position: absolute;
  top: 0;
  right: 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.jeeta-app .slotTestEdit .quizDiv .testReport .report-btn,
.jeeta-app .slotTestView .quizDiv .testReport .report-btn,
.jeeta-app * .slotTestEdit .quizDiv .testReport .report-btn,
.jeeta-app * .slotTestView .quizDiv .testReport .report-btn,
.customModal .slotTestEdit .quizDiv .testReport .report-btn,
.customModal .slotTestView .quizDiv .testReport .report-btn,
.customModal * .slotTestEdit .quizDiv .testReport .report-btn,
.customModal * .slotTestView .quizDiv .testReport .report-btn {
  background-color: var(--finished);
  color: var(--whiteColor);
  border: none;
  border-radius: .3rem;
  font-size: .83rem;
  padding: .3rem .6rem;
  font-weight: 600;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: .5rem;
}

.jeeta-app .slotTestEdit .quizDiv .testReport .report-btn .icon,
.jeeta-app .slotTestView .quizDiv .testReport .report-btn .icon,
.jeeta-app * .slotTestEdit .quizDiv .testReport .report-btn .icon,
.jeeta-app * .slotTestView .quizDiv .testReport .report-btn .icon,
.customModal .slotTestEdit .quizDiv .testReport .report-btn .icon,
.customModal .slotTestView .quizDiv .testReport .report-btn .icon,
.customModal * .slotTestEdit .quizDiv .testReport .report-btn .icon,
.customModal * .slotTestView .quizDiv .testReport .report-btn .icon {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  font-size: 1rem;
}

.jeeta-app .slotTestEdit .quizDiv .testReport .report-btn:hover,
.jeeta-app .slotTestView .quizDiv .testReport .report-btn:hover,
.jeeta-app * .slotTestEdit .quizDiv .testReport .report-btn:hover,
.jeeta-app * .slotTestView .quizDiv .testReport .report-btn:hover,
.customModal .slotTestEdit .quizDiv .testReport .report-btn:hover,
.customModal .slotTestView .quizDiv .testReport .report-btn:hover,
.customModal * .slotTestEdit .quizDiv .testReport .report-btn:hover,
.customModal * .slotTestView .quizDiv .testReport .report-btn:hover {
  cursor: pointer;
  background-color: var(--SecondaryColor);
}

.jeeta-app .slotTestEdit .quizDiv .testStatus,
.jeeta-app .slotTestView .quizDiv .testStatus,
.jeeta-app * .slotTestEdit .quizDiv .testStatus,
.jeeta-app * .slotTestView .quizDiv .testStatus,
.customModal .slotTestEdit .quizDiv .testStatus,
.customModal .slotTestView .quizDiv .testStatus,
.customModal * .slotTestEdit .quizDiv .testStatus,
.customModal * .slotTestView .quizDiv .testStatus {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.jeeta-app .slotTestEdit .quizDiv .testStatus .msg,
.jeeta-app .slotTestView .quizDiv .testStatus .msg,
.jeeta-app * .slotTestEdit .quizDiv .testStatus .msg,
.jeeta-app * .slotTestView .quizDiv .testStatus .msg,
.customModal .slotTestEdit .quizDiv .testStatus .msg,
.customModal .slotTestView .quizDiv .testStatus .msg,
.customModal * .slotTestEdit .quizDiv .testStatus .msg,
.customModal * .slotTestView .quizDiv .testStatus .msg {
  color: var(--whiteColor);
  border: none;
  border-radius: .3rem;
  font-size: .83rem;
  padding: .3rem .6rem;
  font-weight: 600;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: .5rem;
}

.jeeta-app .slotTestEdit .quizDiv .testStatus #started,
.jeeta-app .slotTestView .quizDiv .testStatus #started,
.jeeta-app * .slotTestEdit .quizDiv .testStatus #started,
.jeeta-app * .slotTestView .quizDiv .testStatus #started,
.customModal .slotTestEdit .quizDiv .testStatus #started,
.customModal .slotTestView .quizDiv .testStatus #started,
.customModal * .slotTestEdit .quizDiv .testStatus #started,
.customModal * .slotTestView .quizDiv .testStatus #started {
  background-color: var(--started);
}

.jeeta-app .slotTestEdit .quizDiv .testStatus #finished,
.jeeta-app .slotTestView .quizDiv .testStatus #finished,
.jeeta-app * .slotTestEdit .quizDiv .testStatus #finished,
.jeeta-app * .slotTestView .quizDiv .testStatus #finished,
.customModal .slotTestEdit .quizDiv .testStatus #finished,
.customModal .slotTestView .quizDiv .testStatus #finished,
.customModal * .slotTestEdit .quizDiv .testStatus #finished,
.customModal * .slotTestView .quizDiv .testStatus #finished {
  background-color: var(--finished);
}

.jeeta-app .slotTestEdit .quizDiv .testStatus #not-started,
.jeeta-app .slotTestView .quizDiv .testStatus #not-started,
.jeeta-app * .slotTestEdit .quizDiv .testStatus #not-started,
.jeeta-app * .slotTestView .quizDiv .testStatus #not-started,
.customModal .slotTestEdit .quizDiv .testStatus #not-started,
.customModal .slotTestView .quizDiv .testStatus #not-started,
.customModal * .slotTestEdit .quizDiv .testStatus #not-started,
.customModal * .slotTestView .quizDiv .testStatus #not-started {
  background-color: var(--not-started);
}

.jeeta-app .slotTestEdit .quizDiv .testRefresh,
.jeeta-app .slotTestView .quizDiv .testRefresh,
.jeeta-app * .slotTestEdit .quizDiv .testRefresh,
.jeeta-app * .slotTestView .quizDiv .testRefresh,
.customModal .slotTestEdit .quizDiv .testRefresh,
.customModal .slotTestView .quizDiv .testRefresh,
.customModal * .slotTestEdit .quizDiv .testRefresh,
.customModal * .slotTestView .quizDiv .testRefresh {
  position: absolute;
  top: 0;
  right: 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.jeeta-app .slotTestEdit .quizDiv .testRefresh .msg,
.jeeta-app .slotTestView .quizDiv .testRefresh .msg,
.jeeta-app * .slotTestEdit .quizDiv .testRefresh .msg,
.jeeta-app * .slotTestView .quizDiv .testRefresh .msg,
.customModal .slotTestEdit .quizDiv .testRefresh .msg,
.customModal .slotTestView .quizDiv .testRefresh .msg,
.customModal * .slotTestEdit .quizDiv .testRefresh .msg,
.customModal * .slotTestView .quizDiv .testRefresh .msg {
  background-color: var(--started);
  color: var(--whiteColor);
  border: none;
  border-radius: .3rem;
  font-size: .83rem;
  padding: .3rem .6rem;
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: .5rem;
}

.jeeta-app .slotTestEdit .quizDiv .testRefresh .msg .icon,
.jeeta-app .slotTestView .quizDiv .testRefresh .msg .icon,
.jeeta-app * .slotTestEdit .quizDiv .testRefresh .msg .icon,
.jeeta-app * .slotTestView .quizDiv .testRefresh .msg .icon,
.customModal .slotTestEdit .quizDiv .testRefresh .msg .icon,
.customModal .slotTestView .quizDiv .testRefresh .msg .icon,
.customModal * .slotTestEdit .quizDiv .testRefresh .msg .icon,
.customModal * .slotTestView .quizDiv .testRefresh .msg .icon {
  font-size: 1rem;
  font-weight: 600;
}

.jeeta-app .slotTestEdit .quizDiv .testRefresh:hover,
.jeeta-app .slotTestView .quizDiv .testRefresh:hover,
.jeeta-app * .slotTestEdit .quizDiv .testRefresh:hover,
.jeeta-app * .slotTestView .quizDiv .testRefresh:hover,
.customModal .slotTestEdit .quizDiv .testRefresh:hover,
.customModal .slotTestView .quizDiv .testRefresh:hover,
.customModal * .slotTestEdit .quizDiv .testRefresh:hover,
.customModal * .slotTestView .quizDiv .testRefresh:hover {
  cursor: pointer;
}

.jeeta-app .slotTestEdit .quizDiv .testEdit,
.jeeta-app .slotTestEdit .quizDiv .testSubject,
.jeeta-app .slotTestView .quizDiv .testEdit,
.jeeta-app .slotTestView .quizDiv .testSubject,
.jeeta-app * .slotTestEdit .quizDiv .testEdit,
.jeeta-app * .slotTestEdit .quizDiv .testSubject,
.jeeta-app * .slotTestView .quizDiv .testEdit,
.jeeta-app * .slotTestView .quizDiv .testSubject,
.customModal .slotTestEdit .quizDiv .testEdit,
.customModal .slotTestEdit .quizDiv .testSubject,
.customModal .slotTestView .quizDiv .testEdit,
.customModal .slotTestView .quizDiv .testSubject,
.customModal * .slotTestEdit .quizDiv .testEdit,
.customModal * .slotTestEdit .quizDiv .testSubject,
.customModal * .slotTestView .quizDiv .testEdit,
.customModal * .slotTestView .quizDiv .testSubject {
  width: 100%;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: 0;
}

.jeeta-app .slotTestEdit .quizDiv .testEdit .hideIcon,
.jeeta-app .slotTestEdit .quizDiv .testSubject .hideIcon,
.jeeta-app .slotTestView .quizDiv .testEdit .hideIcon,
.jeeta-app .slotTestView .quizDiv .testSubject .hideIcon,
.jeeta-app * .slotTestEdit .quizDiv .testEdit .hideIcon,
.jeeta-app * .slotTestEdit .quizDiv .testSubject .hideIcon,
.jeeta-app * .slotTestView .quizDiv .testEdit .hideIcon,
.jeeta-app * .slotTestView .quizDiv .testSubject .hideIcon,
.customModal .slotTestEdit .quizDiv .testEdit .hideIcon,
.customModal .slotTestEdit .quizDiv .testSubject .hideIcon,
.customModal .slotTestView .quizDiv .testEdit .hideIcon,
.customModal .slotTestView .quizDiv .testSubject .hideIcon,
.customModal * .slotTestEdit .quizDiv .testEdit .hideIcon,
.customModal * .slotTestEdit .quizDiv .testSubject .hideIcon,
.customModal * .slotTestView .quizDiv .testEdit .hideIcon,
.customModal * .slotTestView .quizDiv .testSubject .hideIcon {
  display: none;
}

.jeeta-app .slotTestEdit .quizDiv .testEdit .testSection,
.jeeta-app .slotTestEdit .quizDiv .testEdit .testSectionContainer,
.jeeta-app .slotTestEdit .quizDiv .testSubject .testSection,
.jeeta-app .slotTestEdit .quizDiv .testSubject .testSectionContainer,
.jeeta-app .slotTestView .quizDiv .testEdit .testSection,
.jeeta-app .slotTestView .quizDiv .testEdit .testSectionContainer,
.jeeta-app .slotTestView .quizDiv .testSubject .testSection,
.jeeta-app .slotTestView .quizDiv .testSubject .testSectionContainer,
.jeeta-app * .slotTestEdit .quizDiv .testEdit .testSection,
.jeeta-app * .slotTestEdit .quizDiv .testEdit .testSectionContainer,
.jeeta-app * .slotTestEdit .quizDiv .testSubject .testSection,
.jeeta-app * .slotTestEdit .quizDiv .testSubject .testSectionContainer,
.jeeta-app * .slotTestView .quizDiv .testEdit .testSection,
.jeeta-app * .slotTestView .quizDiv .testEdit .testSectionContainer,
.jeeta-app * .slotTestView .quizDiv .testSubject .testSection,
.jeeta-app * .slotTestView .quizDiv .testSubject .testSectionContainer,
.customModal .slotTestEdit .quizDiv .testEdit .testSection,
.customModal .slotTestEdit .quizDiv .testEdit .testSectionContainer,
.customModal .slotTestEdit .quizDiv .testSubject .testSection,
.customModal .slotTestEdit .quizDiv .testSubject .testSectionContainer,
.customModal .slotTestView .quizDiv .testEdit .testSection,
.customModal .slotTestView .quizDiv .testEdit .testSectionContainer,
.customModal .slotTestView .quizDiv .testSubject .testSection,
.customModal .slotTestView .quizDiv .testSubject .testSectionContainer,
.customModal * .slotTestEdit .quizDiv .testEdit .testSection,
.customModal * .slotTestEdit .quizDiv .testEdit .testSectionContainer,
.customModal * .slotTestEdit .quizDiv .testSubject .testSection,
.customModal * .slotTestEdit .quizDiv .testSubject .testSectionContainer,
.customModal * .slotTestView .quizDiv .testEdit .testSection,
.customModal * .slotTestView .quizDiv .testEdit .testSectionContainer,
.customModal * .slotTestView .quizDiv .testSubject .testSection,
.customModal * .slotTestView .quizDiv .testSubject .testSectionContainer {
  width: 100%;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.jeeta-app .slotTestCreateModal,
.jeeta-app * .slotTestCreateModal,
.customModal .slotTestCreateModal,
.customModal * .slotTestCreateModal {
  position: absolute;
  top: 55vh;
  left: 50vw;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: var(--whiteColorDeam);
  padding: 2rem;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
}

.jeeta-app .slotTestCreateModal .modalTitle,
.jeeta-app * .slotTestCreateModal .modalTitle,
.customModal .slotTestCreateModal .modalTitle,
.customModal * .slotTestCreateModal .modalTitle {
  text-align: center;
  padding: 1rem;
  text-wrap: nowrap;
}

.jeeta-app .slotTestCreateModal .testInfoInput,
.jeeta-app * .slotTestCreateModal .testInfoInput,
.customModal .slotTestCreateModal .testInfoInput,
.customModal * .slotTestCreateModal .testInfoInput {
  width: 90%;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: .5rem;
}

.jeeta-app .slotTestCreateModal .testInfoInput .infoLabel,
.jeeta-app * .slotTestCreateModal .testInfoInput .infoLabel,
.customModal .slotTestCreateModal .testInfoInput .infoLabel,
.customModal * .slotTestCreateModal .testInfoInput .infoLabel {
  font-weight: 600;
}

.jeeta-app .slotTestCreateModal .testInfoInput .textInput,
.jeeta-app * .slotTestCreateModal .testInfoInput .textInput,
.customModal .slotTestCreateModal .testInfoInput .textInput,
.customModal * .slotTestCreateModal .testInfoInput .textInput {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  padding: .5rem;
  border: none;
  min-width: 0;
}

.jeeta-app .slotTestCreateModal .testInfoInput .numberInput,
.jeeta-app * .slotTestCreateModal .testInfoInput .numberInput,
.customModal .slotTestCreateModal .testInfoInput .numberInput,
.customModal * .slotTestCreateModal .testInfoInput .numberInput {
  width: 80px;
  padding: .5rem;
  border: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.jeeta-app .slotTestCreateModal #create,
.jeeta-app * .slotTestCreateModal #create,
.customModal .slotTestCreateModal #create,
.customModal * .slotTestCreateModal #create {
  margin-top: 1rem;
  font-weight: 600;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.jeeta-app .slotTestCreateModal #close,
.jeeta-app * .slotTestCreateModal #close,
.customModal .slotTestCreateModal #close,
.customModal * .slotTestCreateModal #close {
  position: absolute;
  top: .3rem;
  right: .3rem;
  color: var(--PrimaryColor);
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
}

.jeeta-app .slotTestCreateModal #close:hover,
.jeeta-app * .slotTestCreateModal #close:hover,
.customModal .slotTestCreateModal #close:hover,
.customModal * .slotTestCreateModal #close:hover {
  color: var(--tiger-lilly);
}

@media all and (max-width: 720px) {
  .jeeta-app .slotTestEdit,
  .jeeta-app .slotTestView,
  .jeeta-app * .slotTestEdit,
  .jeeta-app * .slotTestView,
  .customModal .slotTestEdit,
  .customModal .slotTestView,
  .customModal * .slotTestEdit,
  .customModal * .slotTestView {
    gap: .5rem;
  }
  .jeeta-app .slotTestEdit .quizSelect .quizSelectionDropdown .selectionDropdown,
  .jeeta-app .slotTestView .quizSelect .quizSelectionDropdown .selectionDropdown,
  .jeeta-app * .slotTestEdit .quizSelect .quizSelectionDropdown .selectionDropdown,
  .jeeta-app * .slotTestView .quizSelect .quizSelectionDropdown .selectionDropdown,
  .customModal .slotTestEdit .quizSelect .quizSelectionDropdown .selectionDropdown,
  .customModal .slotTestView .quizSelect .quizSelectionDropdown .selectionDropdown,
  .customModal * .slotTestEdit .quizSelect .quizSelectionDropdown .selectionDropdown,
  .customModal * .slotTestView .quizSelect .quizSelectionDropdown .selectionDropdown {
    font-size: .95rem;
  }
  .jeeta-app .slotTestEdit .quizDiv,
  .jeeta-app .slotTestView .quizDiv,
  .jeeta-app * .slotTestEdit .quizDiv,
  .jeeta-app * .slotTestView .quizDiv,
  .customModal .slotTestEdit .quizDiv,
  .customModal .slotTestView .quizDiv,
  .customModal * .slotTestEdit .quizDiv,
  .customModal * .slotTestView .quizDiv {
    padding: 1.5rem .5rem .5rem .5rem;
    gap: .5rem;
  }
  .jeeta-app .slotTestEdit .quizDiv .testTimer,
  .jeeta-app .slotTestView .quizDiv .testTimer,
  .jeeta-app * .slotTestEdit .quizDiv .testTimer,
  .jeeta-app * .slotTestView .quizDiv .testTimer,
  .customModal .slotTestEdit .quizDiv .testTimer,
  .customModal .slotTestView .quizDiv .testTimer,
  .customModal * .slotTestEdit .quizDiv .testTimer,
  .customModal * .slotTestView .quizDiv .testTimer {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
}

@media all and (max-width: 500px) {
  .jeeta-app .slotTestEdit,
  .jeeta-app .slotTestView,
  .jeeta-app * .slotTestEdit,
  .jeeta-app * .slotTestView,
  .customModal .slotTestEdit,
  .customModal .slotTestView,
  .customModal * .slotTestEdit,
  .customModal * .slotTestView {
    gap: .5rem;
  }
  .jeeta-app .slotTestEdit .quizSelect .quizSelectionDropdown .selectionDropdown,
  .jeeta-app .slotTestView .quizSelect .quizSelectionDropdown .selectionDropdown,
  .jeeta-app * .slotTestEdit .quizSelect .quizSelectionDropdown .selectionDropdown,
  .jeeta-app * .slotTestView .quizSelect .quizSelectionDropdown .selectionDropdown,
  .customModal .slotTestEdit .quizSelect .quizSelectionDropdown .selectionDropdown,
  .customModal .slotTestView .quizSelect .quizSelectionDropdown .selectionDropdown,
  .customModal * .slotTestEdit .quizSelect .quizSelectionDropdown .selectionDropdown,
  .customModal * .slotTestView .quizSelect .quizSelectionDropdown .selectionDropdown {
    font-size: .9rem;
  }
  .jeeta-app .slotTestEdit .quizDiv,
  .jeeta-app .slotTestView .quizDiv,
  .jeeta-app * .slotTestEdit .quizDiv,
  .jeeta-app * .slotTestView .quizDiv,
  .customModal .slotTestEdit .quizDiv,
  .customModal .slotTestView .quizDiv,
  .customModal * .slotTestEdit .quizDiv,
  .customModal * .slotTestView .quizDiv {
    min-height: 20rem;
  }
  .jeeta-app .slotTestEdit .quizDiv .testTimer,
  .jeeta-app .slotTestView .quizDiv .testTimer,
  .jeeta-app * .slotTestEdit .quizDiv .testTimer,
  .jeeta-app * .slotTestView .quizDiv .testTimer,
  .customModal .slotTestEdit .quizDiv .testTimer,
  .customModal .slotTestView .quizDiv .testTimer,
  .customModal * .slotTestEdit .quizDiv .testTimer,
  .customModal * .slotTestView .quizDiv .testTimer {
    margin-top: .5rem;
  }
  .jeeta-app .slotTestCreateModal,
  .jeeta-app * .slotTestCreateModal,
  .customModal .slotTestCreateModal,
  .customModal * .slotTestCreateModal {
    width: 90%;
  }
}
