.jeeta-app .batches,
.jeeta-app .batchView,
.jeeta-app * .batches,
.jeeta-app * .batchView,
.customModal .batches,
.customModal .batchView,
.customModal * .batches,
.customModal * .batchView {
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
  padding: 1rem;
  color: var(--textColor);
  -webkit-animation: fadeUp 1.2s ease-out forwards;
          animation: fadeUp 1.2s ease-out forwards;
  /* Example duration and timing */
}

.jeeta-app .batches .btn,
.jeeta-app .batchView .btn,
.jeeta-app * .batches .btn,
.jeeta-app * .batchView .btn,
.customModal .batches .btn,
.customModal .batchView .btn,
.customModal * .batches .btn,
.customModal * .batchView .btn {
  color: var(--whiteColor);
  font-weight: 600;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: .5rem;
  font-size: 0.88rem;
}

.jeeta-app .batches .btn .icon,
.jeeta-app .batchView .btn .icon,
.jeeta-app * .batches .btn .icon,
.jeeta-app * .batchView .btn .icon,
.customModal .batches .btn .icon,
.customModal .batchView .btn .icon,
.customModal * .batches .btn .icon,
.customModal * .batchView .btn .icon {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  font-size: 1.11rem;
}

.jeeta-app .batches #manage,
.jeeta-app .batchView #manage,
.jeeta-app * .batches #manage,
.jeeta-app * .batchView #manage,
.customModal .batches #manage,
.customModal .batchView #manage,
.customModal * .batches #manage,
.customModal * .batchView #manage {
  margin-bottom: 2rem;
}

.jeeta-app .create-btn,
.jeeta-app * .create-btn,
.customModal .create-btn,
.customModal * .create-btn {
  border: none;
  color: var(--PrimaryColor);
  background: transparent;
}

.jeeta-app .create-btn:hover,
.jeeta-app * .create-btn:hover,
.customModal .create-btn:hover,
.customModal * .create-btn:hover {
  cursor: pointer;
}

.jeeta-app .create-btn .icon,
.jeeta-app * .create-btn .icon,
.customModal .create-btn .icon,
.customModal * .create-btn .icon {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  font-size: 2rem;
}

.jeeta-app .batches-header,
.jeeta-app * .batches-header,
.customModal .batches-header,
.customModal * .batches-header {
  position: relative;
  width: 100%;
  padding: .5rem 1rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
  background: var(--gradientBkg);
  border-radius: 1rem;
  -webkit-box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
          box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
}

.jeeta-app .batches-header .title,
.jeeta-app * .batches-header .title,
.customModal .batches-header .title,
.customModal * .batches-header .title {
  color: var(--whiteColor);
  text-shadow: 0 2px 4px rgba(140, 140, 141, 0.3);
  text-align: center;
}

.jeeta-app .batch-filter,
.jeeta-app * .batch-filter,
.customModal .batch-filter,
.customModal * .batch-filter {
  width: 95%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 1rem;
  gap: 1rem;
  z-index: 5;
}

.jeeta-app .batchGradeSelectDropdown,
.jeeta-app * .batchGradeSelectDropdown,
.customModal .batchGradeSelectDropdown,
.customModal * .batchGradeSelectDropdown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 2rem;
}

.jeeta-app .batchGradeSelectDropdown .gradeSelectionDropdown,
.jeeta-app .batchGradeSelectDropdown .batchSelectionDropdown,
.jeeta-app * .batchGradeSelectDropdown .gradeSelectionDropdown,
.jeeta-app * .batchGradeSelectDropdown .batchSelectionDropdown,
.customModal .batchGradeSelectDropdown .gradeSelectionDropdown,
.customModal .batchGradeSelectDropdown .batchSelectionDropdown,
.customModal * .batchGradeSelectDropdown .gradeSelectionDropdown,
.customModal * .batchGradeSelectDropdown .batchSelectionDropdown {
  width: 15rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: .5rem;
}

.jeeta-app .batchGradeSelectDropdown .gradeSelectionDropdown .title,
.jeeta-app .batchGradeSelectDropdown .batchSelectionDropdown .title,
.jeeta-app * .batchGradeSelectDropdown .gradeSelectionDropdown .title,
.jeeta-app * .batchGradeSelectDropdown .batchSelectionDropdown .title,
.customModal .batchGradeSelectDropdown .gradeSelectionDropdown .title,
.customModal .batchGradeSelectDropdown .batchSelectionDropdown .title,
.customModal * .batchGradeSelectDropdown .gradeSelectionDropdown .title,
.customModal * .batchGradeSelectDropdown .batchSelectionDropdown .title {
  font-size: 0.88rem;
  font-weight: 600;
}

.jeeta-app .batchGradeSelectDropdown .gradeSelectionDropdown .selectionDropdown,
.jeeta-app .batchGradeSelectDropdown .gradeSelectionDropdown .selectionDropdownMultiple,
.jeeta-app .batchGradeSelectDropdown .batchSelectionDropdown .selectionDropdown,
.jeeta-app .batchGradeSelectDropdown .batchSelectionDropdown .selectionDropdownMultiple,
.jeeta-app * .batchGradeSelectDropdown .gradeSelectionDropdown .selectionDropdown,
.jeeta-app * .batchGradeSelectDropdown .gradeSelectionDropdown .selectionDropdownMultiple,
.jeeta-app * .batchGradeSelectDropdown .batchSelectionDropdown .selectionDropdown,
.jeeta-app * .batchGradeSelectDropdown .batchSelectionDropdown .selectionDropdownMultiple,
.customModal .batchGradeSelectDropdown .gradeSelectionDropdown .selectionDropdown,
.customModal .batchGradeSelectDropdown .gradeSelectionDropdown .selectionDropdownMultiple,
.customModal .batchGradeSelectDropdown .batchSelectionDropdown .selectionDropdown,
.customModal .batchGradeSelectDropdown .batchSelectionDropdown .selectionDropdownMultiple,
.customModal * .batchGradeSelectDropdown .gradeSelectionDropdown .selectionDropdown,
.customModal * .batchGradeSelectDropdown .gradeSelectionDropdown .selectionDropdownMultiple,
.customModal * .batchGradeSelectDropdown .batchSelectionDropdown .selectionDropdown,
.customModal * .batchGradeSelectDropdown .batchSelectionDropdown .selectionDropdownMultiple {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  font-size: .83rem;
}

.jeeta-app .batchGradeSelectDropdown .gradeSelectionDropdown .selectionDropdown .title,
.jeeta-app .batchGradeSelectDropdown .gradeSelectionDropdown .selectionDropdownMultiple .title,
.jeeta-app .batchGradeSelectDropdown .batchSelectionDropdown .selectionDropdown .title,
.jeeta-app .batchGradeSelectDropdown .batchSelectionDropdown .selectionDropdownMultiple .title,
.jeeta-app * .batchGradeSelectDropdown .gradeSelectionDropdown .selectionDropdown .title,
.jeeta-app * .batchGradeSelectDropdown .gradeSelectionDropdown .selectionDropdownMultiple .title,
.jeeta-app * .batchGradeSelectDropdown .batchSelectionDropdown .selectionDropdown .title,
.jeeta-app * .batchGradeSelectDropdown .batchSelectionDropdown .selectionDropdownMultiple .title,
.customModal .batchGradeSelectDropdown .gradeSelectionDropdown .selectionDropdown .title,
.customModal .batchGradeSelectDropdown .gradeSelectionDropdown .selectionDropdownMultiple .title,
.customModal .batchGradeSelectDropdown .batchSelectionDropdown .selectionDropdown .title,
.customModal .batchGradeSelectDropdown .batchSelectionDropdown .selectionDropdownMultiple .title,
.customModal * .batchGradeSelectDropdown .gradeSelectionDropdown .selectionDropdown .title,
.customModal * .batchGradeSelectDropdown .gradeSelectionDropdown .selectionDropdownMultiple .title,
.customModal * .batchGradeSelectDropdown .batchSelectionDropdown .selectionDropdown .title,
.customModal * .batchGradeSelectDropdown .batchSelectionDropdown .selectionDropdownMultiple .title {
  padding: .35rem .5rem;
}

.jeeta-app .batchGradeSelectDropdown .gradeSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app .batchGradeSelectDropdown .gradeSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app .batchGradeSelectDropdown .batchSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app .batchGradeSelectDropdown .batchSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .batchGradeSelectDropdown .gradeSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .batchGradeSelectDropdown .gradeSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .batchGradeSelectDropdown .batchSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .batchGradeSelectDropdown .batchSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal .batchGradeSelectDropdown .gradeSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal .batchGradeSelectDropdown .gradeSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal .batchGradeSelectDropdown .batchSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal .batchGradeSelectDropdown .batchSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal * .batchGradeSelectDropdown .gradeSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal * .batchGradeSelectDropdown .gradeSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal * .batchGradeSelectDropdown .batchSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal * .batchGradeSelectDropdown .batchSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric {
  max-width: 180px;
  overflow: hidden;
}

.jeeta-app .batch-glass,
.jeeta-app * .batch-glass,
.customModal .batch-glass,
.customModal * .batch-glass {
  display: -ms-grid;
  display: grid;
  width: 100%;
  padding: 1rem;
  background: var(--whiteColor);
  border-radius: 2rem;
  gap: 1rem;
  -ms-grid-columns: 11rem auto;
      grid-template-columns: 11rem auto;
  overflow: hidden;
  -webkit-box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
          box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
}

.jeeta-app .timeTable,
.jeeta-app * .timeTable,
.customModal .timeTable,
.customModal * .timeTable {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  min-height: 500px;
  background: var(--whiteColor);
  border-radius: 2rem;
  overflow: hidden;
  -webkit-box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
          box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
}

.jeeta-app .timeTable .title,
.jeeta-app * .timeTable .title,
.customModal .timeTable .title,
.customModal * .timeTable .title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: .5rem;
  margin: 2rem .5rem 0 .5rem;
  text-align: center;
  color: var(--SecondaryColor);
}

.jeeta-app .timeTable .bkgImg,
.jeeta-app * .timeTable .bkgImg,
.customModal .timeTable .bkgImg,
.customModal * .timeTable .bkgImg {
  width: 25rem;
}

.jeeta-app #full-calendar-wrapper,
.jeeta-app * #full-calendar-wrapper,
.customModal #full-calendar-wrapper,
.customModal * #full-calendar-wrapper {
  min-height: 80vh;
}

.jeeta-app .batchModal,
.jeeta-app * .batchModal,
.customModal .batchModal,
.customModal * .batchModal {
  position: absolute;
  top: 50vh;
  left: 50vw;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 1000;
  min-width: 600px;
  max-width: 90vw;
  max-height: 90vh;
  overflow-y: auto;
  background-color: var(--whiteColorDeam);
  padding: 2rem;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.jeeta-app .batchModal .batchCreate,
.jeeta-app .batchModal .batchScheduleModify,
.jeeta-app * .batchModal .batchCreate,
.jeeta-app * .batchModal .batchScheduleModify,
.customModal .batchModal .batchCreate,
.customModal .batchModal .batchScheduleModify,
.customModal * .batchModal .batchCreate,
.customModal * .batchModal .batchScheduleModify {
  width: 100%;
  color: var(--textColor);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1.5rem;
}

.jeeta-app .batchModal .batchCreate .modalTitle,
.jeeta-app .batchModal .batchScheduleModify .modalTitle,
.jeeta-app * .batchModal .batchCreate .modalTitle,
.jeeta-app * .batchModal .batchScheduleModify .modalTitle,
.customModal .batchModal .batchCreate .modalTitle,
.customModal .batchModal .batchScheduleModify .modalTitle,
.customModal * .batchModal .batchCreate .modalTitle,
.customModal * .batchModal .batchScheduleModify .modalTitle {
  margin: 1rem 0;
  font-size: 1.38rem;
  text-align: center;
}

.jeeta-app .batchModal .batchCreate .batchInfoInput,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput,
.jeeta-app * .batchModal .batchCreate .batchInfoInput,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput,
.customModal .batchModal .batchCreate .batchInfoInput,
.customModal .batchModal .batchScheduleModify .batchInfoInput,
.customModal * .batchModal .batchCreate .batchInfoInput,
.customModal * .batchModal .batchScheduleModify .batchInfoInput {
  width: 80%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 1.5rem;
}

.jeeta-app .batchModal .batchCreate .batchInfoInput .textInput,
.jeeta-app .batchModal .batchCreate .batchInfoInput .numberInput,
.jeeta-app .batchModal .batchCreate .batchInfoInput .gradeSelectionDropdown,
.jeeta-app .batchModal .batchCreate .batchInfoInput .batchSelectionDropdown,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .textInput,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .numberInput,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .gradeSelectionDropdown,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .batchSelectionDropdown,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .textInput,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .numberInput,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .gradeSelectionDropdown,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .batchSelectionDropdown,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .textInput,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .numberInput,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .gradeSelectionDropdown,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .batchSelectionDropdown,
.customModal .batchModal .batchCreate .batchInfoInput .textInput,
.customModal .batchModal .batchCreate .batchInfoInput .numberInput,
.customModal .batchModal .batchCreate .batchInfoInput .gradeSelectionDropdown,
.customModal .batchModal .batchCreate .batchInfoInput .batchSelectionDropdown,
.customModal .batchModal .batchScheduleModify .batchInfoInput .textInput,
.customModal .batchModal .batchScheduleModify .batchInfoInput .numberInput,
.customModal .batchModal .batchScheduleModify .batchInfoInput .gradeSelectionDropdown,
.customModal .batchModal .batchScheduleModify .batchInfoInput .batchSelectionDropdown,
.customModal * .batchModal .batchCreate .batchInfoInput .textInput,
.customModal * .batchModal .batchCreate .batchInfoInput .numberInput,
.customModal * .batchModal .batchCreate .batchInfoInput .gradeSelectionDropdown,
.customModal * .batchModal .batchCreate .batchInfoInput .batchSelectionDropdown,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .textInput,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .numberInput,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .gradeSelectionDropdown,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .batchSelectionDropdown {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: .5rem;
}

.jeeta-app .batchModal .batchCreate .batchInfoInput .textInput .title,
.jeeta-app .batchModal .batchCreate .batchInfoInput .numberInput .title,
.jeeta-app .batchModal .batchCreate .batchInfoInput .gradeSelectionDropdown .title,
.jeeta-app .batchModal .batchCreate .batchInfoInput .batchSelectionDropdown .title,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .textInput .title,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .numberInput .title,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .gradeSelectionDropdown .title,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .batchSelectionDropdown .title,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .textInput .title,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .numberInput .title,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .gradeSelectionDropdown .title,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .batchSelectionDropdown .title,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .textInput .title,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .numberInput .title,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .gradeSelectionDropdown .title,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .batchSelectionDropdown .title,
.customModal .batchModal .batchCreate .batchInfoInput .textInput .title,
.customModal .batchModal .batchCreate .batchInfoInput .numberInput .title,
.customModal .batchModal .batchCreate .batchInfoInput .gradeSelectionDropdown .title,
.customModal .batchModal .batchCreate .batchInfoInput .batchSelectionDropdown .title,
.customModal .batchModal .batchScheduleModify .batchInfoInput .textInput .title,
.customModal .batchModal .batchScheduleModify .batchInfoInput .numberInput .title,
.customModal .batchModal .batchScheduleModify .batchInfoInput .gradeSelectionDropdown .title,
.customModal .batchModal .batchScheduleModify .batchInfoInput .batchSelectionDropdown .title,
.customModal * .batchModal .batchCreate .batchInfoInput .textInput .title,
.customModal * .batchModal .batchCreate .batchInfoInput .numberInput .title,
.customModal * .batchModal .batchCreate .batchInfoInput .gradeSelectionDropdown .title,
.customModal * .batchModal .batchCreate .batchInfoInput .batchSelectionDropdown .title,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .textInput .title,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .numberInput .title,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .gradeSelectionDropdown .title,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .batchSelectionDropdown .title {
  min-width: 5rem;
  font-size: .87rem;
  font-weight: 600;
}

.jeeta-app .batchModal .batchCreate .batchInfoInput .textInput input,
.jeeta-app .batchModal .batchCreate .batchInfoInput .numberInput input,
.jeeta-app .batchModal .batchCreate .batchInfoInput .gradeSelectionDropdown input,
.jeeta-app .batchModal .batchCreate .batchInfoInput .batchSelectionDropdown input,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .textInput input,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .numberInput input,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .gradeSelectionDropdown input,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .batchSelectionDropdown input,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .textInput input,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .numberInput input,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .gradeSelectionDropdown input,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .batchSelectionDropdown input,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .textInput input,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .numberInput input,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .gradeSelectionDropdown input,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .batchSelectionDropdown input,
.customModal .batchModal .batchCreate .batchInfoInput .textInput input,
.customModal .batchModal .batchCreate .batchInfoInput .numberInput input,
.customModal .batchModal .batchCreate .batchInfoInput .gradeSelectionDropdown input,
.customModal .batchModal .batchCreate .batchInfoInput .batchSelectionDropdown input,
.customModal .batchModal .batchScheduleModify .batchInfoInput .textInput input,
.customModal .batchModal .batchScheduleModify .batchInfoInput .numberInput input,
.customModal .batchModal .batchScheduleModify .batchInfoInput .gradeSelectionDropdown input,
.customModal .batchModal .batchScheduleModify .batchInfoInput .batchSelectionDropdown input,
.customModal * .batchModal .batchCreate .batchInfoInput .textInput input,
.customModal * .batchModal .batchCreate .batchInfoInput .numberInput input,
.customModal * .batchModal .batchCreate .batchInfoInput .gradeSelectionDropdown input,
.customModal * .batchModal .batchCreate .batchInfoInput .batchSelectionDropdown input,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .textInput input,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .numberInput input,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .gradeSelectionDropdown input,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .batchSelectionDropdown input {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  border: none;
  border-radius: .3rem;
  padding: .3rem .5rem;
  font-size: .87rem;
}

.jeeta-app .batchModal .batchCreate .batchInfoInput .textInput .selectionDropdown,
.jeeta-app .batchModal .batchCreate .batchInfoInput .textInput .selectionDropdownMultiple,
.jeeta-app .batchModal .batchCreate .batchInfoInput .numberInput .selectionDropdown,
.jeeta-app .batchModal .batchCreate .batchInfoInput .numberInput .selectionDropdownMultiple,
.jeeta-app .batchModal .batchCreate .batchInfoInput .gradeSelectionDropdown .selectionDropdown,
.jeeta-app .batchModal .batchCreate .batchInfoInput .gradeSelectionDropdown .selectionDropdownMultiple,
.jeeta-app .batchModal .batchCreate .batchInfoInput .batchSelectionDropdown .selectionDropdown,
.jeeta-app .batchModal .batchCreate .batchInfoInput .batchSelectionDropdown .selectionDropdownMultiple,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .textInput .selectionDropdown,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .textInput .selectionDropdownMultiple,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .numberInput .selectionDropdown,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .numberInput .selectionDropdownMultiple,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .gradeSelectionDropdown .selectionDropdown,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .gradeSelectionDropdown .selectionDropdownMultiple,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .batchSelectionDropdown .selectionDropdown,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .batchSelectionDropdown .selectionDropdownMultiple,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .textInput .selectionDropdown,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .textInput .selectionDropdownMultiple,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .numberInput .selectionDropdown,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .numberInput .selectionDropdownMultiple,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .gradeSelectionDropdown .selectionDropdown,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .gradeSelectionDropdown .selectionDropdownMultiple,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .batchSelectionDropdown .selectionDropdown,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .batchSelectionDropdown .selectionDropdownMultiple,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .textInput .selectionDropdown,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .textInput .selectionDropdownMultiple,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .numberInput .selectionDropdown,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .numberInput .selectionDropdownMultiple,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .gradeSelectionDropdown .selectionDropdown,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .gradeSelectionDropdown .selectionDropdownMultiple,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .batchSelectionDropdown .selectionDropdown,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .batchSelectionDropdown .selectionDropdownMultiple,
.customModal .batchModal .batchCreate .batchInfoInput .textInput .selectionDropdown,
.customModal .batchModal .batchCreate .batchInfoInput .textInput .selectionDropdownMultiple,
.customModal .batchModal .batchCreate .batchInfoInput .numberInput .selectionDropdown,
.customModal .batchModal .batchCreate .batchInfoInput .numberInput .selectionDropdownMultiple,
.customModal .batchModal .batchCreate .batchInfoInput .gradeSelectionDropdown .selectionDropdown,
.customModal .batchModal .batchCreate .batchInfoInput .gradeSelectionDropdown .selectionDropdownMultiple,
.customModal .batchModal .batchCreate .batchInfoInput .batchSelectionDropdown .selectionDropdown,
.customModal .batchModal .batchCreate .batchInfoInput .batchSelectionDropdown .selectionDropdownMultiple,
.customModal .batchModal .batchScheduleModify .batchInfoInput .textInput .selectionDropdown,
.customModal .batchModal .batchScheduleModify .batchInfoInput .textInput .selectionDropdownMultiple,
.customModal .batchModal .batchScheduleModify .batchInfoInput .numberInput .selectionDropdown,
.customModal .batchModal .batchScheduleModify .batchInfoInput .numberInput .selectionDropdownMultiple,
.customModal .batchModal .batchScheduleModify .batchInfoInput .gradeSelectionDropdown .selectionDropdown,
.customModal .batchModal .batchScheduleModify .batchInfoInput .gradeSelectionDropdown .selectionDropdownMultiple,
.customModal .batchModal .batchScheduleModify .batchInfoInput .batchSelectionDropdown .selectionDropdown,
.customModal .batchModal .batchScheduleModify .batchInfoInput .batchSelectionDropdown .selectionDropdownMultiple,
.customModal * .batchModal .batchCreate .batchInfoInput .textInput .selectionDropdown,
.customModal * .batchModal .batchCreate .batchInfoInput .textInput .selectionDropdownMultiple,
.customModal * .batchModal .batchCreate .batchInfoInput .numberInput .selectionDropdown,
.customModal * .batchModal .batchCreate .batchInfoInput .numberInput .selectionDropdownMultiple,
.customModal * .batchModal .batchCreate .batchInfoInput .gradeSelectionDropdown .selectionDropdown,
.customModal * .batchModal .batchCreate .batchInfoInput .gradeSelectionDropdown .selectionDropdownMultiple,
.customModal * .batchModal .batchCreate .batchInfoInput .batchSelectionDropdown .selectionDropdown,
.customModal * .batchModal .batchCreate .batchInfoInput .batchSelectionDropdown .selectionDropdownMultiple,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .textInput .selectionDropdown,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .textInput .selectionDropdownMultiple,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .numberInput .selectionDropdown,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .numberInput .selectionDropdownMultiple,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .gradeSelectionDropdown .selectionDropdown,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .gradeSelectionDropdown .selectionDropdownMultiple,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .batchSelectionDropdown .selectionDropdown,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .batchSelectionDropdown .selectionDropdownMultiple {
  width: 100%;
  font-size: .83rem;
}

.jeeta-app .batchModal .batchCreate .batchInfoInput .textInput .selectionDropdown .css-13cymwt-control,
.jeeta-app .batchModal .batchCreate .batchInfoInput .textInput .selectionDropdownMultiple .css-13cymwt-control,
.jeeta-app .batchModal .batchCreate .batchInfoInput .numberInput .selectionDropdown .css-13cymwt-control,
.jeeta-app .batchModal .batchCreate .batchInfoInput .numberInput .selectionDropdownMultiple .css-13cymwt-control,
.jeeta-app .batchModal .batchCreate .batchInfoInput .gradeSelectionDropdown .selectionDropdown .css-13cymwt-control,
.jeeta-app .batchModal .batchCreate .batchInfoInput .gradeSelectionDropdown .selectionDropdownMultiple .css-13cymwt-control,
.jeeta-app .batchModal .batchCreate .batchInfoInput .batchSelectionDropdown .selectionDropdown .css-13cymwt-control,
.jeeta-app .batchModal .batchCreate .batchInfoInput .batchSelectionDropdown .selectionDropdownMultiple .css-13cymwt-control,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .textInput .selectionDropdown .css-13cymwt-control,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .textInput .selectionDropdownMultiple .css-13cymwt-control,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .numberInput .selectionDropdown .css-13cymwt-control,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .numberInput .selectionDropdownMultiple .css-13cymwt-control,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .gradeSelectionDropdown .selectionDropdown .css-13cymwt-control,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .gradeSelectionDropdown .selectionDropdownMultiple .css-13cymwt-control,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .batchSelectionDropdown .selectionDropdown .css-13cymwt-control,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .batchSelectionDropdown .selectionDropdownMultiple .css-13cymwt-control,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .textInput .selectionDropdown .css-13cymwt-control,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .textInput .selectionDropdownMultiple .css-13cymwt-control,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .numberInput .selectionDropdown .css-13cymwt-control,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .numberInput .selectionDropdownMultiple .css-13cymwt-control,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .gradeSelectionDropdown .selectionDropdown .css-13cymwt-control,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .gradeSelectionDropdown .selectionDropdownMultiple .css-13cymwt-control,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .batchSelectionDropdown .selectionDropdown .css-13cymwt-control,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .batchSelectionDropdown .selectionDropdownMultiple .css-13cymwt-control,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .textInput .selectionDropdown .css-13cymwt-control,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .textInput .selectionDropdownMultiple .css-13cymwt-control,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .numberInput .selectionDropdown .css-13cymwt-control,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .numberInput .selectionDropdownMultiple .css-13cymwt-control,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .gradeSelectionDropdown .selectionDropdown .css-13cymwt-control,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .gradeSelectionDropdown .selectionDropdownMultiple .css-13cymwt-control,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .batchSelectionDropdown .selectionDropdown .css-13cymwt-control,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .batchSelectionDropdown .selectionDropdownMultiple .css-13cymwt-control,
.customModal .batchModal .batchCreate .batchInfoInput .textInput .selectionDropdown .css-13cymwt-control,
.customModal .batchModal .batchCreate .batchInfoInput .textInput .selectionDropdownMultiple .css-13cymwt-control,
.customModal .batchModal .batchCreate .batchInfoInput .numberInput .selectionDropdown .css-13cymwt-control,
.customModal .batchModal .batchCreate .batchInfoInput .numberInput .selectionDropdownMultiple .css-13cymwt-control,
.customModal .batchModal .batchCreate .batchInfoInput .gradeSelectionDropdown .selectionDropdown .css-13cymwt-control,
.customModal .batchModal .batchCreate .batchInfoInput .gradeSelectionDropdown .selectionDropdownMultiple .css-13cymwt-control,
.customModal .batchModal .batchCreate .batchInfoInput .batchSelectionDropdown .selectionDropdown .css-13cymwt-control,
.customModal .batchModal .batchCreate .batchInfoInput .batchSelectionDropdown .selectionDropdownMultiple .css-13cymwt-control,
.customModal .batchModal .batchScheduleModify .batchInfoInput .textInput .selectionDropdown .css-13cymwt-control,
.customModal .batchModal .batchScheduleModify .batchInfoInput .textInput .selectionDropdownMultiple .css-13cymwt-control,
.customModal .batchModal .batchScheduleModify .batchInfoInput .numberInput .selectionDropdown .css-13cymwt-control,
.customModal .batchModal .batchScheduleModify .batchInfoInput .numberInput .selectionDropdownMultiple .css-13cymwt-control,
.customModal .batchModal .batchScheduleModify .batchInfoInput .gradeSelectionDropdown .selectionDropdown .css-13cymwt-control,
.customModal .batchModal .batchScheduleModify .batchInfoInput .gradeSelectionDropdown .selectionDropdownMultiple .css-13cymwt-control,
.customModal .batchModal .batchScheduleModify .batchInfoInput .batchSelectionDropdown .selectionDropdown .css-13cymwt-control,
.customModal .batchModal .batchScheduleModify .batchInfoInput .batchSelectionDropdown .selectionDropdownMultiple .css-13cymwt-control,
.customModal * .batchModal .batchCreate .batchInfoInput .textInput .selectionDropdown .css-13cymwt-control,
.customModal * .batchModal .batchCreate .batchInfoInput .textInput .selectionDropdownMultiple .css-13cymwt-control,
.customModal * .batchModal .batchCreate .batchInfoInput .numberInput .selectionDropdown .css-13cymwt-control,
.customModal * .batchModal .batchCreate .batchInfoInput .numberInput .selectionDropdownMultiple .css-13cymwt-control,
.customModal * .batchModal .batchCreate .batchInfoInput .gradeSelectionDropdown .selectionDropdown .css-13cymwt-control,
.customModal * .batchModal .batchCreate .batchInfoInput .gradeSelectionDropdown .selectionDropdownMultiple .css-13cymwt-control,
.customModal * .batchModal .batchCreate .batchInfoInput .batchSelectionDropdown .selectionDropdown .css-13cymwt-control,
.customModal * .batchModal .batchCreate .batchInfoInput .batchSelectionDropdown .selectionDropdownMultiple .css-13cymwt-control,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .textInput .selectionDropdown .css-13cymwt-control,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .textInput .selectionDropdownMultiple .css-13cymwt-control,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .numberInput .selectionDropdown .css-13cymwt-control,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .numberInput .selectionDropdownMultiple .css-13cymwt-control,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .gradeSelectionDropdown .selectionDropdown .css-13cymwt-control,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .gradeSelectionDropdown .selectionDropdownMultiple .css-13cymwt-control,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .batchSelectionDropdown .selectionDropdown .css-13cymwt-control,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .batchSelectionDropdown .selectionDropdownMultiple .css-13cymwt-control {
  border: none;
  padding: .2rem;
}

.jeeta-app .batchModal .batchCreate .batchInfoInput .textInput .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app .batchModal .batchCreate .batchInfoInput .textInput .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app .batchModal .batchCreate .batchInfoInput .numberInput .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app .batchModal .batchCreate .batchInfoInput .numberInput .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app .batchModal .batchCreate .batchInfoInput .gradeSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app .batchModal .batchCreate .batchInfoInput .gradeSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app .batchModal .batchCreate .batchInfoInput .batchSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app .batchModal .batchCreate .batchInfoInput .batchSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .textInput .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .textInput .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .numberInput .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .numberInput .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .gradeSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .gradeSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .batchSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .batchSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .textInput .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .textInput .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .numberInput .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .numberInput .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .gradeSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .gradeSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .batchSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .batchSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .textInput .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .textInput .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .numberInput .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .numberInput .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .gradeSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .gradeSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .batchSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .batchSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal .batchModal .batchCreate .batchInfoInput .textInput .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal .batchModal .batchCreate .batchInfoInput .textInput .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal .batchModal .batchCreate .batchInfoInput .numberInput .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal .batchModal .batchCreate .batchInfoInput .numberInput .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal .batchModal .batchCreate .batchInfoInput .gradeSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal .batchModal .batchCreate .batchInfoInput .gradeSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal .batchModal .batchCreate .batchInfoInput .batchSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal .batchModal .batchCreate .batchInfoInput .batchSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal .batchModal .batchScheduleModify .batchInfoInput .textInput .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal .batchModal .batchScheduleModify .batchInfoInput .textInput .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal .batchModal .batchScheduleModify .batchInfoInput .numberInput .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal .batchModal .batchScheduleModify .batchInfoInput .numberInput .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal .batchModal .batchScheduleModify .batchInfoInput .gradeSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal .batchModal .batchScheduleModify .batchInfoInput .gradeSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal .batchModal .batchScheduleModify .batchInfoInput .batchSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal .batchModal .batchScheduleModify .batchInfoInput .batchSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal * .batchModal .batchCreate .batchInfoInput .textInput .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal * .batchModal .batchCreate .batchInfoInput .textInput .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal * .batchModal .batchCreate .batchInfoInput .numberInput .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal * .batchModal .batchCreate .batchInfoInput .numberInput .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal * .batchModal .batchCreate .batchInfoInput .gradeSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal * .batchModal .batchCreate .batchInfoInput .gradeSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal * .batchModal .batchCreate .batchInfoInput .batchSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal * .batchModal .batchCreate .batchInfoInput .batchSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .textInput .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .textInput .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .numberInput .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .numberInput .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .gradeSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .gradeSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .batchSelectionDropdown .selectionDropdown .css-wsp0cs-MultiValueGeneric,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .batchSelectionDropdown .selectionDropdownMultiple .css-wsp0cs-MultiValueGeneric {
  max-width: 180px;
  overflow: hidden;
}

.jeeta-app .batchModal .batchCreate .batchInfoInput .timeDateInput,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .timeDateInput,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .timeDateInput,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .timeDateInput,
.customModal .batchModal .batchCreate .batchInfoInput .timeDateInput,
.customModal .batchModal .batchScheduleModify .batchInfoInput .timeDateInput,
.customModal * .batchModal .batchCreate .batchInfoInput .timeDateInput,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .timeDateInput {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
}

.jeeta-app .batchModal .batchCreate .batchInfoInput .timeDateInput .dateInput,
.jeeta-app .batchModal .batchCreate .batchInfoInput .timeDateInput .timeInput,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .timeDateInput .dateInput,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .timeDateInput .timeInput,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .timeDateInput .dateInput,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .timeDateInput .timeInput,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .timeDateInput .dateInput,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .timeDateInput .timeInput,
.customModal .batchModal .batchCreate .batchInfoInput .timeDateInput .dateInput,
.customModal .batchModal .batchCreate .batchInfoInput .timeDateInput .timeInput,
.customModal .batchModal .batchScheduleModify .batchInfoInput .timeDateInput .dateInput,
.customModal .batchModal .batchScheduleModify .batchInfoInput .timeDateInput .timeInput,
.customModal * .batchModal .batchCreate .batchInfoInput .timeDateInput .dateInput,
.customModal * .batchModal .batchCreate .batchInfoInput .timeDateInput .timeInput,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .timeDateInput .dateInput,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .timeDateInput .timeInput {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: .5rem;
}

.jeeta-app .batchModal .batchCreate .batchInfoInput .timeDateInput .dateInput .title,
.jeeta-app .batchModal .batchCreate .batchInfoInput .timeDateInput .timeInput .title,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .timeDateInput .dateInput .title,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .timeDateInput .timeInput .title,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .timeDateInput .dateInput .title,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .timeDateInput .timeInput .title,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .timeDateInput .dateInput .title,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .timeDateInput .timeInput .title,
.customModal .batchModal .batchCreate .batchInfoInput .timeDateInput .dateInput .title,
.customModal .batchModal .batchCreate .batchInfoInput .timeDateInput .timeInput .title,
.customModal .batchModal .batchScheduleModify .batchInfoInput .timeDateInput .dateInput .title,
.customModal .batchModal .batchScheduleModify .batchInfoInput .timeDateInput .timeInput .title,
.customModal * .batchModal .batchCreate .batchInfoInput .timeDateInput .dateInput .title,
.customModal * .batchModal .batchCreate .batchInfoInput .timeDateInput .timeInput .title,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .timeDateInput .dateInput .title,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .timeDateInput .timeInput .title {
  min-width: 5rem;
  font-size: 0.88rem;
  font-weight: 600;
}

.jeeta-app .batchModal .batchCreate .batchInfoInput .timeDateInput .dateInput input,
.jeeta-app .batchModal .batchCreate .batchInfoInput .timeDateInput .timeInput input,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .timeDateInput .dateInput input,
.jeeta-app .batchModal .batchScheduleModify .batchInfoInput .timeDateInput .timeInput input,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .timeDateInput .dateInput input,
.jeeta-app * .batchModal .batchCreate .batchInfoInput .timeDateInput .timeInput input,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .timeDateInput .dateInput input,
.jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .timeDateInput .timeInput input,
.customModal .batchModal .batchCreate .batchInfoInput .timeDateInput .dateInput input,
.customModal .batchModal .batchCreate .batchInfoInput .timeDateInput .timeInput input,
.customModal .batchModal .batchScheduleModify .batchInfoInput .timeDateInput .dateInput input,
.customModal .batchModal .batchScheduleModify .batchInfoInput .timeDateInput .timeInput input,
.customModal * .batchModal .batchCreate .batchInfoInput .timeDateInput .dateInput input,
.customModal * .batchModal .batchCreate .batchInfoInput .timeDateInput .timeInput input,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .timeDateInput .dateInput input,
.customModal * .batchModal .batchScheduleModify .batchInfoInput .timeDateInput .timeInput input {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  max-width: 180px;
  border: none;
  border-radius: .3rem;
  padding: .5rem;
  font-size: 0.88rem;
}

.jeeta-app .batchModal .batchCreate .btn,
.jeeta-app .batchModal .batchScheduleModify .btn,
.jeeta-app * .batchModal .batchCreate .btn,
.jeeta-app * .batchModal .batchScheduleModify .btn,
.customModal .batchModal .batchCreate .btn,
.customModal .batchModal .batchScheduleModify .btn,
.customModal * .batchModal .batchCreate .btn,
.customModal * .batchModal .batchScheduleModify .btn {
  margin-top: 1rem;
  color: var(--whiteColor);
  font-weight: 600;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: .5rem;
}

.jeeta-app .batchModal .batchCreate .btn .icon,
.jeeta-app .batchModal .batchScheduleModify .btn .icon,
.jeeta-app * .batchModal .batchCreate .btn .icon,
.jeeta-app * .batchModal .batchScheduleModify .btn .icon,
.customModal .batchModal .batchCreate .btn .icon,
.customModal .batchModal .batchScheduleModify .btn .icon,
.customModal * .batchModal .batchCreate .btn .icon,
.customModal * .batchModal .batchScheduleModify .btn .icon {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  font-size: 1rem;
}

.jeeta-app .batchModal #close,
.jeeta-app * .batchModal #close,
.customModal .batchModal #close,
.customModal * .batchModal #close {
  position: absolute;
  top: .3rem;
  right: .3rem;
  color: var(--PrimaryColor);
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  font-size: 1.66rem;
}

@media all and (max-width: 860px) {
  .jeeta-app .batches,
  .jeeta-app .batchView,
  .jeeta-app * .batches,
  .jeeta-app * .batchView,
  .customModal .batches,
  .customModal .batchView,
  .customModal * .batches,
  .customModal * .batchView {
    gap: .5rem;
  }
  .jeeta-app .batches .LecturePIN,
  .jeeta-app .batchView .LecturePIN,
  .jeeta-app * .batches .LecturePIN,
  .jeeta-app * .batchView .LecturePIN,
  .customModal .batches .LecturePIN,
  .customModal .batchView .LecturePIN,
  .customModal * .batches .LecturePIN,
  .customModal * .batchView .LecturePIN {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
}

@media all and (max-width: 800px) {
  .jeeta-app .batches,
  .jeeta-app .batchView,
  .jeeta-app * .batches,
  .jeeta-app * .batchView,
  .customModal .batches,
  .customModal .batchView,
  .customModal * .batches,
  .customModal * .batchView {
    padding: .5rem;
  }
  .jeeta-app .batches .LecturePIN,
  .jeeta-app .batchView .LecturePIN,
  .jeeta-app * .batches .LecturePIN,
  .jeeta-app * .batchView .LecturePIN,
  .customModal .batches .LecturePIN,
  .customModal .batchView .LecturePIN,
  .customModal * .batches .LecturePIN,
  .customModal * .batchView .LecturePIN {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  .jeeta-app .batches-header,
  .jeeta-app * .batches-header,
  .customModal .batches-header,
  .customModal * .batches-header {
    margin: .5rem 0;
    padding: .5rem 0;
  }
}

@media all and (max-width: 720px) {
  .jeeta-app .batches .batchFilter .filterTitle,
  .jeeta-app .batchView .batchFilter .filterTitle,
  .jeeta-app * .batches .batchFilter .filterTitle,
  .jeeta-app * .batchView .batchFilter .filterTitle,
  .customModal .batches .batchFilter .filterTitle,
  .customModal .batchView .batchFilter .filterTitle,
  .customModal * .batches .batchFilter .filterTitle,
  .customModal * .batchView .batchFilter .filterTitle {
    padding: .5rem;
  }
  .jeeta-app .batches .batchFilter .filterTitle .title,
  .jeeta-app .batchView .batchFilter .filterTitle .title,
  .jeeta-app * .batches .batchFilter .filterTitle .title,
  .jeeta-app * .batchView .batchFilter .filterTitle .title,
  .customModal .batches .batchFilter .filterTitle .title,
  .customModal .batchView .batchFilter .filterTitle .title,
  .customModal * .batches .batchFilter .filterTitle .title,
  .customModal * .batchView .batchFilter .filterTitle .title {
    margin-top: 0;
  }
  .jeeta-app .batches .batchFilter .filters,
  .jeeta-app .batchView .batchFilter .filters,
  .jeeta-app * .batches .batchFilter .filters,
  .jeeta-app * .batchView .batchFilter .filters,
  .customModal .batches .batchFilter .filters,
  .customModal .batchView .batchFilter .filters,
  .customModal * .batches .batchFilter .filters,
  .customModal * .batchView .batchFilter .filters {
    padding: .5rem;
  }
  .jeeta-app .batches .batchFilter .filters .create-btn .icon,
  .jeeta-app .batchView .batchFilter .filters .create-btn .icon,
  .jeeta-app * .batches .batchFilter .filters .create-btn .icon,
  .jeeta-app * .batchView .batchFilter .filters .create-btn .icon,
  .customModal .batches .batchFilter .filters .create-btn .icon,
  .customModal .batchView .batchFilter .filters .create-btn .icon,
  .customModal * .batches .batchFilter .filters .create-btn .icon,
  .customModal * .batchView .batchFilter .filters .create-btn .icon {
    font-size: 1.66rem;
  }
  .jeeta-app .batchGradeSelectDropdown,
  .jeeta-app * .batchGradeSelectDropdown,
  .customModal .batchGradeSelectDropdown,
  .customModal * .batchGradeSelectDropdown {
    gap: 1rem;
  }
  .jeeta-app .batchModal,
  .jeeta-app * .batchModal,
  .customModal .batchModal,
  .customModal * .batchModal {
    min-width: 0;
    width: 80vw;
    gap: .5rem;
    padding: .5rem;
  }
  .jeeta-app .batchModal .batchCreate,
  .jeeta-app .batchModal .batchScheduleModify,
  .jeeta-app * .batchModal .batchCreate,
  .jeeta-app * .batchModal .batchScheduleModify,
  .customModal .batchModal .batchCreate,
  .customModal .batchModal .batchScheduleModify,
  .customModal * .batchModal .batchCreate,
  .customModal * .batchModal .batchScheduleModify {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  .jeeta-app .batchModal .batchCreate .batchInfoInput,
  .jeeta-app .batchModal .batchScheduleModify .batchInfoInput,
  .jeeta-app * .batchModal .batchCreate .batchInfoInput,
  .jeeta-app * .batchModal .batchScheduleModify .batchInfoInput,
  .customModal .batchModal .batchCreate .batchInfoInput,
  .customModal .batchModal .batchScheduleModify .batchInfoInput,
  .customModal * .batchModal .batchCreate .batchInfoInput,
  .customModal * .batchModal .batchScheduleModify .batchInfoInput {
    gap: 1rem;
  }
  .jeeta-app .batchModal .batchCreate .batchInfoInput .timeDateInput,
  .jeeta-app .batchModal .batchScheduleModify .batchInfoInput .timeDateInput,
  .jeeta-app * .batchModal .batchCreate .batchInfoInput .timeDateInput,
  .jeeta-app * .batchModal .batchScheduleModify .batchInfoInput .timeDateInput,
  .customModal .batchModal .batchCreate .batchInfoInput .timeDateInput,
  .customModal .batchModal .batchScheduleModify .batchInfoInput .timeDateInput,
  .customModal * .batchModal .batchCreate .batchInfoInput .timeDateInput,
  .customModal * .batchModal .batchScheduleModify .batchInfoInput .timeDateInput {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

@media all and (max-width: 610px) {
  .jeeta-app .batchGradeSelectDropdown,
  .jeeta-app * .batchGradeSelectDropdown,
  .customModal .batchGradeSelectDropdown,
  .customModal * .batchGradeSelectDropdown {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

@media all and (max-width: 550px) {
  .jeeta-app .batches .batches-header,
  .jeeta-app .batchView .batches-header,
  .jeeta-app * .batches .batches-header,
  .jeeta-app * .batchView .batches-header,
  .customModal .batches .batches-header,
  .customModal .batchView .batches-header,
  .customModal * .batches .batches-header,
  .customModal * .batchView .batches-header {
    margin: 0;
    padding: .5rem;
    font-size: .9rem;
  }
  .jeeta-app .batches .batchFilter,
  .jeeta-app .batchView .batchFilter,
  .jeeta-app * .batches .batchFilter,
  .jeeta-app * .batchView .batchFilter,
  .customModal .batches .batchFilter,
  .customModal .batchView .batchFilter,
  .customModal * .batches .batchFilter,
  .customModal * .batchView .batchFilter {
    padding: .5rem 0;
    gap: 1em;
  }
  .jeeta-app .batches .batchFilter .filterTitle .title,
  .jeeta-app .batchView .batchFilter .filterTitle .title,
  .jeeta-app * .batches .batchFilter .filterTitle .title,
  .jeeta-app * .batchView .batchFilter .filterTitle .title,
  .customModal .batches .batchFilter .filterTitle .title,
  .customModal .batchView .batchFilter .filterTitle .title,
  .customModal * .batches .batchFilter .filterTitle .title,
  .customModal * .batchView .batchFilter .filterTitle .title {
    font-size: 1.27rem;
  }
  .jeeta-app .batches .batchFilter .filterTitle span,
  .jeeta-app .batchView .batchFilter .filterTitle span,
  .jeeta-app * .batches .batchFilter .filterTitle span,
  .jeeta-app * .batchView .batchFilter .filterTitle span,
  .customModal .batches .batchFilter .filterTitle span,
  .customModal .batchView .batchFilter .filterTitle span,
  .customModal * .batches .batchFilter .filterTitle span,
  .customModal * .batchView .batchFilter .filterTitle span {
    font-size: 0.88rem;
  }
  .jeeta-app .batches .batchFilter .filters .create-btn,
  .jeeta-app .batchView .batchFilter .filters .create-btn,
  .jeeta-app * .batches .batchFilter .filters .create-btn,
  .jeeta-app * .batchView .batchFilter .filters .create-btn,
  .customModal .batches .batchFilter .filters .create-btn,
  .customModal .batchView .batchFilter .filters .create-btn,
  .customModal * .batches .batchFilter .filters .create-btn,
  .customModal * .batchView .batchFilter .filters .create-btn {
    margin-top: 3.3rem;
  }
  .jeeta-app .batches .batchFilter .filters .create-btn .icon,
  .jeeta-app .batchView .batchFilter .filters .create-btn .icon,
  .jeeta-app * .batches .batchFilter .filters .create-btn .icon,
  .jeeta-app * .batchView .batchFilter .filters .create-btn .icon,
  .customModal .batches .batchFilter .filters .create-btn .icon,
  .customModal .batchView .batchFilter .filters .create-btn .icon,
  .customModal * .batches .batchFilter .filters .create-btn .icon,
  .customModal * .batchView .batchFilter .filters .create-btn .icon {
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    font-size: 1.66rem;
  }
  .jeeta-app .timeTable,
  .jeeta-app * .timeTable,
  .customModal .timeTable,
  .customModal * .timeTable {
    padding: .5rem .1rem;
  }
  .jeeta-app .timeTable .bkgImg,
  .jeeta-app * .timeTable .bkgImg,
  .customModal .timeTable .bkgImg,
  .customModal * .timeTable .bkgImg {
    width: 90%;
  }
  .jeeta-app .batchModal,
  .jeeta-app * .batchModal,
  .customModal .batchModal,
  .customModal * .batchModal {
    width: 90%;
    gap: .5rem;
    padding: .5rem;
  }
  .jeeta-app .batchModal .batchCreate,
  .jeeta-app .batchModal .batchScheduleModify,
  .jeeta-app * .batchModal .batchCreate,
  .jeeta-app * .batchModal .batchScheduleModify,
  .customModal .batchModal .batchCreate,
  .customModal .batchModal .batchScheduleModify,
  .customModal * .batchModal .batchCreate,
  .customModal * .batchModal .batchScheduleModify {
    gap: 1rem;
  }
  .jeeta-app .batchModal .batchCreate .modalTitle,
  .jeeta-app .batchModal .batchScheduleModify .modalTitle,
  .jeeta-app * .batchModal .batchCreate .modalTitle,
  .jeeta-app * .batchModal .batchScheduleModify .modalTitle,
  .customModal .batchModal .batchCreate .modalTitle,
  .customModal .batchModal .batchScheduleModify .modalTitle,
  .customModal * .batchModal .batchCreate .modalTitle,
  .customModal * .batchModal .batchScheduleModify .modalTitle {
    margin: .5rem 0;
  }
}
